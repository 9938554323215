import _ from "lodash";
import { showPopup } from "../../utils/helpers";
import settingsService from "../../services/settings.service";

const state = {
  account: { settings: { depositMsg: "", public: {} } },
};

const mutations = {
  setAccountData(state, data) {
    state.account = _.cloneDeep(data);
  },

  setFormField(state, obj) {
    state.account = _.set(state.account, obj.field, obj.val);
  },

  addEmailNotification(state) {
    state.account.settings.emailNotifications.push({
      email: "",
      resource: "",
    });
  },

  deleteEmailNotification(state, index) {
    state.account.settings.emailNotifications.splice(index, 1);
  },

  setEmailValue(state, data) {
    state.account.settings.emailNotifications[data.idx].email = data.$event.toLowerCase();
  },

  setResourceValue(state, data) {
    state.account.settings.emailNotifications[data.idx].resource = data.$event;
  },
};

const actions = {
  updateAccount({ state }) {
    return settingsService
      .updateAccount(state.account)
      .then(() => {
        showPopup("Tilin tiedot päivitetty", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  saveAccessToken(ctx, code) {
    return settingsService
      .saveAccessToken(code)
      .then(() => {
        showPopup("Valtuutus hyväksytty", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
