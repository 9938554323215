import axiosMethods from "../mixins/axios";

export default {
  async getAllPartners(url) {
    return await axiosMethods.get(url);
  },

  async createPartner(data) {
    return await axiosMethods.post("/api/v1/partner/create", data);
  },

  async editPartner(data) {
    return await axiosMethods.update(data.url, data.data);
  },

  async getOnePartner(url) {
    return await axiosMethods.get(url);
  },

  async inActivatePartner(id) {
    return await axiosMethods.update("/api/v1/partner/inactivate/" + id);
  },

  async searchPartner(url) {
    return await axiosMethods.get(url);
  },
};
