const menuPages = [
  {
    text: "Yhteenveto",
    link: "/overview",
    icon: "mdi-view-dashboard-outline",
    show: "overview",
  },
  {
    text: "Tehtävät",
    link: "/todos/tasks",
    icon: "mdi-calendar-check",
    show: "todos",
  },
  {
    text: "Sopimukset",
    icon: "mdi-file-document-multiple-outline",
    show: "contracts",
    submenu: true,
    items: [
      {
        text: "Vuokrasopimukset",
        link: "/rental-contracts",
        icon: "mdi-file-document-edit-outline",
        show: "contracts",
      },
      {
        text: "Muut sopimukset",
        link: "/other-contracts",
        icon: "mdi-file-document-edit",
        show: "otherContracts",
      },
      {
        text: "Sopimuspohjat",
        link: "/contract-models",
        icon: "mdi-file-document-multiple-outline",
        show: "contractModels",
      },
    ],
  },
  {
    text: "Laskutus",
    icon: "mdi-file-document-outline",
    show: "invoiceMenuItem",
    submenu: true,
    items: [
      {
        text: "Laskut",
        link: "/invoices",
        icon: "mdi-text-box-plus-outline",
        show: "invoices",
      },
      {
        text: "Hyvityslaskut",
        link: "/refund-invoices",
        icon: "mdi-text-box-minus-outline",
        show: "refundInvoices",
      },
      {
        text: "Lähtevien seuranta",
        link: "/outgoing-payments",
        icon: "mdi-text-box-minus-outline",
        show: "outgoingPayments",
      },
      {
        text: "Tuotteet",
        link: "/products",
        icon: "mdi-cart-outline",
        show: "products",
      },
      {
        text: "Vuokrankorotukset",
        link: "/rent-increase",
        icon: "mdi-chart-line-variant",
        show: "rentIncreases",
      },
      {
        text: "Maksusuoritukset",
        link: "/payments",
        icon: "mdi-cash-multiple",
        show: "payments",
      },
    ],
  },
  {
    text: "Asiakashallinta",
    icon: "mdi-account-group",
    show: "tenantMenuItem",
    submenu: true,
    items: [
      {
        text: "Vuokralaiset",
        link: "/tenants",
        icon: "mdi-account",
        show: "tenants",
      },
      {
        text: "Toimeksiantajat",
        link: "/partners",
        icon: "mdi-account-group",
        show: "partners",
      },
      {
        text: "Välittäjät",
        link: "/realtors",
        icon: "mdi-account-switch",
        show: "realtors",
      },
    ],
  },
  {
    text: "Kohteet",
    icon: "mdi-home-group",
    show: "condominiumMenuItem",
    submenu: true,
    items: [
      {
        text: "Vuokrakohteet",
        link: "/apartments",
        icon: "mdi-home-outline",
        show: "apartments",
      },
      {
        text: "Taloyhtiöt / Kiinteistöt",
        link: "/condominiums",
        icon: "mdi-home-group",
        show: "condominiums",
      },
      {
        text: "Statistiikka",
        link: "/apartment-statistics",
        icon: "mdi-home-search-outline",
        show: "apartmentStatistics",
      },
    ],
  },
  {
    text: "Yhteys",
    icon: "mdi-calendar-account-outline",
    show: "contactMenuItem",
    submenu: true,
    items: [
      {
        text: "Lomakkeen yhteydenotot",
        link: "/form-submissions",
        icon: "mdi-form-select",
        show: "formSubmissions",
      },
      {
        text: "Lomakkeet",
        link: "/forms",
        icon: "mdi-form-textbox",
        show: "forms",
      },
      {
        text: "Verkkosivut",
        link: "/contacts",
        icon: "mdi-card-account-mail-outline",
        show: "contacts",
      },
      {
        text: "Varaukset",
        link: "/reservations",
        icon: "mdi-calendar-month-outline",
        show: "reservations",
      },
    ],
  },
  {
    text: "Vakuudet",
    link: "/deposits",
    icon: "mdi-bank-transfer",
    show: "deposits",
  },

  {
    text: "Rahoitus | Kulut",
    icon: "mdi-currency-eur",
    show: "financeMenuItem",
    submenu: true,
    items: [
      {
        text: "Kulut",
        link: "/receipts",
        icon: "mdi-cash-multiple",
        show: "receipts",
      },
      {
        text: "Lainat",
        link: "/loans",
        icon: "mdi-currency-eur",
        show: "loans",
      },
      {
        text: "Luottotiedot",
        link: "/credit-reports",
        icon: "mdi-account-search",
        show: "creditReports",
        doNotShowOnService: "talenom",
      },
    ],
  },
  {
    text: "Avainhallinta",
    icon: "mdi-key-variant",
    show: "keyControlMenuItem",
    submenu: true,
    items: [
      {
        text: "Avaimet",
        link: "/key-control",
        icon: "mdi-key-variant",
        show: "keyControl",
      },
      {
        text: "Avaimenhaltijat",
        link: "/key-holders",
        icon: "mdi-account-key-outline",
        show: "keyHolders",
      },
    ],
  },
  {
    text: "Raportit",
    icon: "mdi-clipboard-text-outline",
    show: "reportsMenuItem",
    submenu: true,
    items: [
      {
        text: "Raportit",
        link: "/reports",
        icon: "mdi-clipboard-text-outline",
        show: "reports",
      },
    ],
  },
];

const groupPages = [
  {
    text: "Yhteenveto",
    link: "/group/overview",
    icon: "mdi-view-dashboard-outline",
    show: "g-overview",
  },
  {
    text: "Laskutus",
    icon: "mdi-file-document-outline",
    show: "g-invoiceMenuItem",
    submenu: true,
    items: [
      {
        text: "Laskut",
        link: "/group/invoices",
        icon: "mdi-text-box-plus-outline",
        show: "g-invoices",
      },
      {
        text: "Hyvityslaskut",
        link: "/group/refund-invoices",
        icon: "mdi-text-box-minus-outline",
        show: "g-refundInvoices",
      },
      {
        text: "Vuokrankorotukset",
        link: "/group/rent-increase",
        icon: "mdi-cash-multiple",
        show: "g-rentIncreases",
      },
    ],
  },
  {
    text: "Kohteet",
    icon: "mdi-home-group",
    show: "g-condominiumMenuItem",
    submenu: true,
    items: [
      {
        text: "Vuokrakohteet",
        link: "/group/apartments",
        icon: "mdi-home-outline",
        show: "g-apartments",
      },
      {
        text: "Taloyhtiöt / Kiinteistöt",
        link: "/group/condominiums",
        icon: "mdi-home-group",
        show: "g-condominiums",
      },
    ],
  },
  {
    text: "Ryhmäasetukset",
    icon: "mdi-account-group",
    show: "g-groups-menu",
    submenu: true,
    items: [
      {
        text: "Ryhmät",
        link: "/group/groups",
        icon: "mdi-text-box-plus-outline",
        show: "g-groups",
      },
      {
        text: "Liitä tilit ryhmiin",
        link: "/group/accounts",
        icon: "mdi-text-box-plus-outline",
        show: "g-groups-accounts",
      },
    ],
  },
];

export default {
  // main navigation - side menu
  menu: [
    {
      text: "Päävalikko",
      items: menuPages,
      groupItems: groupPages,
    },
    {
      text: "Muut",
      items: [
        {
          text: "Yhteistyökumppanit",
          icon: "mdi-handshake-outline",
          show: "collaborators",
          link: "/collaborators",
        },
        {
          text: "Tilin asetukset",
          icon: "mdi-cog-outline",
          show: "settings",
          link: "/settings",
        },
      ],
      groupItems: [],
    },
  ],
};
