import axiosMethods from "../mixins/axios";

export default {
  async getFormSubmissionsByFormId(url) {
    return await axiosMethods.get(url);
  },
  async getOne(id) {
    return await axiosMethods.get("/api/v1/form-submission/get-one/" + id);
  },
  async getFormSubmissionImages(sessionKey) {
    return await axiosMethods.get("/api/v1/form-submission/images/" + sessionKey);
  },
  async saveSubmissionFieldComment(data) {
    return await axiosMethods.update(
      `/api/v1/form-submission/update/${data.formSubmissionId}/comment`,
      { key: data.key, value: data.value }
    );
  },
  async deleteSubmission(data) {
    return await axiosMethods.update("/api/v1/form-submission/delete/" + data.id, {
      sessionKey: data.sessionKey,
    });
  },
};
