import { showPopup } from "../../utils/helpers";
import stripeService from "../../services/stripe.service";

const state = {
  stripeAccount: null,
};

const mutations = {
  setAccountLink(state, linkObj) {
    window.location = linkObj.url;
  },

  setAccount(state, account) {
    state.stripeAccount = account;
  },
};

const actions = {
  async createStripeAccount() {
    try {
      const res = await stripeService.createAccount();
      window.location = res.data.accountLink.url;
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getStripeAccount({ commit }) {
    try {
      const res = await stripeService.getAccount();
      commit("setAccount", res.data.stripeAccount);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async verifyStripeAccount() {
    try {
      await stripeService.verifyAccount();
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
