import nominatimService from "../../services/nominatim.service";
import { showPopup } from "../../utils/helpers";

const state = {
  nominatimAddress: null,
  foundAddresses: [],
};

const mutations = {
  setFoundAddresses(state, data) {
    state.foundAddresses = data.map((el) => {
      if (el.address.road && el.address.country) {
        return el;
      }
    });
  },

  setNominatimAddress(state, val) {
    state.nominatimAddress = val;
  },
};

const actions = {
  async getInfoByAddress({ commit }, address) {
    try {
      const res = await nominatimService.getInfoByAddress(address);
      commit("setFoundAddresses", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
