import { showPopup } from "../../utils/helpers";
import reportService from "../../services/report.service";

const state = {
  tenantReportData: [],
  reportDates: { startDate: null, endDate: null },
  rentReportData: null,
  receiptReportData: null,
  chartData: {},
  loadingRentReport: false,
  statisticsApartments: [],
  totalLength: 0,
};

const mutations = {
  setTenantData(state, data) {
    state.tenantReportData = data;
  },

  setReportDates(state, obj) {
    state.reportDates[obj.field] = obj.val;
  },

  setRentReportData(state, data) {
    // Set to state
    state.rentReportData = { ...data };

    // Format data for pie chart
    const labels = [];
    const dataPoints = [];
    const backgroundColors = [];
    const avgAreaPerLabels = [];
    let apartmentsTotal = 0;

    data.fixedTimeState.roomCountsArr.forEach((el) => {
      dataPoints.push(el.apartmentsTotal);
      apartmentsTotal += el.apartmentsTotal;

      const percentage = `(${(
        (el.apartmentsTotal / data.fixedTimeState.apartmentsTotal) *
        100
      ).toFixed(2)} %)`;
      const avgArea = `ka. ${el.avgArea.toFixed(2)} m²`;

      labels.push(
        el.roomCount
          ? `${el.roomCount} huonetta ${percentage} | ${avgArea}`
          : `Ei määritetty ${percentage} | ${avgArea}`
      );
      avgAreaPerLabels.push(el.avgArea);
      backgroundColors.push(getRandomColor());
    });

    // Set chartData
    state.chartData = {
      labels,
      dataPoints,
      backgroundColors,
      apartmentsTotal,
      avgAreaPerLabels,
    };
  },

  setReceiptReportData(state, report) {
    state.receiptReportData = { ...report };
    state.loadingRentReport = false;
  },

  setLoadingRentReport(state, val) {
    state.loadingRentReport = val;
  },

  setStatisticsApartments(state, data) {
    state.statisticsApartments = data.items;
    state.totalLength = data.totalLength;
  },
};

const actions = {
  async getTenantReport({ commit }) {
    try {
      const res = await reportService.getTenantReport();
      commit("setTenantData", res.data.data);
    } catch (err) {
      throw new Error(err);
    }
  },

  async getRentReport({ commit }, dates) {
    try {
      const res = await reportService.getRentReport(dates);
      commit("setRentReportData", res.data.report);
    } catch (err) {
      throw new Error(err);
    }
  },

  async getRentReportReceipts({ commit }, dates) {
    try {
      const res = await reportService.getRentReportReceipts(dates);
      commit("setReceiptReportData", res.data.report);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getContractReport(ctx, dates) {
    try {
      const res = await reportService.getContractReport(dates);
      return res.data.contractMap;
    } catch (err) {
      throw new Error(err);
    }
  },

  async getAssignmentReport(ctx, data) {
    try {
      const res = await reportService.getAssignmentReport(data);
      return res.data.itemMap;
    } catch (err) {
      throw new Error(err);
    }
  },

  async getApartmentStatistics({ commit }, data) {
    try {
      const res = await reportService.getApartmentStatistics(data);
      commit("setStatisticsApartments", {
        items: res.data.items,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getRentRollReport() {
    try {
      const res = await reportService.getRentRollReport();
      return res.data.apartmentMap;
    } catch (err) {
      throw new Error(err);
    }
  },
};

// Helpers

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
