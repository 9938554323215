const noAuthRoutes = [
  {
    path: "/login",
    name: "Kirjautuminen",
    meta: { layout: "auth" },
    component: () => import(/* webpackChunkName: "login" */ "../../views/Auth/Login.vue"),
  },
  {
    path: "/logout",
    name: "Uloskirjautuminen",
    component: () => import(/* webpackChunkName: "logout" */ "../../views/Auth/Logout.vue"),
  },
  {
    path: "/signup",
    name: "Rekisteröityminen",
    meta: { layout: "auth" },
    component: () => import(/* webpackChunkName: "signup" */ "../../views/Auth/Signup.vue"),
  },
  {
    path: "/confirm/:id",
    name: "Käyttäjän varmennus",
    meta: { layout: "auth" },
    component: () =>
      import(/* webpackChunkName: "userconfirm" */ "../../views/Auth/ConfirmUser.vue"),
  },
  {
    path: "/forgotpassword",
    name: "Unohtuiko salasana?",
    meta: { layout: "auth" },
    component: () =>
      import(/* webpackChunkName: "passwordforgot" */ "../../views/Auth/PasswordForgot.vue"),
  },
  {
    path: "/resetpassword/:token",
    name: "Salasanan uusiminen",
    meta: { layout: "auth" },
    component: () =>
      import(/* webpackChunkName: "passwordreset" */ "../../views/Auth/PasswordReset.vue"),
  },
  // FORM
  {
    path: "/lomakkeet/:id",
    name: "Lomake",
    meta: { layout: "full" },
    component: () =>
      import(/* webpackChunkName: "accountform" */ "../../components/Form/SubmitForm.vue"),
  },
  {
    path: "/lomakkeet/kohde/:apartmentId/lomake/:formId",
    name: "Vuokrakohteen lomake",
    meta: { layout: "full" },
    component: () =>
      import(
        /* webpackChunkName: "apartmentform" */ "../../components/Form/SubmitApartmentForm.vue"
      ),
  },
  {
    path: "/lomakkeet/sopimus/:contractId/lomake/:formId",
    name: "Sopimuksen lomake",
    meta: { layout: "full" },
    component: () =>
      import(
        /* webpackChunkName: "rentalcontractform" */ "../../components/Form/SubmitRentalContractForm.vue"
      ),
  },
  // 404
  {
    path: "/404",
    name: "Sivua ei löydy",
    meta: { layout: "auth" },
    component: () => import(/* webpackChunkName: "404" */ "../../views/404.vue"),
  },
];

export default noAuthRoutes;
