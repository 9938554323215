import axiosMethods from "../mixins/axios";

export default {
  async getReceipts(data) {
    return await axiosMethods.post(data.url, { dates: data.dates, receiptType: data.receiptType });
  },

  async createReceipt(data) {
    return await axiosMethods.post(`/api/v1/receipt/createReceipt/`, data);
  },

  async updateReceipt(data) {
    return await axiosMethods.update(`/api/v1/receipt/editReceipt/${data._id}`, { receipt: data });
  },

  async deleteReceipt(id) {
    return await axiosMethods.delete(`/api/v1/receipt/deleteReceipt/${id}`);
  },
};
