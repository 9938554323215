<template>
  <div class="min-w-0 w-full">
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan vuokrasopimusta..."
      class="full-page-loader"
    ></full-page-loader>

    <v-stepper v-if="!loading" v-model="step">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :rules="[() => (step > 1 ? validateApartment() : true)]"
          :complete="apartmentCheck"
          >Kohde</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          step="2"
          :rules="[() => (step > 2 ? validateInformation() : true)]"
          :complete="landLordCheck && tenantCheck"
          >Yhteystiedot</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          :rules="[() => (step > 3 ? validateLease() : true)]"
          :complete="leaseCheck"
          >Vuokra</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          step="4"
          :rules="[() => (step > 4 ? validateOthers() : true)]"
          :complete="othersCheck"
          >Muut</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="5">Yhteenveto</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div align="right">
            <v-btn
              color="primary"
              @click="
                step = 2;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- Apartment -->
          <Apartment ref="apartment"></Apartment>

          <div align="right">
            <v-btn
              color="primary"
              @click="
                step = 2;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 1;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 3;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- Landlord -->
          <Landlord ref="landlord"></Landlord>
          <v-divider></v-divider>
          <!-- Tenant -->
          <Tenant ref="tenant"></Tenant>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 1;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 3;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 2;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 4;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- Lease -->
          <Lease ref="lease"></Lease>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 2;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 4;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 3;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 5;
                scrollToTop();
              "
            >
              Seuraava
            </v-btn>
          </div>

          <!-- OtherData -->
          <other-data ref="otherData"></other-data>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 3;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 5;
                scrollToTop();
              "
            >
              Seuraava
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="5">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 4;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
          </div>

          <!-- Summary -->
          <Summary ref="summary" @saveContractContent="saveContractContentHandler" />

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 4;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn color="info" @click="$refs.summary.handleContractContentSave()">Tallenna</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import Landlord from "../../components/RentalContract/Landlord";
import Tenant from "../../components/RentalContract/Tenant";
import Apartment from "../../components/RentalContract/Apartment";
import Lease from "../../components/RentalContract/Lease";
import OtherData from "../../components/RentalContract/OtherData";
import Summary from "../../components/RentalContract/Summary";
import _ from "lodash";
import { mapActions, mapMutations, mapState } from "vuex";
import FullPageLoader from "@/components/FullPageLoader.vue";

export default {
  title: "Muokkaa vuokrasopimusta",

  components: {
    Landlord,
    Tenant,
    Apartment,
    Lease,
    OtherData,
    Summary,
    FullPageLoader,
  },

  mixins: [mixins],

  data() {
    return {
      step: 1,
      landLordCheck: false,
      tenantCheck: false,
      apartmentCheck: false,
      leaseCheck: false,
      othersCheck: false,
    };
  },

  watch: {
    step(val) {
      if (val == 5) {
        this.$refs.summary.getRentalContractContent();
      }
    },
  },

  async created() {
    let res;
    try {
      this.setLoading(true);

      // Get products
      await this.getInvoiceProducts("/api/v1/product/get-invoice-products");

      // Set Contract
      res = await axiosMethods.get(
        `/api/v1/rental-contract/get-one-formatted/${this.$route.params.id}`
      );

      let contract = _.cloneDeep(res.data.contract);

      // // Set initial values to arrays
      const landlordIds = res.data.contract.landlords.map((el) => el.landlordId);
      const tenantIds = [
        res.data.contract.tenant.tenantId._id,
        ...res.data.contract.otherTenants.map((el) => el.tenantId),
      ];
      const apartmentIds = [res.data.contract.apartment.id._id];
      const rentalContractIds = [
        ...res.data.contract.others.keyHoldersFromContracts.map((el) => el._id),
      ];

      await Promise.all([
        this.getLandlordsById(landlordIds),
        this.getTenantsById(tenantIds),
        this.getApartmentsById(apartmentIds),
        this.getRentalContractsById(rentalContractIds),
      ]);

      // Set tenantId and apartment.id to _id value. its populated when it arrives
      contract.tenant.tenantId = res.data.contract.tenant.tenantId._id;

      // Set apartment same way
      contract.apartment.activeForRent = contract.apartment.id.activeForRent;
      contract.apartment.id = res.data.contract.apartment.id._id;
      contract.apartment.rooms = res.data.contract.apartment.id.rooms;

      this.$store.commit("setContract", contract);

      this.setLoading(false);
    } catch (err) {
      this.showPopup(err, "error");
    }

    // Set saveTenantInformation options to false on create
    this.$store.state.saveTenantInformation = false;

    // // Set products to its own array so they are visible
    // if (res.data.contract.lease.rentProduct)
    //   this.$store.state.product.products.push(res.data.contract.lease.rentProduct);
    // if (res.data.contract.lease.waterProduct)
    //   this.$store.state.product.products.push(res.data.contract.lease.waterProduct);
    // // Push other products as well
    // res.data.contract.lease.otherProducts.forEach((el) => {
    //   this.$store.state.product.products.push(el.productId);
    // });

    eventBus.$on("checklandlord", (boolean) => {
      this.landLordCheck = boolean;
    });
    eventBus.$on("checktenant", (boolean) => {
      this.tenantCheck = boolean;
    });
    eventBus.$on("checkapartment", (boolean) => {
      this.apartmentCheck = boolean;
    });
    eventBus.$on("checklease", (boolean) => {
      this.leaseCheck = boolean;
    });
    eventBus.$on("checkothers", (boolean) => {
      this.othersCheck = boolean;
    });

    this.$store.commit("updateLocalStorage");
  },

  computed: {
    ...mapState("contract", ["loading"]),
    ...mapState("contractmodel", ["contentError"]),
  },

  methods: {
    ...mapActions("account", ["getLandlordsById"]),
    ...mapActions("tenant", ["getTenantsById"]),
    ...mapActions("apartment", ["getApartmentsById"]),
    ...mapActions("contract", ["getRentalContractsById"]),
    ...mapMutations("contract", ["setLoading"]),
    ...mapActions("product", ["getInvoiceProducts"]),

    validateApartment() {
      this.$refs.apartment.checkIfValid();

      if (this.apartmentCheck) return true;
      else return false;
    },

    validateInformation() {
      this.$refs.landlord.checkIfValid();
      this.$refs.tenant.checkIfValid();

      if (this.landLordCheck && this.tenantCheck) return true;
      else return false;
    },

    validateLease() {
      this.$refs.lease.checkIfValid();

      if (this.leaseCheck) return true;
      else return false;
    },

    validateOthers() {
      this.$refs.otherData.checkIfValid();

      if (this.othersCheck) return true;
      else return false;
    },

    async saveContractContentHandler(contractContent) {
      let contract = this.$store.getters.getContract;
      let saveTenantInformation = this.$store.state.saveTenantInformation;

      contract.content = contractContent;

      await this.submit({ documentUpdate: contract, saveTenantInformation });
    },

    async submit(data) {
      // Check if content is loaded to summary
      if (
        this.$refs.summary.modelContentLoaded == "" ||
        this.$refs.summary.modelContentLoaded == "<p></p>" ||
        this.contentError
      ) {
        return this.showPopup(
          'Lataa vuokrasopimuksen tiedot sopimuspohjan "Lataa"-napista tai luo uusi sopimuspohja ja varmista että siinä on sisältöä ja se on oikein muotoiltu.',
          "error"
        );
      }

      if (
        this.landLordCheck &&
        this.tenantCheck &&
        this.apartmentCheck &&
        this.leaseCheck &&
        this.othersCheck
      ) {
        try {
          // Check lease dates
          if (data.documentUpdate.lease.leaseType == "Määräaikainen") {
            const startDate = new Date(data.documentUpdate.lease.startDate).getTime();
            const endDate = new Date(data.documentUpdate.lease.endDate).getTime();

            if (endDate <= startDate) {
              return this.showPopup(
                "Vuokrauksen päättymispäivä tulee olla suurempi kuin alkamispäivä",
                "error"
              );
            }
          }

          const res = await axiosMethods.update(
            "/api/v1/rental-contract/update/" + this.$route.params.id,
            data
          );

          if (typeof res.data.emailCount === "number" && res.data.emailCount > 0) {
            this.showPopup(
              `Samalla sähköpostilla löytyy ${res.data.emailCount} ${
                res.data.emailCount === 1 ? "muu vuokralainen" : "muuta vuokralaista"
              }. Tarkista, ettet vahingossa asettanut väärää email-osoitetta.`,
              "info"
            );
          }

          this.$store.commit("deleteContract");
          this.$router.push("/rental-contracts");

          this.showPopup("Vuokrasopimuksen päivittäminen onnistui.", "success");
        } catch (err) {
          this.showPopup("Vuokrasopimuksen päivittäminen ei onnistunut. " + err, "error");
        }
      } else {
        this.showPopup(
          "Lomakkeen kaikki kentät eivät ole täytetty oikein. Tarkista kaikki pakolliset kentät.",
          "error"
        );
      }
    },
  },
};
</script>

<style>
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
