import axiosMethods from "../mixins/axios";

export default {
  async getTasks() {
    return await axiosMethods.get("/api/v1/toDo/getAll");
  },

  async addTask(item) {
    return await axiosMethods.post("/api/v1/toDo/createTodo", item);
  },

  async updateTask(item) {
    return await axiosMethods.update("/api/v1/toDo/update/" + item._id, item);
  },

  async deleteTask(item) {
    return await axiosMethods.delete("/api/v1/toDo/delete/" + item._id);
  },

  async markCompleted(item) {
    return await axiosMethods.update("/api/v1/toDo/completed/" + item._id);
  },

  async markInCompleted(item) {
    return await axiosMethods.update("/api/v1/toDo/inCompleted/" + item._id);
  },
};
