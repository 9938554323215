import accountService from "../../services/account.service";
import { showPopup } from "../../utils/helpers";
import globalValues from "../../configs/globalValues";
import groupService from "../../services/group.service";

const state = {
  currentUser: {
    name: "",
    email: "",
    groupMode: false,
    userPermissions: { resources: {}, routes: {} },
    accounts: [],
    ownerOfAccounts: [],
    currentAccount: {
      name: "",
      isCompany: false,
      settings: { public: {}, emailNotifications: [] },
      stripe: {},
      signHeroToken: {},
      images: [],
    },
  },
  account: {},
  accounts: [],
  totalLength: 0,
  selectedGroups: [],
  activeGroups: [],
  selectedAccounts: [],
  activeAccounts: [],
  activeLandlords: [],
  usersAccounts: [],
  accountUsers: [],
  logo: null,
};

const mutations = {
  setAccount(state, account) {
    state.account = account;
  },

  addActiveAccounts(state, accounts) {
    state.activeAccounts = accounts;
  },

  setAllAccounts(state, data) {
    state.accounts = [...data.accounts];
    state.totalLength = data.totalLength;
  },

  replaceAccount(state, account) {
    state.accounts.splice(
      state.accounts.findIndex((x) => String(x._id) == String(account._id)),
      1,
      account
    );
  },

  setSelectedAccounts(state, accounts) {
    state.selectedAccounts = accounts;
  },

  setSelectedGroups(state, groups) {
    state.selectedGroups = groups;
  },

  setAccountUsers(state, users) {
    state.accountUsers = users;
  },

  setCurrentUser(state, user) {
    state.currentUser = user;
  },

  setUserDefaultHeaders(state, headers) {
    state.currentUser.defaultHeaders = headers;
  },

  removeAllAccountsAndGroups(state) {
    state.selectedAccounts = [];
    state.selectedGroups = [];
    state.activeAccounts = [];
    state.activeGroups = [];
  },

  addActiveLandlords(state, landlords) {
    state.activeLandlords = [...landlords, ...state.activeLandlords].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  addActiveGroups(state, groups) {
    state.activeGroups = [...groups, ...state.activeGroups].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  addUsersAccounts(state, accounts) {
    state.usersAccounts = [...accounts];
  },

  replaceImages(state, images) {
    state.currentUser.currentAccount.images = images;
  },

  setLogo(state, img) {
    state.logo = { ...img };
  },
};

const actions = {
  async getAllAccounts({ commit }, url) {
    try {
      const res = await accountService.getAllAccounts(url);
      commit("setAllAccounts", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getActiveAccounts({ commit }) {
    try {
      const res = await accountService.getActiveAccounts();
      commit("addActiveAccounts", res.data.accounts);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchGroups({ commit }, url) {
    try {
      const res = await groupService.searchGroups(url);
      commit("addActiveGroups", res.data.groups);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getAccountUsers({ commit }, includeMe) {
    try {
      const res = await accountService.getAccountUsers(includeMe);
      commit("setAccountUsers", res.data.users);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async switchAccount({ rootState, dispatch, commit }, id) {
    try {
      if (rootState.groupMode) {
        const res1 = await accountService.setGroupMode();
        commit("setUserData", res1.data.userData, { root: true });

        const res2 = await accountService.switchAccount(id);
        commit("setUserData", res2.data.userData, { root: true });
      } else {
        const res = await accountService.switchAccount(id);
        commit("setUserData", res.data.userData, { root: true });
      }

      await dispatch("getCurrentUser");
    } catch (err) {
      throw new Error(err);
    }
  },

  getCurrentUser({ commit }) {
    return accountService
      .getCurrentUser()
      .then((res) => {
        if (res.data.currentUser) {
          const user = res.data.currentUser;
          // set to accounts state
          commit("setCurrentUser", user);
          // Set to user role, group mode and token state
          commit("setUser", user, { root: true });
          // Set user role based permissions and routes
          commit("userProfile/setUserPermissions", user.userPermissions, { root: true });
          // Set users account to empty array
          commit("addUsersAccounts", []);

          if (user.currentAccount) {
            // Set account Permissions
            let permissions = user.currentAccount.permissions
              ? user.currentAccount.permissions
              : {};
            commit("setAccountPermissions", permissions, { root: true });
          }
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async attachGroupToAccount({ commit }, data) {
    try {
      const res = await accountService.attachGroupToAccount(data);
      commit("replaceAccount", res.data.account);
    } catch (err) {
      throw new Error(err);
    }
  },

  async attachGroupToMultipleAccounts(ctx, data) {
    try {
      await accountService.attachGroupToMultipleAccounts(data);
    } catch (err) {
      throw new Error(err);
    }
  },

  async savePublicSettings({ commit }, data) {
    try {
      const res = await accountService.savePublicSettings(data);
      commit("setAccount", res.data.account);
      showPopup("Asetukset tallennettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchAllLandlords({ commit }, url) {
    try {
      const res = await accountService.searchAllLandlords(url);

      commit("addActiveLandlords", res.data.landlords);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchUsersAccounts({ commit }, url) {
    try {
      const res = await accountService.searchUsersAccounts(url);

      commit("addUsersAccounts", res.data.accounts);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  getLandlordsById({ commit }, ids) {
    return accountService
      .getLandlordsById(ids)
      .then((res) => {
        commit("addActiveLandlords", res.data.landlords);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getAccountLogo({ commit }, id) {
    return accountService
      .getAccountLogo(id)
      .then((res) => {
        commit("setLogo", res.data.logo);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

const getters = {
  invoiceService(state) {
    return state.currentUser?.currentAccount?.settings?.invoice?.service || null;
  },

  getSelectedAccounts(state) {
    if (state.selectedAccounts.length > 0) {
      return { groupSelection: false, accounts: state.selectedAccounts };
    } else if (state.selectedGroups.length > 0) {
      return { groupSelection: true, groups: state.selectedGroups };
    } else {
      return { groupSelection: false, accounts: [] };
    }
  },

  userIsAccountOwner(state) {
    return state.currentUser.ownerOfAccounts.includes(state.currentUser.currentAccount._id);
  },

  defaultTableRows(state) {
    return state.currentUser.defaultTableRows || 15;
  },

  getCustomLogo(state) {
    const bypassGroupStyles = state.currentUser?.currentAccount?.group?.bypassGroupStyles;

    if (state.currentUser?.currentAccount?.group?.logo && !bypassGroupStyles) {
      return {
        buffer: state.currentUser.currentAccount.group.logo.buffer,
        mimeType: state.currentUser.currentAccount.group.logo.mimeType,
        dimensions: state.currentUser.currentAccount.group.logo.dimensions,
      };
    } else {
      return null;
    }
  },

  getFooterData(state) {
    if (!state.currentUser.bypassCustomStyles && state.currentUser?.currentAccount?.group) {
      return {
        texts: state.currentUser.currentAccount.group.texts.footer,
      };
    } else {
      return null;
    }
  },

  getAccountColors(state) {
    const bypassGroupStyles = state.currentUser?.currentAccount?.group?.bypassGroupStyles;

    if (state.currentUser?.currentAccount?.group && !bypassGroupStyles) {
      return state.currentUser.currentAccount.group.colors;
    } else {
      return globalValues.themeColors;
    }
  },

  getFontFamily() {
    const bypassGroupStyles = state.currentUser?.currentAccount?.group?.bypassGroupStyles;

    if (
      !state.currentUser.bypassCustomStyles &&
      state.currentUser?.currentAccount?.group &&
      !bypassGroupStyles
    ) {
      return state.currentUser.currentAccount.group.fonts.fontFamily;
    } else {
      return "Bai Jamjuree";
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
