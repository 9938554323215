import axiosMethods from "../mixins/axios";

export default {
  async updateAccount(data) {
    return await axiosMethods.update("/api/v1/account/updateAccount", data);
  },

  async saveAccessToken(code) {
    return await axiosMethods.post("/api/v1/signature/signhero/saveAccessToken", { code });
  },
};
