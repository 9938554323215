import { Node } from "tiptap";
import { replaceText, nodeInputRule } from "tiptap-commands";
import _ from 'lodash';

const VARIABLE_INPUT_REGEX = /\{\{(.+?)\}\}/

export default class Variable extends Node {

  get name() {
    return "variable";
  }

  get defaultOptions() {
    return {
      prefix: "{{",
      suffix: "}}",
      variableClass: "variable",
      variables: []
    };
  }

  get schema() {
    return {
      attrs: {
        value: {
          default: 'variable'
        }
      },
      group: "inline",
      inline: true,
      content: 'text*',
      selectable: false,
      atom: true,
      toDOM: node => {
        // get variabl
        const value = _.trim(node.attrs.value);
        const variable = _.find(this.options.variables, function(v) { return v.value == value });
        const text = variable ? variable.text : '';
        const type = variable ? variable.type : 'variable';
        // return to dom
        return [
          'span',
          {
            'data-variable': node.attrs.value,
            'data-type': type,
            class: this.options.variableClass,
          },
          `${text}`
        ]
      },
      parseDOM: [
        {
          tag: "span[data-variable]",
          getAttrs: dom => {
            const value = dom.getAttribute('data-variable');
            return { value };
          },
        }
      ]
    };
  }

  commands({ schema }) {
    return attrs => replaceText(null, schema.nodes[this.name], attrs);
  }

  inputRules({ type }) {
    return [
      nodeInputRule(VARIABLE_INPUT_REGEX, type, match => {
        return { value: match[1] };
      })
    ]
  }

  get plugins() {
    return [];
  }
}
