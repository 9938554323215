// import router from "../../router/router.js";
import paymentService from "../../services/outgoingPayment.service";
import { showPopup } from "../../utils/helpers";

const state = {
  payments: [],
  currentOtherPayment: { joinedTo: {} },
  currentPayment: { payments: [], joinedPayments: [], apartment: {}, joinedTo: null },
  otherPayments: [],
  activePayments: [],
  checkList: [],
  totalLength: 0,
};

const mutations = {
  addAllPayments(state, data) {
    state.payments = [...data.payments];
    state.totalLength = data.totalLength;
  },

  addCheckList(state, data) {
    state.checkList = [...data.items];
    state.totalLength = data.totalLength;
  },

  addOtherPayments(state, data) {
    state.otherPayments = [...data.payments];
    state.totalLength = data.totalLength;
  },

  addActivePayments(state, payments) {
    state.activePayments = [...payments, ...state.activePayments].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  removePayment(state, id) {
    state.payments = state.payments.filter((el) => String(el._id) != String(id));
  },

  replaceOtherPayment(state, payment) {
    state.otherPayments.splice(
      state.otherPayments.findIndex((x) => String(x._id) == String(payment._id)),
      1,
      payment
    );
  },

  replacePayment(state, payment) {
    const index = state.payments.findIndex((el) => String(el._id) == String(payment._id));
    if (index != -1) state.payments.splice(index, 1, { ...payment });
    state.currentPayment = { ...payment };
  },

  replaceUpdatedPayments(state, data) {
    data.payments.forEach((payment) => {
      // Set current payment
      if (String(data.currentId) == String(payment._id)) state.currentPayment = { ...payment };

      // Set payments
      const index = state.payments.findIndex((el) => String(el._id) == String(payment._id));
      if (index != -1) state.payments.splice(index, 1, { ...payment });
    });
  },

  removeOtherPayment(state, id) {
    state.otherPayments = state.otherPayments.filter((el) => String(el._id) != String(id));
  },
};

const actions = {
  async getAllPayments({ commit }, url) {
    try {
      const res = await paymentService.getAllPayments(url);
      commit("addAllPayments", { payments: res.data.payments, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getCheckList({ commit }, data) {
    try {
      const res = await paymentService.getCheckList(data);
      commit("addCheckList", { items: res.data.items, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getPartnerPayments({ commit }, url) {
    try {
      const res = await paymentService.getPartnerPayments(url);
      commit("addAllPayments", { payments: res.data.payments, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getOtherPayments({ commit }, url) {
    try {
      const res = await paymentService.getOtherPayments(url);
      commit("addOtherPayments", {
        payments: res.data.payments,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async deletePayment({ commit }, data) {
    try {
      await paymentService.deletePayment(data);
      commit("removePayment", data.paymentId);
      showPopup("Maksu poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  deleteManualPayment({ commit }, obj) {
    return paymentService
      .deleteManualPayment(obj)
      .then(({ data }) => {
        commit("replacePayment", data.payment);
        showPopup("Ohisuoritus poistettu", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async deleteOtherPayment({ commit }, id) {
    try {
      await paymentService.deleteOtherPayment(id);
      commit("removeOtherPayment", id);
      showPopup("Maksu poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchPayments({ commit }, url) {
    try {
      const res = await paymentService.searchPayments(url);
      commit("addActivePayments", res.data.payments);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  joinOtherPayment({ commit }, obj) {
    return paymentService
      .joinOtherPayment(obj)
      .then(({ data }) => {
        const removeFromList = obj.returnUnEdited || !obj.editList;

        if (removeFromList) {
          setTimeout(() => {
            commit("removeOtherPayment", obj.otherPayment._id);
          }, 1000);
        } else {
          commit("replaceOtherPayment", data.otherPayment);
        }

        showPopup("Maksua muokattu", "success");
      })
      .catch((err) => {
        showPopup(err, "error");
      });
  },

  joinPayment({ commit }, obj) {
    return paymentService
      .joinPayment(obj)
      .then(({ data }) => {
        commit("replaceUpdatedPayments", {
          payments: [data.currentPayment, data.updatedPayment],
        });
        showPopup("Maksu kohdistettu ja siirretään maksettujen listalle.", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  deleteJoinedPayment({ commit }, obj) {
    return paymentService
      .deleteJoinedPayment(obj)
      .then(({ data }) => {
        commit("replaceUpdatedPayments", {
          payments: [data.currentPayment, data.payment],
          currentId: obj.currentPaymentId,
        });
        showPopup(
          "Kohdistu poistettu ja kohdistettu maksu poistetaan maksettujen listalta.",
          "success"
        );
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  deleteAttachedOtherPayment({ commit }, obj) {
    return paymentService
      .deleteAttachedOtherPayment(obj)
      .then(({ data }) => {
        commit("replacePayment", data.payment);
        showPopup("Maksun kohdistus poistettu ja maksut päivitetään...", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  makePayment({ commit }, obj) {
    return paymentService
      .makePayment(obj)
      .then(({ data }) => {
        commit("replacePayment", data.payment);
        showPopup("Maksu lisätty", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  checkTransactionFile(ctx, data) {
    return paymentService
      .checkTransactionFile(data)
      .then((res) => {
        // If error
        if (res.data.includes("ERROR:")) {
          const errorMessage = res.data.split("ERROR: ")[1];
          throw new Error(errorMessage);
        }

        showPopup("Maksut tarkistettu", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  checkTransactionOpenBanking(ctx, data) {
    return paymentService
      .checkTransactionOpenBanking(data)
      .then((res) => {
        // If error
        if (res.data.includes("ERROR:")) {
          const errorMessage = res.data.split("ERROR: ")[1];
          throw new Error(errorMessage);
        }

        showPopup("Maksut tarkistettu", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
