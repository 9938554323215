import Vue from "vue";
import Vuex from "vuex";
import keys from "./modules/keys";
import account from "./modules/account";
import todoApp from "./modules/todoApp";
import partner from "./modules/partner";
import tenant from "./modules/tenant";
import invoice from "./modules/invoice";
import condominium from "./modules/condominium";
import contract from "./modules/rentalContract";
import apartment from "./modules/apartment";
import userProfile from "./modules/userProfile";
import statistics from "./modules/statistics";
import browsehistory from "./modules/browseHistory";
import keyholder from "./modules/keyHolder";
import product from "./modules/product";
import report from "./modules/report";
import deposit from "./modules/deposit";
import contact from "./modules/contact";
import reservation from "./modules/reservation";
import stripe from "./modules/stripe";
import othercontract from "./modules/otherContract";
import contractmodel from "./modules/contractModel";
import outgoingpayment from "./modules/outgoingPayment";
import note from "././modules/note";
import signature from "././modules/signature";
import image from "././modules/image";
import settings from "././modules/settings";
import realtor from "././modules/realtor";
import loan from "././modules/loan";
import rentIncrease from "././modules/rentIncrease.js";
import receipt from "././modules/receipt";
import nominatim from "././modules/nominatim";
import form from "././modules/form";
import formSubmission from "././modules/formSubmission.js";
import nordigen from "././modules/nordigen.js";
import pdfViewer from "./modules/pdfViewer";
import group from "./modules/group";
import userModule from "./modules/user";
import tableHeader from "./modules/tableHeader.js";
import { defaultRentalContract } from "../configs/defaultState.js";
import _ from "lodash";

import { apiAgent } from "../services/apiAgent";

Vue.use(Vuex);

let rentalContract = localStorage.getItem("contract");
let saveTenantInformation = localStorage.getItem("saveTenantInformation");
let groupMode = localStorage.getItem("groupMode");

export default new Vuex.Store({
  state: {
    loading: false,
    user: localStorage.getItem("user") || null,
    groupMode: groupMode ? JSON.parse(groupMode) : false,
    token: localStorage.getItem("token") || null,
    userData: sessionStorage.getItem("userData") || null,
    accountPermissions: {},
    saveTenantInformation: saveTenantInformation ? JSON.parse(saveTenantInformation) : false,
    rentalContract: rentalContract
      ? JSON.parse(rentalContract)
      : _.cloneDeep(defaultRentalContract),
  },

  mutations: {
    setLoading(state, val) {
      state.loading = val;
    },
    setUser(state, user) {
      localStorage.setItem("user", user.role);
      localStorage.setItem("groupMode", user.groupMode ? true : false);
      state.user = user.role;
      state.groupMode = user.groupMode;
    },
    deleteUser(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("groupMode");
      state.user = null;
      state.groupMode = null;
    },
    setUserData(state, id) {
      sessionStorage.setItem("userData", id);
      state.userData = id;
    },
    deleteUserData(state) {
      sessionStorage.removeItem("userData");
      state.userData = null;
    },
    setToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    deleteToken(state) {
      localStorage.removeItem("token");
      state.token = null;
    },
    setAccountPermissions(state, obj) {
      state.accountPermissions = obj;
    },
    setContractField(state, obj) {
      state.rentalContract[Object.keys(obj)[0]] = Object.values(obj)[0];
      localStorage.setItem("contract", JSON.stringify(state.rentalContract));
    },
    updateLocalStorage(state) {
      localStorage.setItem("contract", JSON.stringify(state.rentalContract));
      localStorage.setItem("saveTenantInformation", JSON.stringify(state.saveTenantInformation));
    },
    setContract(state, obj) {
      state.rentalContract = obj;
      localStorage.setItem("contract", JSON.stringify(state.rentalContract));
    },
    deleteContract(state) {
      localStorage.removeItem("contract");
      state.rentalContract = _.cloneDeep(defaultRentalContract);
    },
    cleanArrays(state, { module, array }) {
      state[module][array] = [];
    },
  },

  actions: {
    getToken(ctx, credentials) {
      return new Promise((resolve, reject) => {
        apiAgent
          .post(`/api/v1/user/login`, {
            email: credentials.email,
            password: credentials.password,
          })
          .then((res) => {
            // set token and userdata
            ctx.commit("setToken", res.data.token);
            ctx.commit("setUserData", res.data.userData);
            resolve(res);
          })
          .catch((err) => {
            ctx.commit("deleteToken");
            ctx.commit("deleteUserData");
            ctx.commit("deleteUser");
            ctx.commit("deleteContract");
            ctx.commit("userProfile/deletePermissions");
            ctx.commit("account/removeAllAccountsAndGroups");
            reject(err.response.data.message);
          });
      });
    },

    deleteToken(ctx) {
      if (ctx.getters.isLoggedIn) {
        return new Promise((resolve) => {
          ctx.commit("deleteToken");
          ctx.commit("deleteUserData");
          ctx.commit("deleteUser");
          ctx.commit("deleteContract");
          ctx.commit("userProfile/deletePermissions");
          ctx.commit("account/removeAllAccountsAndGroups");
          resolve("success");
        });
      }
    },
  },

  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    getToken(state) {
      return state.token;
    },
    getContract(state) {
      return state.rentalContract;
    },
    getIsGroupMode(state) {
      return state.groupMode ? true : false;
    },
  },

  modules: {
    account,
    keys,
    todoApp,
    contract,
    condominium,
    apartment,
    userProfile,
    statistics,
    tenant,
    partner,
    invoice,
    browsehistory,
    keyholder,
    product,
    report,
    deposit,
    contact,
    reservation,
    stripe,
    othercontract,
    contractmodel,
    outgoingpayment,
    note,
    signature,
    image,
    settings,
    realtor,
    loan,
    rentIncrease,
    receipt,
    nominatim,
    form,
    formSubmission,
    nordigen,
    pdfViewer,
    group,
    userModule,
    tableHeader,
  },
});
