const state = {
  loading: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },
};

export default {
  state,
  mutations,
  namespaced: true,
};
