import contractModelService from "../../services/contractModel.service";
import { showPopup } from "../../utils/helpers";

const state = {
  contractContent: "",
  contractModel: { content: "" },
  contractModels: [],
  selectedContractModel: null,
  contentError: false,
};

const mutations = {
  getContractContentSuccess(state, contractContent) {
    state.contractContent = contractContent;
  },

  getContractModelSuccess(state, contractModel) {
    state.contractModel = contractModel;
  },

  setContractModels(state, models) {
    state.contractModels = [...models];
  },

  setSelectedContractModel(state, model) {
    state.selectedContractModel = model;
  },

  setContentErrorState(state, val) {
    state.contentError = val;
  },
};

const actions = {
  getContractContent({ commit }, { contractData, contentData, contractType, rentalContractId }) {
    contractModelService
      .getContractContent(contractData, contentData, contractType, rentalContractId)
      .then(
        (contractContent) => {
          commit("getContractContentSuccess", contractContent);
          commit("setContentErrorState", false);
        },
        (error) => {
          showPopup(error, "error");
          commit("setContentErrorState", true);
        }
      );
  },

  getContractModel({ commit }, modelId) {
    contractModelService.getContractModel(modelId).then(
      (contractModel) => {
        commit("getContractModelSuccess", contractModel);
      },
      (error) => {
        showPopup(error, "error");
      }
    );
  },

  async getContractModels({ commit }, modelType) {
    try {
      const res = await contractModelService.getContractModels(modelType);
      commit("setContractModels", res.data.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

// Exports
export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
