import axiosMethods from "../mixins/axios";

export default {
  async getForms(url) {
    return await axiosMethods.get(url);
  },
  async getActiveForms() {
    return await axiosMethods.get("/api/v1/form/get-active-forms");
  },
  async getOne(id) {
    return await axiosMethods.get("/api/v1/form/get-form/" + id);
  },
  async createForm(form) {
    return await axiosMethods.post("/api/v1/form/create", form);
  },
  async editForm(data) {
    return await axiosMethods.update("/api/v1/form/update/" + data.id, { ...data.form });
  },
  async updateFormActiveState(data) {
    return await axiosMethods.update("/api/v1/form/update-form-active/" + data._id, {
      active: data.active,
    });
  },
  async attachFormToApartment(data) {
    return await axiosMethods.update("/api/v1/form/attach-to-apartment/" + data.apartmentId, {
      form: data.form,
      dates: data.dates,
      recipients: data.recipients,
      alwaysActive: data.alwaysActive,
    });
  },
  async attachFormToRentalContract(data) {
    return await axiosMethods.update("/api/v1/form/attach-to-rental-contract/" + data.contractId, {
      form: data.form,
      dates: data.dates,
      recipients: data.recipients,
      alwaysActive: data.alwaysActive,
    });
  },
  async editApartmentForm(data) {
    return await axiosMethods.update(
      "/api/v1/form/edit-apartment-form/" + data.apartmentId,
      data.form
    );
  },
  async editRentalContractForm(data) {
    return await axiosMethods.update(
      "/api/v1/form/edit-rental-contract-form/" + data.contractId,
      data.form
    );
  },
  async saveDefaultHeaders(data) {
    return await axiosMethods.update("/api/v1/form/update-default-headers/" + data.formId, {
      headers: data.headers,
    });
  },
  async deleteForm(id) {
    return await axiosMethods.update("/api/v1/form/delete/" + id);
  },
  async removeApartmentForm(data) {
    return await axiosMethods.update(
      "/api/v1/form/remove-apartment-form/" + data.apartmentId,
      data
    );
  },
  async removeRentalContractForm(data) {
    return await axiosMethods.update(
      "/api/v1/form/remove-rental-contract-form/" + data.contractId,
      data
    );
  },

  // PUBLIC
  async getOnePublic(id) {
    return await axiosMethods.get("/api/v1/public/form/get-form/" + id);
  },
  async getApartmentForm(data) {
    return await axiosMethods.post("/api/v1/public/form/apartment-form/" + data.apartmentId, data);
  },
  async getRentalContractForm(data) {
    return await axiosMethods.post(
      "/api/v1/public/form/rental-contract-form/" + data.contractId,
      data
    );
  },
  async submitForm(data) {
    return await axiosMethods.post(`/api/v1/public/form/submit-form`, data);
  },
};
