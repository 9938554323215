<template>
  <v-alert dense :type="type" :prominent="large" outlined :color="color" icon="mdi-alert">
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "warning",
    },
    type: {
      type: String,
      default: "error",
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.v-alert >>> .v-alert__content {
  font-size: 14px;
}
</style>
