import contactService from "../../services/contact.service";
import { showPopup } from "../../utils/helpers";

const state = {
  contacts: [],
  queueContacts: [],
  totalLength: 0,
};

const mutations = {
  addAllContacts(state, data) {
    state.contacts = data.contacts;
    state.totalLength = data.totalLength;
  },

  addAllQueueContacts(state, data) {
    state.queueContacts = data.contacts;
    state.totalLength = data.totalLength;
  },

  removeContact(state, id) {
    state.contacts = state.contacts.filter((el) => el._id != id);
  },

  removeQueueContact(state, id) {
    state.queueContacts = state.queueContacts.filter((el) => el._id != id);
  },

  setItemRead(state, data) {
    if (data.type == "contact") {
      const index = state.contacts.findIndex((el) => String(el._id) == String(data.id));

      if (index != -1) {
        state.contacts[index].read = true;
      }
    } else if (data.type == "queueContact") {
      const index = state.queueContacts.findIndex((el) => String(el._id) == String(data.id));

      if (index != -1) {
        state.queueContacts[index].read = true;
      }
    }
  },
};

const actions = {
  async getAllContacts({ commit }, url) {
    try {
      const res = await contactService.getAllContacts(url);
      commit("addAllContacts", { contacts: res.data.contacts, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getAllQueueContacts({ commit }, url) {
    try {
      const res = await contactService.getAllQueueContacts(url);
      commit("addAllQueueContacts", {
        contacts: res.data.contacts,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async deleteContact({ commit }, id) {
    try {
      await contactService.deleteContact(id);
      commit("removeContact", id);
      showPopup("Yhteydenotto poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async deleteQueueContact({ commit }, id) {
    try {
      await contactService.deleteQueueContact(id);
      commit("removeQueueContact", id);
      showPopup("Yhteydenotto poistettu jonosta", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async markItemRead({ commit }, data) {
    try {
      await contactService.markItemRead(data);
      commit("setItemRead", data);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
