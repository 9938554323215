import userProfileService from "../../services/userProfile.service";
import { showPopup } from "../../utils/helpers";

const state = {
  user: {
    email: "",
    name: "",
  },
  resourcePermissions: null,
  routePermissions: null,
  fieldPermissions: null,
};

const mutations = {
  setUserPermissions(state, permissions) {
    state.routePermissions = permissions.routes;
    state.resourcePermissions = permissions.resources;
    state.fieldPermissions = permissions.fields;
  },

  deletePermissions(state) {
    state.resourcePermissions = null;
    state.routePermissions = null;
    state.fieldPermissions = null;
  },
};

const actions = {
  async updateUserData(ctx, user) {
    try {
      await userProfileService.updateUserData(user);
      showPopup("Käyttäjän tiedot päivitetty", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async updatePassword({ commit }, { password, passwordConfirm, currentPassword }) {
    try {
      const res = await userProfileService.updatePassword(
        password,
        passwordConfirm,
        currentPassword
      );

      const token = res.data.token;
      commit("setToken", token, { root: true });

      showPopup("Salasana päivitetty", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
