import axiosMethods from "../mixins/axios";

export default {
  async getTableHeaders(tableName) {
    return await axiosMethods.get(`/api/v1/table-header/${tableName}`);
  },

  async createTableHeader(data) {
    return await axiosMethods.post(`/api/v1/table-header/`, data);
  },

  async editTableHeader(data) {
    return await axiosMethods.update(`/api/v1/table-header/${data.header._id}`, data);
  },

  async deleteTableHeader(data) {
    return await axiosMethods.delete(`/api/v1/table-header/${data.headerId}`);
  },

  async saveDefaultHeader(data) {
    return await axiosMethods.update(
      `/api/v1/table-header/save-for-default/${data.headerId}`,
      data
    );
  },
};
