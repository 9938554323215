import axiosMethods from "../mixins/axios";

export default {
  async sortImages(data) {
    return await axiosMethods.update(
      `/api/v1/image/sort-images/${data.documentId}?model=${data.model}`,
      data.images
    );
  },

  async deleteImage(url) {
    return await axiosMethods.delete(url);
  },

  async getSignedUrl(img) {
    return await axiosMethods.get(
      `/api/v1/image/get-signed-url?bucket=${img.bucket}&key=${img.key}`
    );
  },

  // Public
  async deleteFormImage(data) {
    return await axiosMethods.update("/api/v1/public/aws/delete-form-image", data);
  },
};
