import axiosMethods from "../mixins/axios";

export default {
  async getAllAccounts(url) {
    return await axiosMethods.get(url);
  },

  async getActiveAccounts() {
    return await axiosMethods.get(`/api/v1/account/get-active-accounts`);
  },

  async attachGroupToAccount(data) {
    return await axiosMethods.update("/api/v1/account/attach-group", data);
  },

  async attachGroupToMultipleAccounts(data) {
    return await axiosMethods.update("/api/v1/account/attach-group-multiple", data);
  },

  async switchAccount(id) {
    return await axiosMethods.get(`/api/v1/account/switchAccount/${id}`);
  },

  async setGroupMode() {
    return await axiosMethods.get(`/api/v1/user/set-group-mode/`);
  },

  async getCurrentUser() {
    return await axiosMethods.get("/api/v1/user/getCurrentUser");
  },

  async getAccountUsers(includeMe = false) {
    return await axiosMethods.post("/api/v1/user/getAccountUsers", { includeMe });
  },

  async savePublicSettings(data) {
    return await axiosMethods.update("/api/v1/account/save-public-settings", data);
  },

  async searchAllLandlords(url) {
    return await axiosMethods.get(url);
  },

  async searchUsersAccounts(url) {
    return await axiosMethods.get(url);
  },

  async getLandlordsById(ids) {
    return await axiosMethods.post("/api/v1/account/get-landlords-by-id", ids);
  },

  async getAccountLogo(id) {
    return await axiosMethods.get("/api/v1/account/get-account-logo/" + id);
  },
};
