import realtorService from "../../services/realtor.service";
import { showPopup } from "../../utils/helpers";

const state = {
  realtors: [],
  activeRealtors: [],
  totalLength: 0,
  currentRealtor: {},
};

const mutations = {
  addAllRealtors(state, data) {
    state.realtors = data.realtors;
    state.totalLength = data.totalLength;
  },

  setCurrentRealtor(state, realtor) {
    state.currentRealtor = { ...realtor };
  },

  addActiveRealtors(state, realtors) {
    state.activeRealtors = [...realtors, ...state.activeRealtors].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  removeRealtor(state, id) {
    state.realtors = state.realtors.filter((el) => el._id != id);
  },

  addRealtor(state, realtor) {
    state.realtors.unshift({ ...realtor });
    state.totalLength++;
  },

  replaceRealtor(state, realtor) {
    state.realtors.splice(
      state.realtors.findIndex((el) => el._id == realtor._id),
      1,
      realtor
    );
  },

  setFieldValue(state, obj) {
    state.currentRealtor[obj.field] = obj.val;
  },
};

const actions = {
  async getRealtors({ commit }, url) {
    try {
      const res = await realtorService.getRealtors(url);
      commit("addAllRealtors", { realtors: res.data.realtors, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getRealtorsById({ commit }, ids) {
    try {
      const res = await realtorService.getRealtorsById(ids);
      commit("addActiveRealtors", res.data.realtors);
    } catch (err) {
      throw new Error(err);
    }
  },

  createRealtor({ commit }, obj) {
    return realtorService
      .createRealtor(obj)
      .then(({ data }) => {
        commit("addRealtor", data.realtor);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  updateRealtor({ commit }, obj) {
    return realtorService
      .updateRealtor(obj)
      .then(({ data }) => {
        commit("replaceRealtor", data.realtor);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async getOneRealtor({ commit }, url) {
    try {
      const res = await realtorService.getOneRealtor(url);
      commit("setCurrentRealtor", res.data.realtor);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async inActivateRealtor({ commit }, id) {
    try {
      await realtorService.inActivateRealtor(id);
      commit("removeRealtor", id);
      showPopup("Välittäjä poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchRealtor({ commit }, url) {
    try {
      const res = await realtorService.searchRealtor(url);
      commit("addActiveRealtors", res.data.realtors);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
