<template>
  <v-container>
    <v-form ref="form">
      <v-row class="justify-center" dense>
        <v-col
          v-for="(landlord, index) in landlords"
          :key="index"
          cols="12"
          sm="10"
          md="6"
          lg="5"
          xl="4"
        >
          <v-col cols="12">
            <h2 class="mb-2">
              Vuokranantaja
              <span
                v-if="index > 0"
                class="ml-2 error--text"
                @click="removeLandlord(index)"
                style="font-size: 15px; cursor: pointer"
                >Poista
              </span>
            </h2>
          </v-col>

          <v-col cols="12">
            <label>Hae vuokranantaja</label>

            <v-autocomplete
              :value="landlord.landlordId"
              :items="activeLandlords"
              item-text="name"
              item-value="_id"
              no-data-text="Toimeksijantajan tai oman tilisi nimi"
              outlined
              dense
              return-object
              :rules="required"
              small-chips
              @change="updateLandlord($event, { ...landlord }, index)"
              @input.native="getLandlords"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Nimi</label>
            <v-text-field
              v-model="landlord.name"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col v-if="!landlord.isCompany" cols="12">
            <label>Henkilötunnus</label>
            <v-text-field
              v-model="landlord.social"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col v-if="landlord.isCompany" cols="12">
            <label>Y-tunnus</label>
            <v-text-field
              v-model="landlord.businessId"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Osoite</label>
            <v-text-field
              v-model="landlord.address"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Postinumero</label>
            <v-text-field
              v-model="landlord.zipCode"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Kaupunki</label>
            <v-text-field
              v-model="landlord.city"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Puhelin</label>
            <v-text-field
              v-model="landlord.phone"
              :rules="required"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Sähköposti</label>
            <v-text-field
              v-model="landlord.email"
              :rules="emailRules"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col>

          <v-col v-if="!index > 0" cols="12">
            <label>Pankki</label>
            <v-text-field v-model="landlord.bank" outlined dense disabled hide-details />
          </v-col>

          <v-col v-if="!index > 0" cols="12">
            <label>Iban</label>
            <v-text-field v-model="landlord.iban" outlined dense disabled hide-details />
          </v-col>
        </v-col>
      </v-row>

      <v-row dense class="justify-center mt-1">
        <v-btn color="primary" @click="addLandlord()">Lisää vuokranantaja</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";

export default {
  mixins: [mixins],
  data(vm) {
    return {
      landlordInput: "",
      emailRules: [(v) => vm.testEmail(v)],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("account", ["activeLandlords"]),

    landlords: {
      get() {
        return this.$store.state.rentalContract.landlords;
      },
      set(value) {
        this.$store.commit("setContractField", {
          landlords: value,
        });
      },
    },
  },

  watch: {
    landlords: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },

    landlordInput: debounce(function (newVal) {
      this.searchAllLandlords(`/api/v1/account/search-landlords?search=${newVal}`);
    }, 1000),
  },

  async created() {
    this.$root.$refs.editLandlord = this;
  },

  methods: {
    ...mapActions("account", ["searchAllLandlords"]),

    getLandlords(event) {
      this.landlordInput = event.target.value;
    },

    checkIfValid() {
      eventBus.$emit("checklandlord", this.$refs.form.validate());
    },

    addLandlord() {
      let landlords = this.landlords;

      landlords.push({
        isCompany: null,
        name: "",
        social: "",
        businessId: "",
        phone: "",
        address: "",
        zipCode: "",
        city: "",
        email: "",
        bank: "",
        iban: "",
        onModel: "",
        landlordId: "",
      });

      this.landlords = [...landlords];
    },

    removeLandlord(index) {
      this.landlords.splice(index, 1);
    },

    updateLandlord(event, landlord, index) {
      if (event) {
        landlord.name = event.name;
        landlord.address = event.address;
        landlord.city = event.city;
        landlord.zipCode = event.zipCode;
        landlord.email = event.email;
        landlord.phone = event.phone;
        landlord.bank = event.bank;
        landlord.iban = event.iban;
        landlord.landlordId = event._id;
        delete landlord._id;

        if (event.isCompany) {
          landlord.isCompany = true;
          landlord.businessId = event.businessId;
          landlord.social = null;
        } else {
          landlord.isCompany = false;
          landlord.social = event.social;
          landlord.businessId = null;
        }

        if (String(this.$store.state.account.currentUser.currentAccount._id) == String(event._id)) {
          landlord.onModel = "Account";
        } else {
          landlord.onModel = "Partner";
        }

        this.landlords.splice(index, 1, landlord);
      }
    },

    testEmail(val) {
      if (!val) {
        return true;
      }
      return /.+@.+\..+/.test(val) || "Email ei ole oikeassa muodossa";
    },
  },
};
</script>

<style scoped>
.v-text-field >>> .v-messages {
  color: var(--v-error-base) !important;
}
</style>
