import eng from "./eng.js";
import swe from "./swe.js";
import ger from "./ger.js";

export const translateWords = (word, language, category) => {
  if (!word) return "";
  if (!language || !category) return word;

  try {
    let text = "";

    if (language === "swe") text = swe[category][word];
    else if (language === "eng") text = eng[category][word];
    else if (language === "ger") text = ger[category][word];
    else if (language === "fin") text = word;

    if (text === undefined) return word;
    else return text;
  } catch (err) {
    return word;
  }
};
