import axiosMethods from "../mixins/axios";

export default {
  async getAllApartments(data) {
    return await axiosMethods.post(data.url, {
      searchData: data.searchFields,
      dates: data.dates,
      accountSelector: data.accountSelector,
    });
  },

  async searchFreeApartments(url, query) {
    return await axiosMethods.post(url, query);
  },

  async searchApartment(url) {
    return await axiosMethods.get(url);
  },

  async getApartmentsById(ids) {
    return await axiosMethods.post("/api/v1/apartment/get-apartments-by-id", ids);
  },

  // Marking inActive, soft delete
  async deleteApartment(id) {
    return await axiosMethods.update("/api/v1/apartment/delete/" + id);
  },

  async getOneApartment(apartmentId) {
    return await axiosMethods.get("/api/v1/apartment/getOne/" + apartmentId);
  },

  async updateApartment(data) {
    return await axiosMethods.update(`/api/v1/apartment/update/${data.apartmentId}`, {
      documentUpdate: data.apartment,
    });
  },

  async updateMultipleApartments(data) {
    return await axiosMethods.update(`/api/v1/apartment/update-multiple`, data);
  },

  async createApartment(data) {
    return await axiosMethods.post("/api/v1/apartment/create", data);
  },

  async updateApartmentImages(apartmentId, images) {
    return await axiosMethods.update(
      `/api/v1/apartment/update-apartment-images/${apartmentId}`,
      images
    );
  },

  async deleteApartmentImage(url) {
    return await axiosMethods.delete(url);
  },

  async updateApartmentPublicSettings(data) {
    return await axiosMethods.update(
      `/api/v1/apartment/update-public-settings/${data.apartmentId}`,
      data.settings
    );
  },

  // async getAll() {
  //   return await axiosMethods.post("/api/v1/apartment/getAll");
  // },
};
