<template>
  <v-container>
    <v-form ref="form">
      <v-row dense class="justify-center">
        <v-col cols="12" sm="10" md="6" lg="5" xl="4">
          <v-col cols="12">
            <h2 class="mb-3">Vuokralainen</h2>
            <label>Vuokrasopimustyyppi</label>
            <v-radio-group
              v-model="tenant.tenantType"
              :mandatory="true"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio label="Ensisijainen vuokralainen" value="first"></v-radio>
              <v-radio label="Toissijainen vuokralainen" value="second"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12">
            <!-- Create new tenant -->

            <v-btn color="info" small @click="openTenantDialog">Luo uusi vuokralainen</v-btn>
          </v-col>

          <v-col cols="12">
            <label>Hae vuokralainen</label>
            <v-autocomplete
              :value="tenant.tenantId"
              :items="activeTenants"
              item-text="name"
              item-value="_id"
              no-data-text="Vuokralaisen nimi"
              outlined
              dense
              small-chips
              return-object
              @change="setTenant($event)"
              @input.native="getTenants"
              :rules="validations.required"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Asiakastyyppi</label>
            <v-radio-group
              v-model="tenant.isCompany"
              :mandatory="true"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio label="Yksityinen" :value="false"></v-radio>
              <v-radio label="Yritys" :value="true"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col v-if="!tenant.isCompany" cols="12">
            <label>Vuokralaisella on suomalainen henkilötunnus</label>
            <v-radio-group
              v-model="tenant.finnishCitizen"
              :mandatory="true"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12">
            <label>Nimi</label>
            <v-text-field
              v-model="tenant.name"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col v-if="!tenant.isCompany" cols="12">
            <label>Henkilötunnus</label>
            <v-text-field
              v-model.trim="tenant.social"
              :rules="requiredSocial"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col v-if="tenant.isCompany" cols="12">
            <label>Y-tunnus</label>
            <v-text-field
              v-model="tenant.businessId"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Postiosoite</label>
            <v-text-field
              v-model="tenant.address"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Postinumero</label>
            <v-text-field
              v-model="tenant.zipCode"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Kaupunki</label>
            <v-text-field
              v-model="tenant.city"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Puhelin</label>
            <v-text-field
              v-model="tenant.phone"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Sähköposti</label>
            <v-text-field
              v-model="tenant.email"
              :rules="validations.email2"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Mukana muuttavien tiedot</label>
            <v-textarea v-model="tenant.others" outlined dense hide-details />
          </v-col>

          <v-col cols="12">
            <label>Yhteyshenkilön nimi</label>
            <v-text-field v-model="tenant.contactPersonName" outlined dense hide-details />
          </v-col>

          <v-col cols="12">
            <label>Yhteyshenkilön numero</label>
            <v-text-field v-model="tenant.contactPersonPhone" outlined dense hide-details />
          </v-col>

          <v-col cols="12">
            <!-- Update information -->
            <v-checkbox
              v-model="saveTenantInformation"
              hide-details
              label="Tallenna päävuokralaiselle muuttuneet tiedot"
            ></v-checkbox>
          </v-col>
        </v-col>
      </v-row>

      <!-- OTHER TENANTS -->
      <v-row dense class="justify-center mt-3">
        <h2>Muut vuokralaiset</h2>
      </v-row>

      <v-row dense class="justify-center">
        <v-col v-for="(tenant, idx) in otherTenants" :key="idx + 'a'" cols="12" md="4" class="mt-3">
          <h3 class="mb-2">
            Vuokralainen {{ idx + 2 }}
            <span
              class="ml-2 error--text"
              @click="deleteOtherTenant(idx)"
              style="font-size: 15px; cursor: pointer"
              >Poista
            </span>
          </h3>

          <v-col cols="12">
            <label>Vuokralaisen nimi</label>
            <v-autocomplete
              :value="tenant.tenantId"
              :items="activeTenants"
              item-text="name"
              item-value="_id"
              no-data-text="Vuokralaisen nimi"
              outlined
              dense
              small-chips
              return-object
              @change="updateTenant($event, { ...tenant }, idx)"
              @input.native="getTenants"
              :rules="validations.required"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Nimi</label>
            <v-text-field
              v-model="tenant.name"
              :rules="validations.required"
              outlined
              dense
              disabled
              @change="updateOtherTenants"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="!tenant.isCompany" cols="12">
            <label>Henkilötunnus</label>
            <v-text-field
              v-model.trim="tenant.social"
              :rules="validations.required"
              outlined
              dense
              @change="updateOtherTenants"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="tenant.isCompany" cols="12">
            <label>Y-tunnus</label>
            <v-text-field
              v-model.trim="tenant.businessId"
              :rules="validations.required"
              outlined
              dense
              @change="updateOtherTenants"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <label>Sähköposti</label>
            <v-text-field
              v-model="tenant.email"
              :rules="validations.email2"
              outlined
              dense
              @change="updateOtherTenants"
              hide-details
            ></v-text-field>
          </v-col>
        </v-col>
      </v-row>

      <v-row dense no-gutters class="justify-center mb-3 mt-1">
        <v-btn color="primary" @click="addOtherTenant">Lisää vuokralainen</v-btn>
      </v-row>
    </v-form>

    <!-- Create tenant dialog -->
    <create-tenant-dialog v-model="newTenantDialog" />
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import { mapActions, mapState, mapMutations } from "vuex";
import CreateTenantDialog from "@/components/Tenant/CreateTenantDialog";
import validations from "@/validations";

export default {
  mixins: [mixins],

  components: {
    CreateTenantDialog,
  },

  data(vm) {
    return {
      newTenantDialog: false,
      tenants: [],
      tenantInput: "",
      requiredSocial: [(v) => vm.testSocial(v) || "Pakollinen kenttä"],
      validations,
    };
  },

  computed: {
    ...mapState("tenant", ["activeTenants"]),

    tenant() {
      return this.$store.state.rentalContract.tenant;
    },

    otherTenants: {
      get() {
        return this.$store.state.rentalContract.otherTenants;
      },
      set(arr) {
        this.$store.commit("setContractField", {
          otherTenants: arr,
        });
      },
    },

    saveTenantInformation: {
      get() {
        return this.$store.state.saveTenantInformation;
      },
      set(val) {
        this.$store.state.saveTenantInformation = val;
      },
    },

    showOnlyOnCreate() {
      return this.$route.name == "Luo vuokrasopimus" ? true : false;
    },
  },

  watch: {
    "tenant.social": function () {
      if (this.tenant.social) this.tenant.social = this.tenant.social.toUpperCase();
    },

    tenant: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },

    saveTenantInformation() {
      this.$store.commit("updateLocalStorage");
    },

    otherTenants: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },

    tenantInput: debounce(function (newVal) {
      this.searchTenant(`/api/v1/tenant/search-tenant?search=${newVal}`);
    }, 1000),
  },

  async created() {
    this.$root.$refs.tenant = this;
  },

  methods: {
    ...mapActions("tenant", ["searchTenant"]),
    ...mapMutations("tenant", ["setDefaultTenant"]),

    openTenantDialog() {
      this.setDefaultTenant(this.$store.state.account);
      this.newTenantDialog = true;
    },

    setTenant(tenant) {
      if (tenant) {
        tenant = { ...tenant, tenantId: tenant._id };
        delete tenant._id;
        this.$store.state.rentalContract.tenant = tenant;
      }
    },

    getTenants(event) {
      this.tenantInput = event.target.value;
    },

    async updateTenant(event, tenant, index) {
      if (event) {
        tenant.name = event.name;

        if (event.isCompany) {
          tenant.isCompany = true;
          tenant.businessId = event.businessId;
          tenant.social = null;
        } else {
          tenant.isCompany = false;
          tenant.social = event.social;
          tenant.businessId = null;
        }
        tenant.email = event.email;
        tenant.tenantId = event._id;
        delete tenant._id;

        this.otherTenants.splice(index, 1, tenant);
      }
    },

    updateOtherTenants() {
      this.$store.commit("updateLocalStorage");
    },

    addOtherTenant() {
      let otherTenants = this.otherTenants;
      otherTenants.push({
        isCompany: false,
        name: "",
        social: "",
        email: "",
        businessId: "",
        tenantId: null,
      });
      this.otherTenants = [...otherTenants];
    },

    deleteOtherTenant(index) {
      this.otherTenants.splice(index, 1);
    },

    testSocial(val) {
      if (!val && this.tenant.finnishCitizen) {
        return false;
      } else {
        return true;
      }
    },

    checkIfValid() {
      eventBus.$emit("checktenant", this.$refs.form.validate());
    },

    async getTenant(id) {
      try {
        let res = await axiosMethods.get("/api/v1/tenant/getOne/" + id);

        let tenant = { ...res.data.tenant, tenantId: res.data.tenant._id };
        this.$store.commit("setContractField", { tenant: tenant });
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
}
</style>
