<template>
  <div>
    <v-form ref="form">
      <h3>Pohja</h3>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-select
            v-model="modelId"
            item-text="name"
            item-value="_id"
            :items="models"
            :rules="validations.required"
            :no-data-text="noDataText"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn :disabled="!isValidContract" color="info" @click="contractModelIdChangeHandler">
            Lataa
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import { mapMutations } from "vuex";
import validations from "@/validations";

export default {
  mixins: [mixins],

  props: {
    isValidContract: { type: Boolean, default: true },
    modelType: { type: String, default: "rentalContract" },
    noDataText: { type: String, default: "Ei dataa" },
  },

  data() {
    return {
      models: [],
      validations,
    };
  },

  computed: {
    modelId: {
      get() {
        let model = "";
        if (this.modelType == "rentalContract") {
          model = this.$store.state.rentalContract.modelId;
        } else if (this.modelType == "otherContract") {
          model = this.$store.state.othercontract.contract.modelId;
        } else {
          model = this.$store.state.rentIncrease.newRentIncrease.modelId;
        }
        return model;
      },
      set(value) {
        if (this.modelType == "rentalContract") {
          this.$store.commit("setContractField", { modelId: value });
        } else if (this.modelType == "otherContract") {
          this.$store.state.othercontract.contract.modelId = value;
        } else {
          this.setNewRentIncreaseValue({ val: value, field: "modelId" });
        }
      },
    },
  },

  async created() {
    this.$root.$refs.model = this;

    try {
      let res = await axiosMethods.get(`/api/v1/contract-model/getAll?search=${this.modelType}`);
      this.models = res.data.data;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("rentIncrease", ["setNewRentIncreaseValue"]),

    contractModelIdChangeHandler() {
      let answer = confirm(
        "Haluatko varmasti ladata uuden pohjan? (mahdolliset muokkaukset häviävät)"
      );

      if (answer) {
        this.$emit("contractmodelidchanged");
      }
    },
  },
};
</script>

<style scoped></style>
