<template>
  <v-list nav dense class="menu-wrapper">
    <div v-for="(item, index) in menu" :key="index">
      <div v-if="item.key || item.text" class="pa-1 overline">
        {{ item.key ? item.key : item.text }}
      </div>
      <nav-menu :class="index < menu.length - 1 ? '' : 'mb-5'" :menu="item.items" />
    </div>
  </v-list>
</template>

<script>
import NavMenu from "./NavMenu";

export default {
  components: {
    NavMenu,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.menu-wrapper {
  overflow-y: auto;
}
</style>
