import nordigenService from "../../services/nordigen.service";
import { showPopup } from "../../utils/helpers";

const state = {
  institutions: [],
  loadingInstitutions: false,
  loadingBankAccounts: false,
  bankAccounts: [],
};

const mutations = {
  setInstitutions(state, institutions) {
    state.institutions = [...institutions];
    state.loadingInstitutions = false;
  },

  setBankAccounts(state, accounts) {
    state.bankAccounts = [...accounts];
    state.loadingBankAccounts = false;
  },

  setLoading(state, obj) {
    state[obj.field] = obj.val;
  },

  setFieldValue(state, obj) {
    state.currentRealtor[obj.field] = obj.val;
  },
};

const actions = {
  async getInstitutions({ commit }) {
    try {
      const res = await nordigenService.getInstitutions();
      commit("setInstitutions", res.data.institutions);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getConsentLink(ctx, institutionId) {
    try {
      const res = await nordigenService.getConsentLink(institutionId);
      window.location.href = res.data.link.link;
    } catch (err) {
      showPopup(err, "error");
    }
  },

  saveAccounts(xtx, requisitionId) {
    return nordigenService
      .saveAccounts(requisitionId)
      .then(() => {
        showPopup("Valtuutus onnistui", "success");
      })
      .catch((err) => {
        throw new Error("Valtuutuksessa tapahtui virhe." + " " + err);
      });
  },

  async getAccountDetails({ commit }, ids) {
    try {
      const res = await nordigenService.getAccountDetails(ids);
      commit("setBankAccounts", res.data.accounts);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
