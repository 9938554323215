import theme from "./theme";
import toolbar from "./toolbar";
import analytics from "./analytics";
import navigation from "./navigation";
import contract from "./contract";

export default {
  // product display information
  product: {
    name: "Vuokranet",
    version: "1.0",
  },

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // analytics configs
  analytics,

  // navigation configs
  navigation,

  // contract variables & functions
  contract,
};
