import keyService from "../../services/key.service";
import { showPopup } from "../../utils/helpers";

const state = {
  keys: [],
  totalLength: 0,
  key: {
    code: "",
    apartments: [],
    condominiums: [],
    status: "",
    comments: "",
    location: "",
  },
  keyScrollerKeys: [],
  activeKeys: [],
};

const mutations = {
  addKeys(state, data) {
    state.keys = data.keys;
    state.totalLength = data.totalLength;
  },

  addActiveKeys(state, keys) {
    state.activeKeys = [...keys, ...state.activeKeys].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  setCurrentKey(state, key) {
    state.key = { ...key };
  },

  addKeyScrollerKeys(state, arr) {
    state.keyScrollerKeys = arr;
  },

  addAndReplaceKey(state, key) {
    state.key = key;

    state.keys.splice(
      state.keys.findIndex((x) => x._id == key._id),
      1,
      key
    );
  },

  removeKey(state, key) {
    state.keys.splice(
      state.keys.findIndex((x) => x._id == key._id),
      1
    );
  },
};

const actions = {
  async getAllKeys({ commit }, url) {
    try {
      const res = await keyService.getAllKeys(url);

      commit("addKeys", {
        keys: res.data.keys,
        totalLength: res.data.totalLength ? res.data.totalLength : 0,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async editSingleKey({ commit }, key) {
    try {
      const res = await keyService.editSingleKey(key);

      commit("addAndReplaceKey", { ...res.data.key });
      showPopup("Avain päivitetty", "success");
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async editSingleTenantKey({ commit }, key) {
    try {
      const res = await keyService.editSingleTenantKey(key);

      commit("setCurrentKey", res.data.key);
      commit("tenant/addAndReplaceTenantKey", { ...res.data.key }, { root: true });
      showPopup("Avain päivitetty", "success");
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async searchKey({ commit }, url) {
    try {
      const res = await keyService.searchKey(url);

      commit("addActiveKeys", res.data.keys);
    } catch (err) {
      showPopup(err, " error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
