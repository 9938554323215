import axiosMethods from "../mixins/axios";

export default {
  async getAllReservations(url) {
    return await axiosMethods.get(url);
  },

  async markItemRead(id) {
    return await axiosMethods.update("/api/v1/reservation/mark-read/" + id);
  },

  // async deleteReservation(id) {
  //   return await axiosMethods.delete("/api/v1/reservation/delete/" + id);
  // },
};
