import axiosMethods from "../mixins/axios";
import axios from "axios";

export default {
  async getRentalContracts(data) {
    return await axiosMethods.post(data.url, data.data);
  },

  async createNewRentIncrease(newRentIncrease, contract) {
    return await axiosMethods.post("/api/v1/rent-increase/create", { contract, newRentIncrease });
  },

  async getRentIncreases(url) {
    return await axiosMethods.get(url);
  },

  async deleteInActiveRentIncrease(id) {
    return await axiosMethods.delete(`/api/v1/rent-increase/delete-inactive/${id}`);
  },

  async deleteActiveRentIncrease(data) {
    return await axiosMethods.post(`/api/v1/rent-increase/delete-active/${data.id}`, {
      returnPreviousProducts: data.returnPreviousProducts,
    });
  },

  async getIndexValue(data) {
    let value;
    if (
      data.type === "propertyMaintenanceIndex" ||
      data.type === "propertyMaintenancePlusPercentageIndex"
    ) {
      const year = new Date(data.date).getFullYear();
      value = `${year}Q${Math.floor((new Date(data.date).getMonth() + 3) / 3)}`;
    } else {
      const year = new Date(data.date).getFullYear();
      const month = new Date(data.date).getMonth() + 1;
      const monthNumber = month < 10 ? `0${month}` : `${month}`;
      value = `${year}M${monthNumber}`;
    }

    const { query, url } = this.getIndexQuery(data.type, value);

    return await axios({
      method: "POST",
      url,
      data: {
        query,
        response: {
          format: "json-stat2",
        },
      },
    });
  },

  getIndexQuery(type, value) {
    if (type === "index" || type === "indexPlusPercentage") {
      return {
        url: "https://statfin.stat.fi:443/PxWeb/api/v1/fi/StatFin/khi/statfin_khi_pxt_11xl.px",
        query: [
          {
            code: "Kuukausi",
            selection: {
              filter: "item",
              values: [value],
            },
          },
        ],
      };
    } else if (type === "consumerIndex" || type === "consumerIndexPlusPercentage") {
      return {
        url: "https://pxdata.stat.fi:443/PxWeb/api/v1/fi/StatFin/khi/statfin_khi_pxt_11xb.px",
        query: [
          {
            code: "Kuukausi",
            selection: {
              filter: "item",
              values: [value],
            },
          },
          {
            code: "Hyödyke",
            selection: {
              filter: "item",
              values: ["04"],
            },
          },
          {
            code: "Tiedot",
            selection: {
              filter: "item",
              values: ["indeksipisteluku"],
            },
          },
        ],
      };
    } else if (
      type === "propertyMaintenanceIndex" ||
      type === "propertyMaintenancePlusPercentageIndex"
    ) {
      return {
        url: "https://pxdata.stat.fi:443/PxWeb/api/v1/fi/StatFin/kyki/statfin_kyki_pxt_14ry.px",
        query: [
          {
            code: "Vuosineljännes",
            selection: {
              filter: "item",
              values: [value],
            },
          },
          {
            code: "Rakennustyyppi",
            selection: {
              filter: "item",
              values: ["0."],
            },
          },
          {
            code: "Tiedot",
            selection: {
              filter: "item",
              values: ["indeksipisteluku_kaksikatk"],
            },
          },
        ],
      };
    }
  },
};
