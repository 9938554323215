<template>
  <v-dialog v-model="dialog" width="1920">
    <v-card>
      <v-container fluid>
        <v-toolbar flat height="30">
          <v-card-title class="pa-0 ma-0">Luo uusi vuokralainen</v-card-title>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <tenant-form submit-btn-text="Luo" @submit="submit"> </tenant-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TenantForm from "@/components/Tenant/TenantForm";
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],
  props: { value: { type: Boolean, default: false } },

  components: {
    TenantForm,
  },

  computed: {
    ...mapState("tenant", ["currentTenant"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("tenant", ["createTenant"]),

    async submit() {
      try {
        await this.createTenant(this.currentTenant);
        this.dialog = false;
        this.showPopup("Uusi vuokralainen luotu. Voit valita sen hakuvalikosta", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style></style>
