import axiosMethods from "../mixins/axios";

export default {
  async getInstitutions() {
    return await axiosMethods.get(`/api/v1/open-banking/nordigen/institutions`);
  },

  async getConsentLink(institutionId) {
    return await axiosMethods.get(`/api/v1/open-banking/nordigen/consent-link/${institutionId}`);
  },

  async saveAccounts(requisitionId) {
    return await axiosMethods.get(`/api/v1/open-banking/nordigen/accounts/save/${requisitionId}`);
  },

  async getAccountDetails(ids) {
    return await axiosMethods.post(`/api/v1/open-banking/nordigen/accounts/details`, {
      accountIds: ids,
    });
  },
};
