import axiosMethods from "../mixins/axios";

export default {
  async getContractModels(modelType) {
    return await axiosMethods.get(`/api/v1/contract-model/getAll?search=${modelType}`);
  },

  async getContractModel(modelId) {
    const response = await axiosMethods.get("/api/v1/contract-model/getOne/" + modelId);
    return response.data.data;
  },

  async getContractContent(
    contractData,
    contentData = null,
    contractType,
    rentalContractId = null
  ) {
    const response = await axiosMethods.post("/api/v1/contract-model/get-content", {
      contractData: contractData,
      contentData: contentData,
      contractType,
      rentalContractId,
    });
    return response.data.contract;
  },
};
