import axiosMethods from "../mixins/axios";

export default {
  async getAllPayments(url) {
    return await axiosMethods.get(url);
  },

  async getCheckList(data) {
    return await axiosMethods.post(`/api/v1/outgoing-payment/check-list`, data);
  },

  async getPartnerPayments(url) {
    return await axiosMethods.get(url);
  },

  async getOtherPayments(url) {
    return await axiosMethods.get(url);
  },

  async searchPayments(url) {
    return await axiosMethods.get(url);
  },

  async deletePayment(data) {
    return await axiosMethods.delete(
      `/api/v1/outgoing-payment/delete/${data.paymentId}?subtractReceiptFromOutgoing=${data.subtractReceiptFromOutgoing}`
    );
  },

  async deleteOtherPayment(id) {
    return await axiosMethods.delete("/api/v1/outgoing-payment/delete-other-payment/" + id);
  },

  async deleteManualPayment(data) {
    return await axiosMethods.update(
      "/api/v1/outgoing-payment/delete-manual-payment/" + data.outgoingPaymentId,
      { paymentId: data.paymentId, otherPaymentId: data.otherPaymentId }
    );
  },

  async joinOtherPayment(data) {
    return await axiosMethods.post(`/api/v1/outgoing-payment/join-other/`, data);
  },

  async joinPayment(data) {
    return await axiosMethods.post(`/api/v1/outgoing-payment/join-payment/`, data);
  },

  async deleteAttachedOtherPayment(data) {
    return await axiosMethods.update(
      `/api/v1/outgoing-payment/delete-attached-other-payment/${data.outgoingPaymentId}`,
      { otherPaymentId: data.otherPaymentId }
    );
  },

  async deleteJoinedPayment(data) {
    return await axiosMethods.update(
      `/api/v1/outgoing-payment/delete-attached-joined-payment/${data.currentPaymentId}`,
      { joinedPaymentId: data.joinedPaymentId }
    );
  },

  async makePayment(data) {
    return await axiosMethods.update(
      `/api/v1/outgoing-payment/make-payment/${data.paymentId}`,
      data.payment
    );
  },

  async checkTransactionFile(data) {
    return await axiosMethods.post(
      `/api/v1/outgoing-payment/transactions/file?status=${data.status}&startDate=${data.dates.startDate}&endDate=${data.dates.endDate}`,
      data.formData
    );
  },

  async checkTransactionOpenBanking(data) {
    return await axiosMethods.post(`/api/v1/outgoing-payment/transactions/open-banking`, data);
  },
};
