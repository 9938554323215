import axiosMethods from "../mixins/axios";

export default {
  async getKeyHolders(url) {
    return await axiosMethods.get(url);
  },

  async addAdditionalTenants(data) {
    return await axiosMethods.update("/api/v1/tenant/add-additional-tenant/", {
      tenants: data.tenants,
      contractId: data.contractId,
    });
  },

  async getAdditionalTenants(contractId) {
    return await axiosMethods.get(`/api/v1/tenant/get-additional-tenants/${contractId}`);
  },

  async removeAdditionalTenant(data) {
    return await axiosMethods.update("/api/v1/tenant/remove-additional-tenant/" + data.tenantId, {
      contractId: data.contractId,
      deleteType: data.deleteType,
      removeFrom: data.removeFrom,
    });
  },

  async addKeyHolder(keyHolder) {
    return await axiosMethods.post("/api/v1/tenant/create?populateAll=true", keyHolder);
  },

  async updateKeyHolder(keyHolder) {
    return await axiosMethods.update("/api/v1/tenant/update/" + keyHolder._id, keyHolder);
  },

  async inActivateKeyHolder(id) {
    return await axiosMethods.update("/api/v1/tenant/inactivate/" + id);
  },
};
