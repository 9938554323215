import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import * as directives from "vuetify/lib/directives";
import config from "../configs";
import fi from "vuetify/es5/locale/fi";

Vue.use(Vuetify, {
  directives,
});

// // Translation provided by Vuetify (typescript)
// import pl from 'vuetify/src/locale/pl';

// // Your own translation file
// import sv from './i18n/vuetify/sv';

Vue.component("MyComponent", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "fi";
    },
  },
});

export default new Vuetify({
  rtl: config.theme.isRTL,
  theme: {
    light: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: config.theme.light,
      dark: config.theme.dark,
    },
  },
  lang: {
    locales: { fi },
    current: "fi",
  },
});
