export default {
  productItemTypes: {
    kpl: "stk",
    h: "std",
    kk: "monat",
    "hlö/kk": "pers./monat",
    "m³": "m³",
    kWh: "kWh",
  },

  months: {
    tammikuu: "Januar",
    helmikuu: "Februar",
    maaliskuu: "März",
    huhtikuu: "April",
    toukokuu: "Mai",
    kesäkuu: "Juni",
    heinäkuu: "Juli",
    elokuu: "August",
    syyskuu: "September",
    lokakuu: "Oktober",
    marraskuu: "November",
    joulukuu: "Dezember",
  },
};
