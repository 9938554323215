<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
      class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2"
    >
      <div class="mt-3 mt-md-10 pa-2">
        <div class="display-2 font-weight-bold primary--text">Vuokranet</div>
        <div v-if="!$route.path.includes('signup')" class="title my-2">
          Tervetuloa! Pidetään yhdessä huolta vuokrakohteistasi.
        </div>
        <div v-else class="title my-2">
          Tervetuloa! <br /><br />
          Voit aloittaa maksuttoman kokeilujakson täyttämällä oheisen lomakkeen ja kirjautumalla
          sisään luomallasi sähköpostilla ja salasanalla. Tarvitset Ropo One:n, Talenom Onlinen tai
          Fennoan rajapintatunnukset käsitelläksesi laskuja Vuokranetissä.
          <br />
          <br />
          Ota yhteyttä info@vuokranet.fi keskustellaksesi lisää laskutuksesta.
        </div>
        <v-btn href="https://vuokranet.fi" class="my-4">Kotisivuille</v-btn>
      </div>
      <img class="w-full" src="/images/illustrations/signin-illustration.svg" />
    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { setDefaultStyles } from "../utils/helpers";

export default {
  mounted() {
    setDefaultStyles(this.$vuetify);
  },
};
</script>

<style scoped>
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
