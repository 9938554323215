import depositService from "../../services/deposit.service";
import { showPopup } from "../../utils/helpers";

const state = {
  deposits: [],
  currentDeposit: {},
  attachedDeposits: [],
  tenantDeposits: [],
  totalLength: 0,
  total: {},
};

const mutations = {
  setDeposits(state, data) {
    state.deposits = data.deposits;
    state.totalLength = data.totalLength;
    state.total = data.total[0];
  },

  setAttachedDeposits(state, deposits) {
    state.attachedDeposits = deposits;
  },

  setTenantDeposits(state, deposits) {
    state.tenantDeposits = deposits;
  },

  addDeposit(state, deposit) {
    state.deposits.unshift({ ...deposit });
    state.totalLength++;
  },

  replaceDeposit(state, deposit) {
    const index = state.deposits.findIndex((el) => el._id == deposit._id);
    if (index != -1) state.deposits.splice(index, 1, { ...deposit });
  },

  setDeposit(state, deposit) {
    state.currentDeposit = { ...deposit };
  },

  deleteDeposit(state, id) {
    state.deposits = state.deposits.filter((el) => id != el._id);
    state.totalLength--;
  },
};

const actions = {
  async getDeposits({ commit }, data) {
    try {
      const res = await depositService.getDeposits(data);
      commit("setDeposits", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getDeposit({ commit }, id) {
    try {
      const res = await depositService.getDeposit(id);
      commit("setDeposit", res.data.deposit);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getTenantDeposits({ commit }, tenantId) {
    try {
      const res = await depositService.getTenantDeposits(tenantId);
      commit("setTenantDeposits", res.data.deposits);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getAttachedDeposits({ commit }, serviceId) {
    try {
      const res = await depositService.getAttachedDeposits(serviceId);
      commit("setAttachedDeposits", res.data.deposits);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async createDeposit({ commit }, data) {
    try {
      const res = await depositService.createDeposit(data);
      commit("addDeposit", res.data.deposit);
      if (res.data.emailSend) {
        showPopup("Vakuus luotu", "success");
        showPopup("Sähköposti lähetetty vastaanottajille", "info");
      } else {
        showPopup("Vakuus luotu", "success");
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async editDeposit({ commit }, data) {
    try {
      const res = await depositService.editDeposit(data);
      commit("replaceDeposit", res.data.deposit);
      commit("setDeposit", res.data.deposit);
      if (res.data.emailSend) {
        showPopup("Vakuus tallennettu", "success");
        showPopup("Sähköposti lähetetty vastaanottajille", "info");
      } else {
        showPopup("Vakuus tallennettu", "success");
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async releaseDeposit({ commit }, data) {
    try {
      const res = await depositService.releaseDeposit(data);
      commit("replaceDeposit", res.data.deposit);
      commit("setDeposit", res.data.deposit);
      showPopup("Vakuuden palautusta muokattu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async deleteDeposit({ commit }, id) {
    try {
      await depositService.deleteDeposit(id);
      commit("deleteDeposit", id);
      showPopup("Vakuus poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async resendDepositMessage(ctx, id) {
    try {
      await depositService.resendDepositMessage(id);
      showPopup("Sähköposti lähetetty vastaanottajille", "info");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
