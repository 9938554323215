import { showPopup } from "../../utils/helpers";
import signatureService from "../../services/signature.service";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import store from "../store.js";

const state = {
  signatureItem: {
    signature: { processId: "", document: "" },
    signaturePad: { status: "", signers: [] },
  },
  allSigners: [],
  additionalSigners: [],
  contractType: "",
  showSignatureCanvas: false,
  currentSigner: {},
  signaturePadSignatures: [],
  selectedSigners: [],
  loading: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  setSignatureField(state, { field, val }) {
    state[field] = _.cloneDeep(val);
  },

  setSignatureItem(state, item) {
    state.signatureItem = { ...item };
  },

  setSelectedSigners(state, signers) {
    state.selectedSigners = _.cloneDeep(signers);
  },

  setCurrentSigner(state, signer) {
    state.currentSigner = { ...signer };
  },

  removeFromAllSigners(state, idx) {
    state.allSigners.splice(idx, 1);
  },

  removeSigner(state, signer) {
    const index = state.selectedSigners.findIndex((el) => el.uuid == signer.uuid);
    if (index != -1) state.selectedSigners.splice(index, 1);
  },

  setContractType(state, contractType) {
    state.contractType = contractType.slice();
  },

  showSignatureCanvas(state, boolean) {
    state.showSignatureCanvas = boolean;
  },

  addAdditionalSigner(state) {
    state.additionalSigners.push({ name: "", email: "" });
  },

  removeAdditionalSigner(state, idx) {
    state.additionalSigners.splice(idx, 1);
  },

  getSelectedSigners(state) {
    let allSigners = [];

    if (
      state.signatureItem.signaturePad.signers.length > 0 &&
      state.signatureItem.signaturePad.status != "notSigned"
    ) {
      allSigners = state.signatureItem.signaturePad.signers;
    } else {
      let name;

      // RentalContract
      if (state.contractType == "rentalContract") {
        // If signing for others
        if (state.signatureItem.others.signForOthers) {
          if (store.getters["account/userIsAccountOwner"]) {
            name = store.state.account.currentUser.name;
          } else {
            name = "Vuokranantajan puolesta " + store.state.account.currentUser.name;
          }

          allSigners.push({
            name,
            email: store.state.account.currentUser.email,
            signature: null,
            status: "notSigned",
            uuid: uuidv4(),
          });
        } else {
          formatLandlords(state.signatureItem.landlords).forEach((el) => {
            allSigners.push({
              email: el.email ? el.email : "",
              name: el.name,
              status: "notSigned",
              signature: null,
              uuid: uuidv4(),
            });
          });
        }
        // OtherContract
      } else if (state.contractType == "otherContract") {
        formatLandlords(state.signatureItem.landlords).forEach((el) => {
          allSigners.push({
            email: el.email ? el.email : "",
            name: el.name,
            status: "notSigned",
            signature: null,
            uuid: uuidv4(),
          });
        });
      }

      // Tenant
      allSigners.push({
        email: state.signatureItem.tenant.email ? state.signatureItem.tenant.email : "",
        name: state.signatureItem.tenant.name,
        status: "notSigned",
        signature: null,
        uuid: uuidv4(),
      });

      // Other tenants
      state.signatureItem.otherTenants.forEach((el) => {
        allSigners.push({
          email: el.email ? el.email : "",
          name: el.name,
          status: "notSigned",
          signature: null,
          uuid: uuidv4(),
        });
      });
    }

    // Helper function
    function formatLandlords(landlords) {
      if (state.contractType == "rentalContract") {
        return landlords;
      } else if (state.contractType == "otherContract") {
        return landlords.map((el) => el.landlordId);
      }
    }

    // Commit changes
    this.commit("signature/setSelectedSigners", allSigners);
  },

  saveSignature(state, signature) {
    let index, element;

    if (state.currentSigner._id) {
      index = state.selectedSigners.findIndex((el) => el._id == state.currentSigner._id);
    } else {
      index = state.selectedSigners.findIndex((el) => el.uuid == state.currentSigner.uuid);
    }
    element = state.selectedSigners[index];
    element.signature = signature;
    element.status = "pending";
    state.selectedSigners.splice(index, 1, element);
  },
};

const actions = {
  async signContract({ commit, state }) {
    try {
      const { data } = await signatureService.signContract(state);
      if (state.contractType == "rentalContract") {
        commit("contract/replaceContract", { ...data.contract }, { root: true });
      } else if (state.contractType == "otherContract") {
        commit("othercontract/replaceOtherContract", { ...data.contract }, { root: true });
      }
      commit("setSignatureItem", data.contract);
      commit("getSelectedSigners");
      showPopup("Dokumentti allekirjoitettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async deleteSignatureProcess({ commit, state }) {
    try {
      const { data } = await signatureService.deleteSignatureProcess(state);
      if (state.contractType == "rentalContract") {
        commit("contract/replaceContract", { ...data.contract }, { root: true });
      } else if (state.contractType == "otherContract") {
        commit("othercontract/replaceOtherContract", { ...data.contract }, { root: true });
      }
      commit("setSignatureItem", data.contract);
      commit("getSelectedSigners");
      showPopup("Dokumentin allekirjoitukset poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
