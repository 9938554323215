import axiosMethods from "../mixins/axios";

export default {
  async createAccount() {
    return await axiosMethods.get("/api/v1/stripe/create-account");
  },

  async getAccount() {
    return await axiosMethods.get("/api/v1/stripe/get-account");
  },

  async verifyAccount() {
    return await axiosMethods.get("/api/v1/stripe/verify-account");
  },
};
