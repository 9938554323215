import axiosMethods from "../mixins/axios";

export default {
  async createRealtor(obj) {
    return await axiosMethods.post(`/api/v1/realtor/create`, obj);
  },

  async updateRealtor(obj) {
    return await axiosMethods.update(`/api/v1/realtor/update/${obj._id}`, obj);
  },

  async getRealtors(url) {
    return await axiosMethods.get(url);
  },

  async getRealtorsById(ids) {
    return await axiosMethods.post("/api/v1/realtor/get-realtors-by-id", ids);
  },

  async getOneRealtor(url) {
    return await axiosMethods.get(url);
  },

  async inActivateRealtor(id) {
    return await axiosMethods.update("/api/v1/realtor/inactivate/" + id);
  },

  async searchRealtor(url) {
    return await axiosMethods.get(url);
  },
};
