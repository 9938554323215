export default {
  houseTypes: [
    "Kerrostalo",
    "Rivitalo",
    "Paritalo",
    "Omakotitalo",
    "Luhtitalo",
    "Puutalo-osake",
    "Varasto",
    "Huone",
    "Autopaikka",
    "Autotalli",
    "Toimitila",
    "Harrastetila",
    "Kiinteistö",
    "Muu tila",
  ],

  doorTypes: ["Nosto-ovi", "Käyntiovi", "Ei ovea"],

  productItemTypes: ["kpl", "h", "kk", "hlö/kk", "m³", "kWh"],

  productTypes: [
    "Vuokra",
    "Vesi",
    "Vakuus",
    "Digivakuus",
    "Sähkö",
    "Mediamaksu",
    "Autopaikka",
    "Autohalli",
    "Saunamaksu",
    "Yhtiövastike",
    "Yhtiölaina",
    "Sopimussakko",
    "Muu",
  ],

  electricityTypes: [
    { val: 1, text: "Vuokralaisen oma sopimus" },
    { val: 2, text: "Kiinteä kk-maksu" },
    { val: 3, text: "Sisältyy vuokraan" },
  ],

  vatTypes: [
    { text: "Alv 25.5 %", val: 25.5 },
    { text: "Alv 24 %", val: 24 },
    { text: "Alv 14 %", val: 14 },
    { text: "Alv 10 %", val: 10 },
    { text: "Alv 0 %", val: 0 },
  ],

  receiptTypes: [
    { text: "Muu kulu", val: "receipt" },
    { text: "Vastike-/Yhtiölainakulu", val: "maintenance" },
  ],

  loanMethods: [
    { text: "Annuiteetti", val: "annuity" },
    { text: "Kiinteä tasaerä", val: "fixed annuity" },
    { text: "Tasalyhennys", val: "equal instalment" },
  ],

  loanTypes: [
    { text: "Euribor 12 kk", val: "euribor 12" },
    { text: "Euribor 6 kk", val: "euribor 6" },
    { text: "Euribor 3 kk", val: "euribor 3" },
  ],

  loanHandlingTypes: [
    { text: "Tuloutettu", val: "income recognition" },
    { text: "Rahastoitu", val: "funding" },
  ],

  languages: [
    { text: "Suomi", val: "fin" },
    { text: "Englanti", val: "eng" },
    { text: "Ruotsi", val: "swe" },
    { text: "Saksa", val: "ger" },
  ],

  months: [
    "tammikuu",
    "helmikuu",
    "maaliskuu",
    "huhtikuu",
    "toukokuu",
    "kesäkuu",
    "heinäkuu",
    "elokuu",
    "syyskuu",
    "lokakuu",
    "marraskuu",
    "joulukuu",
  ],

  sendTypes(tenantIsCompany, landlordIsCompany) {
    let sendTypes = [
      { text: "Email", val: "email" },
      { text: "Itse toimitettava", val: "print" },
      { text: "Kirje", val: "post" },
    ];

    if (tenantIsCompany && landlordIsCompany)
      sendTypes.push({ text: "Verkkolasku", val: "evoice" });

    return sendTypes;
  },

  paymentTerms(invoiceInterval) {
    let paymentTerms = [];

    if (invoiceInterval == 1) {
      for (let i = 28; i >= 1; i--) {
        paymentTerms.push({ text: `${i}`, days: i });
      }
    } else if (invoiceInterval >= 2) {
      for (let i = 30; i >= 1; i--) {
        paymentTerms.push({ text: `${i}`, days: i });
      }
    }

    return paymentTerms;
  },

  dueDates: [
    { text: "1. päivä", date: 1 },
    { text: "2. päivä", date: 2 },
    { text: "3. päivä", date: 3 },
    { text: "4. päivä", date: 4 },
    { text: "5. päivä", date: 5 },
    { text: "6. päivä", date: 6 },
    { text: "7. päivä", date: 7 },
    { text: "8. päivä", date: 8 },
    { text: "9. päivä", date: 9 },
    { text: "10. päivä", date: 10 },
    { text: "11. päivä", date: 11 },
    { text: "12. päivä", date: 12 },
    { text: "13. päivä", date: 13 },
    { text: "14. päivä", date: 14 },
    { text: "15. päivä", date: 15 },
    { text: "16. päivä", date: 16 },
    { text: "17. päivä", date: 17 },
    { text: "18. päivä", date: 18 },
    { text: "19. päivä", date: 19 },
    { text: "20. päivä", date: 20 },
    { text: "21. päivä", date: 21 },
    { text: "22. päivä", date: 22 },
    { text: "23. päivä", date: 23 },
    { text: "24. päivä", date: 24 },
    { text: "25. päivä", date: 25 },
    { text: "26. päivä", date: 26 },
    { text: "27. päivä", date: 27 },
    { text: "28. päivä", date: 28 },
    { text: "Kuun viimeinen päivä", date: 100 },
  ],

  invoiceIntervals: [
    { text: "1 kuukausi", months: 1 },
    { text: "2 kuukautta", months: 2 },
    { text: "3 kuukautta", months: 3 },
    { text: "4 kuukautta", months: 4 },
    { text: "5 kuukautta", months: 5 },
    { text: "6 kuukautta", months: 6 },
    { text: "7 kuukautta", months: 7 },
    { text: "8 kuukautta", months: 8 },
    { text: "9 kuukautta", months: 9 },
    { text: "10 kuukautta", months: 10 },
    { text: "11 kuukautta", months: 11 },
    { text: "12 kuukautta", months: 12 },
  ],

  fonts: [
    "Bai Jamjuree",
    "Quicksand",
    "Roboto",
    "Lato",
    "Open Sans",
    "Inconsolata",
    "Montserrat",
    "Poppins",
    "Oswald",
    "Raleway",
    "Merriweather",
    "League Spartan",
    "Nunito Sans",
    "Roboto Serif",
  ],

  rentIncreaseTypes: [
    { text: "Prosenttikorotus", val: "percentage" },
    { text: "Elinkustannusindeksin pisteluku", val: "index" },
    { text: "Elinkustannusindeksin pisteluku ja prosenttikorotus", val: "indexPlusPercentage" },
    { text: "Kuluttajaindeksin pisteluku (asuminen 04)", val: "consumerIndex" },
    {
      text: "Kuluttajaindeksin pisteluku (asuminen 04) ja prosenttikorotus",
      val: "consumerIndexPlusPercentage",
    },
    { text: "Kiinteistön ylläpidon kustannusindeksi", val: "propertyMaintenanceIndex" },
    {
      text: "Kiinteistön ylläpidon kustannusindeksi ja prosenttikorotus",
      val: "propertyMaintenancePlusPercentageIndex",
    },
    { text: "Kiinteä summa", val: "fixed" },
    { text: "Jokin muu", val: "own" },
  ],

  footerProps: {
    "items-per-page-options": [5, 10, 15, 20, 50, 100],
    showFirstLastPage: true,
    firstIcon: "mdi-arrow-collapse-left",
    lastIcon: "mdi-arrow-collapse-right",
    prevIcon: "mdi-minus",
    nextIcon: "mdi-plus",
  },

  itemsPerPage: [5, 10, 15, 20, 50, 100],

  themeColors: {
    primary: "#0096C7",
    info: "#2196F3",
    success: "#00BA86",
    warning: "#ECB639",
    error: "#EF476F",
    draft: "#9A968D",
    footer: "#0096C7",
    "footer-text": "#FFFFFF",
  },

  countries: {
    Afghanistan: { alpha2: "AF", alpha3: "AFG", numeric: "004" },
    Albania: { alpha2: "AL", alpha3: "ALB", numeric: "008" },
    Algeria: { alpha2: "DZ", alpha3: "DZA", numeric: "012" },
    "American Samoa": { alpha2: "AS", alpha3: "ASM", numeric: "016" },
    Andorra: { alpha2: "AD", alpha3: "AND", numeric: "020" },
    Angola: { alpha2: "AO", alpha3: "AGO", numeric: "024" },
    Anguilla: { alpha2: "AI", alpha3: "AIA", numeric: 660 },
    Antarctica: { alpha2: "AQ", alpha3: "ATA", numeric: "010" },
    "Antigua and Barbuda": { alpha2: "AG", alpha3: "ATG", numeric: "028" },
    Argentina: { alpha2: "AR", alpha3: "ARG", numeric: "032" },
    Armenia: { alpha2: "AM", alpha3: "ARM", numeric: "051" },
    Aruba: { alpha2: "AW", alpha3: "ABW", numeric: 533 },
    Australia: { alpha2: "AU", alpha3: "AUS", numeric: "036" },
    Austria: { alpha2: "AT", alpha3: "AUT", numeric: "040" },
    Azerbaijan: { alpha2: "AZ", alpha3: "AZE", numeric: "031" },
    "Bahamas (the)": { alpha2: "BS", alpha3: "BHS", numeric: "044" },
    Bahrain: { alpha2: "BH", alpha3: "BHR", numeric: "048" },
    Bangladesh: { alpha2: "BD", alpha3: "BGD", numeric: "050" },
    Barbados: { alpha2: "BB", alpha3: "BRB", numeric: "052" },
    Belarus: { alpha2: "BY", alpha3: "BLR", numeric: 112 },
    Belgium: { alpha2: "BE", alpha3: "BEL", numeric: "056" },
    Belize: { alpha2: "BZ", alpha3: "BLZ", numeric: "084" },
    Benin: { alpha2: "BJ", alpha3: "BEN", numeric: 204 },
    Bermuda: { alpha2: "BM", alpha3: "BMU", numeric: "060" },
    Bhutan: { alpha2: "BT", alpha3: "BTN", numeric: "064" },
    "Bolivia (Plurinational State of)": { alpha2: "BO", alpha3: "BOL", numeric: "068" },
    "Bonaire, Sint Eustatius and Saba": { alpha2: "BQ", alpha3: "BES", numeric: 535 },
    "Bosnia and Herzegovina": { alpha2: "BA", alpha3: "BIH", numeric: "070" },
    Botswana: { alpha2: "BW", alpha3: "BWA", numeric: "072" },
    "Bouvet Island": { alpha2: "BV", alpha3: "BVT", numeric: "074" },
    Brazil: { alpha2: "BR", alpha3: "BRA", numeric: "076" },
    "British Indian Ocean Territory (the)": { alpha2: "IO", alpha3: "IOT", numeric: "086" },
    "Brunei Darussalam": { alpha2: "BN", alpha3: "BRN", numeric: "096" },
    Bulgaria: { alpha2: "BG", alpha3: "BGR", numeric: 100 },
    "Burkina Faso": { alpha2: "BF", alpha3: "BFA", numeric: 854 },
    Burundi: { alpha2: "BI", alpha3: "BDI", numeric: 108 },
    "Cabo Verde": { alpha2: "CV", alpha3: "CPV", numeric: 132 },
    Cambodia: { alpha2: "KH", alpha3: "KHM", numeric: 116 },
    Cameroon: { alpha2: "CM", alpha3: "CMR", numeric: 120 },
    Canada: { alpha2: "CA", alpha3: "CAN", numeric: 124 },
    "Cayman Islands (the)": { alpha2: "KY", alpha3: "CYM", numeric: 136 },
    "Central African Republic (the)": { alpha2: "CF", alpha3: "CAF", numeric: 140 },
    Chad: { alpha2: "TD", alpha3: "TCD", numeric: 148 },
    Chile: { alpha2: "CL", alpha3: "CHL", numeric: 152 },
    China: { alpha2: "CN", alpha3: "CHN", numeric: 156 },
    "Christmas Island": { alpha2: "CX", alpha3: "CXR", numeric: 162 },
    "Cocos (Keeling) Islands (the)": { alpha2: "CC", alpha3: "CCK", numeric: 166 },
    Colombia: { alpha2: "CO", alpha3: "COL", numeric: 170 },
    "Comoros (the)": { alpha2: "KM", alpha3: "COM", numeric: 174 },
    "Congo (the Democratic Republic of the)": { alpha2: "CD", alpha3: "COD", numeric: 180 },
    "Congo (the)": { alpha2: "CG", alpha3: "COG", numeric: 178 },
    "Cook Islands (the)": { alpha2: "CK", alpha3: "COK", numeric: 184 },
    "Costa Rica": { alpha2: "CR", alpha3: "CRI", numeric: 188 },
    Croatia: { alpha2: "HR", alpha3: "HRV", numeric: 191 },
    Cuba: { alpha2: "CU", alpha3: "CUB", numeric: 192 },
    Curaçao: { alpha2: "CW", alpha3: "CUW", numeric: 531 },
    Cyprus: { alpha2: "CY", alpha3: "CYP", numeric: 196 },
    Czechia: { alpha2: "CZ", alpha3: "CZE", numeric: 203 },
    "Côte d'Ivoire": { alpha2: "CI", alpha3: "CIV", numeric: 384 },
    Denmark: { alpha2: "DK", alpha3: "DNK", numeric: 208 },
    Djibouti: { alpha2: "DJ", alpha3: "DJI", numeric: 262 },
    Dominica: { alpha2: "DM", alpha3: "DMA", numeric: 212 },
    "Dominican Republic (the)": { alpha2: "DO", alpha3: "DOM", numeric: 214 },
    Ecuador: { alpha2: "EC", alpha3: "ECU", numeric: 218 },
    Egypt: { alpha2: "EG", alpha3: "EGY", numeric: 818 },
    "El Salvador": { alpha2: "SV", alpha3: "SLV", numeric: 222 },
    "Equatorial Guinea": { alpha2: "GQ", alpha3: "GNQ", numeric: 226 },
    Eritrea: { alpha2: "ER", alpha3: "ERI", numeric: 232 },
    Estonia: { alpha2: "EE", alpha3: "EST", numeric: 233 },
    Eswatini: { alpha2: "SZ", alpha3: "SWZ", numeric: 748 },
    Ethiopia: { alpha2: "ET", alpha3: "ETH", numeric: 231 },
    "Falkland Islands (the) [Malvinas]": { alpha2: "FK", alpha3: "FLK", numeric: 238 },
    "Faroe Islands (the)": { alpha2: "FO", alpha3: "FRO", numeric: 234 },
    Fiji: { alpha2: "FJ", alpha3: "FJI", numeric: 242 },
    Finland: { alpha2: "FI", alpha3: "FIN", numeric: 246 },
    France: { alpha2: "FR", alpha3: "FRA", numeric: 250 },
    "French Guiana": { alpha2: "GF", alpha3: "GUF", numeric: 254 },
    "French Polynesia": { alpha2: "PF", alpha3: "PYF", numeric: 258 },
    "French Southern Territories (the)": { alpha2: "TF", alpha3: "ATF", numeric: 260 },
    Gabon: { alpha2: "GA", alpha3: "GAB", numeric: 266 },
    "Gambia (the)": { alpha2: "GM", alpha3: "GMB", numeric: 270 },
    Georgia: { alpha2: "GE", alpha3: "GEO", numeric: 268 },
    Germany: { alpha2: "DE", alpha3: "DEU", numeric: 276 },
    Ghana: { alpha2: "GH", alpha3: "GHA", numeric: 288 },
    Gibraltar: { alpha2: "GI", alpha3: "GIB", numeric: 292 },
    Greece: { alpha2: "GR", alpha3: "GRC", numeric: 300 },
    Greenland: { alpha2: "GL", alpha3: "GRL", numeric: 304 },
    Grenada: { alpha2: "GD", alpha3: "GRD", numeric: 308 },
    Guadeloupe: { alpha2: "GP", alpha3: "GLP", numeric: 312 },
    Guam: { alpha2: "GU", alpha3: "GUM", numeric: 316 },
    Guatemala: { alpha2: "GT", alpha3: "GTM", numeric: 320 },
    Guernsey: { alpha2: "GG", alpha3: "GGY", numeric: 831 },
    Guinea: { alpha2: "GN", alpha3: "GIN", numeric: 324 },
    "Guinea-Bissau": { alpha2: "GW", alpha3: "GNB", numeric: 624 },
    Guyana: { alpha2: "GY", alpha3: "GUY", numeric: 328 },
    Haiti: { alpha2: "HT", alpha3: "HTI", numeric: 332 },
    "Heard Island and McDonald Islands": { alpha2: "HM", alpha3: "HMD", numeric: 334 },
    "Holy See (the)": { alpha2: "VA", alpha3: "VAT", numeric: 336 },
    Honduras: { alpha2: "HN", alpha3: "HND", numeric: 340 },
    "Hong Kong": { alpha2: "HK", alpha3: "HKG", numeric: 344 },
    Hungary: { alpha2: "HU", alpha3: "HUN", numeric: 348 },
    Iceland: { alpha2: "IS", alpha3: "ISL", numeric: 352 },
    India: { alpha2: "IN", alpha3: "IND", numeric: 356 },
    Indonesia: { alpha2: "ID", alpha3: "IDN", numeric: 360 },
    "Iran (Islamic Republic of)": { alpha2: "IR", alpha3: "IRN", numeric: 364 },
    Iraq: { alpha2: "IQ", alpha3: "IRQ", numeric: 368 },
    Ireland: { alpha2: "IE", alpha3: "IRL", numeric: 372 },
    "Isle of Man": { alpha2: "IM", alpha3: "IMN", numeric: 833 },
    Israel: { alpha2: "IL", alpha3: "ISR", numeric: 376 },
    Italy: { alpha2: "IT", alpha3: "ITA", numeric: 380 },
    Jamaica: { alpha2: "JM", alpha3: "JAM", numeric: 388 },
    Japan: { alpha2: "JP", alpha3: "JPN", numeric: 392 },
    Jersey: { alpha2: "JE", alpha3: "JEY", numeric: 832 },
    Jordan: { alpha2: "JO", alpha3: "JOR", numeric: 400 },
    Kazakhstan: { alpha2: "KZ", alpha3: "KAZ", numeric: 398 },
    Kenya: { alpha2: "KE", alpha3: "KEN", numeric: 404 },
    Kiribati: { alpha2: "KI", alpha3: "KIR", numeric: 296 },
    "Korea (the Democratic People's Republic of)": { alpha2: "KP", alpha3: "PRK", numeric: 408 },
    "Korea (the Republic of)": { alpha2: "KR", alpha3: "KOR", numeric: 410 },
    Kuwait: { alpha2: "KW", alpha3: "KWT", numeric: 414 },
    Kyrgyzstan: { alpha2: "KG", alpha3: "KGZ", numeric: 417 },
    "Lao People's Democratic Republic (the)": { alpha2: "LA", alpha3: "LAO", numeric: 418 },
    Latvia: { alpha2: "LV", alpha3: "LVA", numeric: 428 },
    Lebanon: { alpha2: "LB", alpha3: "LBN", numeric: 422 },
    Lesotho: { alpha2: "LS", alpha3: "LSO", numeric: 426 },
    Liberia: { alpha2: "LR", alpha3: "LBR", numeric: 430 },
    Libya: { alpha2: "LY", alpha3: "LBY", numeric: 434 },
    Liechtenstein: { alpha2: "LI", alpha3: "LIE", numeric: 438 },
    Lithuania: { alpha2: "LT", alpha3: "LTU", numeric: 440 },
    Luxembourg: { alpha2: "LU", alpha3: "LUX", numeric: 442 },
    Macao: { alpha2: "MO", alpha3: "MAC", numeric: 446 },
    Madagascar: { alpha2: "MG", alpha3: "MDG", numeric: 450 },
    Malawi: { alpha2: "MW", alpha3: "MWI", numeric: 454 },
    Malaysia: { alpha2: "MY", alpha3: "MYS", numeric: 458 },
    Maldives: { alpha2: "MV", alpha3: "MDV", numeric: 462 },
    Mali: { alpha2: "ML", alpha3: "MLI", numeric: 466 },
    Malta: { alpha2: "MT", alpha3: "MLT", numeric: 470 },
    "Marshall Islands (the)": { alpha2: "MH", alpha3: "MHL", numeric: 584 },
    Martinique: { alpha2: "MQ", alpha3: "MTQ", numeric: 474 },
    Mauritania: { alpha2: "MR", alpha3: "MRT", numeric: 478 },
    Mauritius: { alpha2: "MU", alpha3: "MUS", numeric: 480 },
    Mayotte: { alpha2: "YT", alpha3: "MYT", numeric: 175 },
    Mexico: { alpha2: "MX", alpha3: "MEX", numeric: 484 },
    "Micronesia (Federated States of)": { alpha2: "FM", alpha3: "FSM", numeric: 583 },
    "Moldova (the Republic of)": { alpha2: "MD", alpha3: "MDA", numeric: 498 },
    Monaco: { alpha2: "MC", alpha3: "MCO", numeric: 492 },
    Mongolia: { alpha2: "MN", alpha3: "MNG", numeric: 496 },
    Montenegro: { alpha2: "ME", alpha3: "MNE", numeric: 499 },
    Montserrat: { alpha2: "MS", alpha3: "MSR", numeric: 500 },
    Morocco: { alpha2: "MA", alpha3: "MAR", numeric: 504 },
    Mozambique: { alpha2: "MZ", alpha3: "MOZ", numeric: 508 },
    Myanmar: { alpha2: "MM", alpha3: "MMR", numeric: 104 },
    Namibia: { alpha2: "NA", alpha3: "NAM", numeric: 516 },
    Nauru: { alpha2: "NR", alpha3: "NRU", numeric: 520 },
    Nepal: { alpha2: "NP", alpha3: "NPL", numeric: 524 },
    "Netherlands (the)": { alpha2: "NL", alpha3: "NLD", numeric: 528 },
    "New Caledonia": { alpha2: "NC", alpha3: "NCL", numeric: 540 },
    "New Zealand": { alpha2: "NZ", alpha3: "NZL", numeric: 554 },
    Nicaragua: { alpha2: "NI", alpha3: "NIC", numeric: 558 },
    "Niger (the)": { alpha2: "NE", alpha3: "NER", numeric: 562 },
    Nigeria: { alpha2: "NG", alpha3: "NGA", numeric: 566 },
    Niue: { alpha2: "NU", alpha3: "NIU", numeric: 570 },
    "Norfolk Island": { alpha2: "NF", alpha3: "NFK", numeric: 574 },
    "Northern Mariana Islands (the)": { alpha2: "MP", alpha3: "MNP", numeric: 580 },
    Norway: { alpha2: "NO", alpha3: "NOR", numeric: 578 },
    Oman: { alpha2: "OM", alpha3: "OMN", numeric: 512 },
    Pakistan: { alpha2: "PK", alpha3: "PAK", numeric: 586 },
    Palau: { alpha2: "PW", alpha3: "PLW", numeric: 585 },
    "Palestine, State of": { alpha2: "PS", alpha3: "PSE", numeric: 275 },
    Panama: { alpha2: "PA", alpha3: "PAN", numeric: 591 },
    "Papua New Guinea": { alpha2: "PG", alpha3: "PNG", numeric: 598 },
    Paraguay: { alpha2: "PY", alpha3: "PRY", numeric: 600 },
    Peru: { alpha2: "PE", alpha3: "PER", numeric: 604 },
    "Philippines (the)": { alpha2: "PH", alpha3: "PHL", numeric: 608 },
    Pitcairn: { alpha2: "PN", alpha3: "PCN", numeric: 612 },
    Poland: { alpha2: "PL", alpha3: "POL", numeric: 616 },
    Portugal: { alpha2: "PT", alpha3: "PRT", numeric: 620 },
    "Puerto Rico": { alpha2: "PR", alpha3: "PRI", numeric: 630 },
    Qatar: { alpha2: "QA", alpha3: "QAT", numeric: 634 },
    "Republic of North Macedonia": { alpha2: "MK", alpha3: "MKD", numeric: 807 },
    Romania: { alpha2: "RO", alpha3: "ROU", numeric: 642 },
    "Russian Federation (the)": { alpha2: "RU", alpha3: "RUS", numeric: 643 },
    Rwanda: { alpha2: "RW", alpha3: "RWA", numeric: 646 },
    Réunion: { alpha2: "RE", alpha3: "REU", numeric: 638 },
    "Saint Barthélemy": { alpha2: "BL", alpha3: "BLM", numeric: 652 },
    "Saint Helena, Ascension and Tristan da Cunha": { alpha2: "SH", alpha3: "SHN", numeric: 654 },
    "Saint Kitts and Nevis": { alpha2: "KN", alpha3: "KNA", numeric: 659 },
    "Saint Lucia": { alpha2: "LC", alpha3: "LCA", numeric: 662 },
    "Saint Martin (French part)": { alpha2: "MF", alpha3: "MAF", numeric: 663 },
    "Saint Pierre and Miquelon": { alpha2: "PM", alpha3: "SPM", numeric: 666 },
    "Saint Vincent and the Grenadines": { alpha2: "VC", alpha3: "VCT", numeric: 670 },
    Samoa: { alpha2: "WS", alpha3: "WSM", numeric: 882 },
    "San Marino": { alpha2: "SM", alpha3: "SMR", numeric: 674 },
    "Sao Tome and Principe": { alpha2: "ST", alpha3: "STP", numeric: 678 },
    "Saudi Arabia": { alpha2: "SA", alpha3: "SAU", numeric: 682 },
    Senegal: { alpha2: "SN", alpha3: "SEN", numeric: 686 },
    Serbia: { alpha2: "RS", alpha3: "SRB", numeric: 688 },
    Seychelles: { alpha2: "SC", alpha3: "SYC", numeric: 690 },
    "Sierra Leone": { alpha2: "SL", alpha3: "SLE", numeric: 694 },
    Singapore: { alpha2: "SG", alpha3: "SGP", numeric: 702 },
    "Sint Maarten (Dutch part)": { alpha2: "SX", alpha3: "SXM", numeric: 534 },
    Slovakia: { alpha2: "SK", alpha3: "SVK", numeric: 703 },
    Slovenia: { alpha2: "SI", alpha3: "SVN", numeric: 705 },
    "Solomon Islands": { alpha2: "SB", alpha3: "SLB", numeric: "090" },
    Somalia: { alpha2: "SO", alpha3: "SOM", numeric: 706 },
    "South Africa": { alpha2: "ZA", alpha3: "ZAF", numeric: 710 },
    "South Georgia and the South Sandwich Islands": { alpha2: "GS", alpha3: "SGS", numeric: 239 },
    "South Sudan": { alpha2: "SS", alpha3: "SSD", numeric: 728 },
    Spain: { alpha2: "ES", alpha3: "ESP", numeric: 724 },
    "Sri Lanka": { alpha2: "LK", alpha3: "LKA", numeric: 144 },
    "Sudan (the)": { alpha2: "SD", alpha3: "SDN", numeric: 729 },
    Suriname: { alpha2: "SR", alpha3: "SUR", numeric: 740 },
    "Svalbard and Jan Mayen": { alpha2: "SJ", alpha3: "SJM", numeric: 744 },
    Sweden: { alpha2: "SE", alpha3: "SWE", numeric: 752 },
    Switzerland: { alpha2: "CH", alpha3: "CHE", numeric: 756 },
    "Syrian Arab Republic": { alpha2: "SY", alpha3: "SYR", numeric: 760 },
    "Taiwan (Province of China)": { alpha2: "TW", alpha3: "TWN", numeric: 158 },
    Tajikistan: { alpha2: "TJ", alpha3: "TJK", numeric: 762 },
    "Tanzania, United Republic of": { alpha2: "TZ", alpha3: "TZA", numeric: 834 },
    Thailand: { alpha2: "TH", alpha3: "THA", numeric: 764 },
    "Timor-Leste": { alpha2: "TL", alpha3: "TLS", numeric: 626 },
    Togo: { alpha2: "TG", alpha3: "TGO", numeric: 768 },
    Tokelau: { alpha2: "TK", alpha3: "TKL", numeric: 772 },
    Tonga: { alpha2: "TO", alpha3: "TON", numeric: 776 },
    "Trinidad and Tobago": { alpha2: "TT", alpha3: "TTO", numeric: 780 },
    Tunisia: { alpha2: "TN", alpha3: "TUN", numeric: 788 },
    Turkey: { alpha2: "TR", alpha3: "TUR", numeric: 792 },
    Turkmenistan: { alpha2: "TM", alpha3: "TKM", numeric: 795 },
    "Turks and Caicos Islands (the)": { alpha2: "TC", alpha3: "TCA", numeric: 796 },
    Tuvalu: { alpha2: "TV", alpha3: "TUV", numeric: 798 },
    Uganda: { alpha2: "UG", alpha3: "UGA", numeric: 800 },
    Ukraine: { alpha2: "UA", alpha3: "UKR", numeric: 804 },
    "United Arab Emirates (the)": { alpha2: "AE", alpha3: "ARE", numeric: 784 },
    "United Kingdom of Great Britain and Northern Ireland (the)": {
      alpha2: "GB",
      alpha3: "GBR",
      numeric: 826,
    },
    "United States Minor Outlying Islands (the)": { alpha2: "UM", alpha3: "UMI", numeric: 581 },
    "United States of America (the)": { alpha2: "US", alpha3: "USA", numeric: 840 },
    Uruguay: { alpha2: "UY", alpha3: "URY", numeric: 858 },
    Uzbekistan: { alpha2: "UZ", alpha3: "UZB", numeric: 860 },
    Vanuatu: { alpha2: "VU", alpha3: "VUT", numeric: 548 },
    "Venezuela (Bolivarian Republic of)": { alpha2: "VE", alpha3: "VEN", numeric: 862 },
    "Viet Nam": { alpha2: "VN", alpha3: "VNM", numeric: 704 },
    "Virgin Islands (British)": { alpha2: "VG", alpha3: "VGB", numeric: "092" },
    "Virgin Islands (U.S.)": { alpha2: "VI", alpha3: "VIR", numeric: 850 },
    "Wallis and Futuna": { alpha2: "WF", alpha3: "WLF", numeric: 876 },
    "Western Sahara": { alpha2: "EH", alpha3: "ESH", numeric: 732 },
    Yemen: { alpha2: "YE", alpha3: "YEM", numeric: 887 },
    Zambia: { alpha2: "ZM", alpha3: "ZMB", numeric: 894 },
    Zimbabwe: { alpha2: "ZW", alpha3: "ZWE", numeric: 716 },
    "Åland Islands": { alpha2: "AX", alpha3: "ALA", numeric: 248 },
  },

  availableInvoiceAddressCountries: {
    ropoCapital: ["Finland", null, undefined, ""],
    talenom: ["Finland", "Sweden"],
  },
};
