import axiosMethods from "../mixins/axios";

export default {
  async signContract(state) {
    return await axiosMethods.post(`/api/v1/signature/sign-contract/${state.signatureItem._id}`, {
      signers: state.selectedSigners,
      contractType: state.contractType,
    });
  },

  async deleteSignatureProcess(state) {
    return await axiosMethods.post(`/api/v1/signature/delete-process/${state.signatureItem._id}`, {
      contractType: state.contractType,
    });
  },
};
