import axiosMethods from "../mixins/axios";

export default {
  async getAllContacts(url) {
    return await axiosMethods.get(url);
  },

  async getAllQueueContacts(url) {
    return await axiosMethods.get(url);
  },

  async deleteContact(id) {
    return await axiosMethods.delete("/api/v1/contact/delete/" + id);
  },

  async deleteQueueContact(id) {
    return await axiosMethods.delete("/api/v1/contact/delete-queue/" + id);
  },

  async markItemRead(data) {
    return await axiosMethods.update("/api/v1/contact/mark-read/" + data.id, { type: data.type });
  },
};
