import globalValues from "../configs/globalValues";

export default {
  getAddressListName(data) {
    if (data) {
      const country = data.address.country;
      const road = data.address.road;
      const number = data.address.house_number ? data.address.house_number : "";
      const city = data.address.city
        ? data.address.city
        : data.address.town
        ? data.address.town
        : data.address.hamlet
        ? data.address.hamlet
        : "";

      if (number != "" && city != "") {
        return `${road} ${number}, ${city}`;
      } else if (number != "" && city == "") {
        return `${road} ${number} (${country})`;
      } else if (number == "" && city != "") {
        return `${road}, ${city}`;
      } else {
        return `${road} (${country})`;
      }
    }
  },

  getAddressData(data) {
    if (data) {
      const city = data.address.city
        ? data.address.city
        : data.address.town
        ? data.address.town
        : data.address.hamlet
        ? data.address.hamlet
        : "";
      const zipCode = data.address.postcode ? data.address.postcode : "";
      const street = data.address.road ? data.address.road : "";
      const number = data.address.house_number ? data.address.house_number : "";
      const address =
        street != "" && number != ""
          ? `${street} ${number}`
          : street != "" && number == ""
          ? street
          : "";
      let country = "";

      for (const [key, value] of Object.entries(globalValues.countries)) {
        const alpha2 = value.alpha2.toLowerCase();
        const countryCode = data.address?.country_code
          ? data.address.country_code.toLowerCase()
          : "";
        if (alpha2 === countryCode) {
          country = key;
          break;
        }
      }

      return { address, zipCode, city, country };
    }
  },
};
