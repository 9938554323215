import keyHolderService from "../../services/keyHolder.service";
import { showPopup } from "../../utils/helpers";

const state = {
  keyHolder: {},
  keyHolders: [],
  additionalTenants: [],
  removedTenants: [],
  totalLength: 0,
};

const mutations = {
  addKeyHolders(state, data) {
    state.keyHolders = data.keyHolders;
    state.totalLength = data.totalLength;
  },

  addAdditionalTenants(state, additionalTenants) {
    state.additionalTenants = [...additionalTenants];
  },

  addRemovedTenants(state, removedTenants) {
    state.removedTenants = [...removedTenants];
  },

  updateKeyHolder(state, keyHolder) {
    state.keyHolders.splice(
      state.keyHolders.findIndex((el) => el._id == keyHolder._id),
      1,
      keyHolder
    );
  },

  setAdditionalTenant(state, keyHolder) {
    state.additionalTenants.splice(
      state.additionalTenants.findIndex((el) => el._id == keyHolder._id),
      1,
      keyHolder
    );
  },

  setRemovedTenant(state, keyHolder) {
    state.removedTenants.splice(
      state.removedTenants.findIndex((el) => el._id == keyHolder._id),
      1,
      keyHolder
    );
  },

  removeAdditionalTenant(state, keyHolder) {
    state.additionalTenants = state.additionalTenants.filter((el) => el._id != keyHolder._id);
  },

  addKeyHolder(state, keyHolder) {
    state.keyHolders.unshift(keyHolder);
    state.totalLength++;
  },

  removeKeyHolder(state, id) {
    state.keyHolders = state.keyHolders.filter((el) => el._id != id);
  },
};

const actions = {
  async getKeyHolders({ commit }, url) {
    try {
      const res = await keyHolderService.getKeyHolders(url);
      commit("addKeyHolders", {
        keyHolders: res.data.tenants,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getAdditionalTenants({ commit }, contractId) {
    try {
      const res = await keyHolderService.getAdditionalTenants(contractId);
      commit("addAdditionalTenants", res.data.tenants);
      commit("addRemovedTenants", res.data.removedTenants);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async addAdditionalTenants(ctx, data) {
    try {
      await keyHolderService.addAdditionalTenants(data);
      showPopup("Vuokralaiset tai avaimenhaltijat päivitetty vuokrasopimukselle", "success");
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async removeAdditionalTenant({ commit }, data) {
    try {
      const res = await keyHolderService.removeAdditionalTenant(data);
      commit("removeAdditionalTenant", res.data.tenant);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async addKeyHolder({ commit }, keyHolder) {
    try {
      const res = await keyHolderService.addKeyHolder(keyHolder);

      commit("addKeyHolder", res.data.tenant);
      showPopup("Avaimenhaltija luotu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async updateKeyHolder({ commit }, data) {
    try {
      const res = await keyHolderService.updateKeyHolder(data.keyHolder);

      if (data.updatingContractTenants) {
        if (data.isRemovedTenant) {
          commit("setRemovedTenant", res.data.data);
        } else {
          commit("setAdditionalTenant", res.data.data);
        }
        // Select popup text
        if (!res.data.data.isOnlyKeyHolder) {
          showPopup("Avaimenhaltija muokattu ja siirretty vuokralaisiin", "success");
        } else {
          showPopup("Avaimenhaltija muokattu", "success");
        }
      } else {
        if (!res.data.data.isOnlyKeyHolder) {
          commit("removeKeyHolder", res.data.data._id);
          showPopup("Avaimenhaltija muokattu ja siirretty vuokralaisiin", "success");
        } else {
          commit("updateKeyHolder", res.data.data);
          showPopup("Avaimenhaltija muokattu", "success");
        }
      }
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async inActivateKeyHolder({ commit }, id) {
    try {
      await keyHolderService.inActivateKeyHolder(id);
      commit("removeKeyHolder", id);
      showPopup("Avaimenhaltija poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
