const groupRoutes = [
  {
    path: "/group",
    component: () => import(/* webpackChunkName: "g-main" */ "../../views/GroupViews/Main.vue"),
    children: [
      {
        path: "overview",
        name: "Ryhmäyhteenveto",
        meta: { permission: "g-overview" },
        component: () =>
          import(
            /* webpackChunkName: "g-overview" */ "../../views/GroupViews/Overview/Overview.vue"
          ),
      },
      {
        path: "invoices",
        name: "Ryhmälaskut",
        meta: { permission: "g-invoices" },
        component: () =>
          import(
            /* webpackChunkName: "g-invoices" */ "../../views/GroupViews/Invoice/Invoices.vue"
          ),
      },
      {
        path: "refund-invoices",
        name: "Ryhmähyvityslaskut",
        meta: { permission: "g-refundInvoices" },
        component: () =>
          import(
            /* webpackChunkName: "g-refundinvoices" */ "../../views/GroupViews/Invoice/RefundInvoices.vue"
          ),
      },
      {
        path: "rent-increase",
        name: "Ryhmävuokrankorotukset",
        meta: { permission: "g-rentIncreases" },
        component: () =>
          import(
            /* webpackChunkName: "g-rentincreases" */ "../../views/RentIncrease/RentIncreases.vue"
          ),
      },
      {
        path: "condominiums",
        name: "Ryhmätaloyhtiöt",
        meta: { permission: "g-condominiums" },
        component: () =>
          import(
            /* webpackChunkName: "g-condominiums" */ "../../views/Condominium/Condominiums.vue"
          ),
      },
      {
        path: "apartments",
        name: "Ryhmävuokrakohteet",
        meta: { permission: "g-apartments" },
        component: () =>
          import(/* webpackChunkName: "g-apartments" */ "../../views/Apartment/Apartments.vue"),
      },
      {
        path: "groups",
        name: "Ryhmät",
        meta: { permission: "g-groups" },
        component: () =>
          import(/* webpackChunkName: "g-groups" */ "../../views/GroupViews/Group/Groups.vue"),
      },
      {
        path: "accounts",
        name: "Ryhmät/tilit",
        meta: { permission: "g-groups-accounts" },
        component: () =>
          import(
            /* webpackChunkName: "g-groups-account" */ "../../views/GroupViews/Group/Accounts.vue"
          ),
      },
    ],
  },
];

export default groupRoutes;
