const state = {
  pdfSource: "",
  pdfName: "",
  pdfUrl: null,
  width: 1000,
  showPdfViewer: false,
};

const mutations = {
  openViewer(state, options) {
    if (options.data) {
      state.pdfUrl = URL.createObjectURL(options.data);
      state.pdfSource = state.pdfUrl;
    } else return;
    state.pdfName = options.name;
    state.showPdfViewer = true;
  },

  setShow(state, val) {
    state.showPdfViewer = val;
  },

  setWidth(state, val) {
    state.width = val;
  },

  revokePdfUrl(state) {
    if (state.pdfUrl) URL.revokeObjectURL(state.pdfUrl);
    state.pdfUrl = null;
    state.showPdfViewer = false;
    state.pdfSource = "";
    state.pdfName = "";
  },
};

export default {
  state,
  mutations,
  namespaced: true,
};
