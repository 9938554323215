import imageService from "../../services/image.service";
import { showPopup } from "../../utils/helpers";

const state = {
  errorFiles: [],
  images: [],
  formImages: [],
  uploading: false,
  uploadProgress: 0,
  signedUrl: null,
};

const mutations = {
  setImages(state, images) {
    state.images = [...images];
  },

  setUploading(state, boolean) {
    state.uploading = boolean;
  },

  setUploadProgress(state, num) {
    state.uploadProgress = num;
  },

  setSignedUrl(state, url) {
    state.signedUrl = url;
  },

  removeImage(state, key) {
    const idx = state.images.findIndex((el) => el.key == key);
    if (idx != -1) state.images.splice(idx, 1);
  },

  removeErrorFile(state, src) {
    const idx = state.errorFiles.findIndex((el) => el.src == src);
    if (idx != -1) state.errorFiles.splice(idx, 1);
  },

  // PUBLIC

  setFormImages(state, images) {
    state.formImages = [...images];
  },

  removeFormImage(state, key) {
    const idx = state.formImages.findIndex((el) => el.key == key);
    if (idx != -1) state.formImages.splice(idx, 1);
  },

  clearFormImages(state) {
    state.formImages = [];
  },
};

const actions = {
  async sortImages(ctx, data) {
    try {
      await imageService.sortImages(data);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async deleteImage({ commit }, obj) {
    try {
      const { data } = await imageService.deleteImage(obj.url);

      commit("removeImage", obj.key);

      if (data.contract != null) {
        commit("contract/replaceContract", data.contract, { root: true });
      }

      showPopup("Kuva poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async deleteLogo({ commit }, url) {
    try {
      await imageService.deleteImage(url);

      commit("account/replaceImages", [], { root: true });

      showPopup("Logo poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getSignedUrl({ commit }, img) {
    try {
      const { data } = await imageService.getSignedUrl(img);
      commit("setSignedUrl", data.signedUrl);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  // PUBLIC

  async deleteFormImage({ commit }, data) {
    try {
      await imageService.deleteFormImage(data);
      commit("removeFormImage", data.imageKey);
      showPopup("Kuva poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

// Exports
export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
