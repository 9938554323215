import productService from "../../services/product.service";
import { showPopup } from "../../utils/helpers";

const state = {
  products: [],
  totalLength: 0,
  product: {},
};

const mutations = {
  setAllProducts(state, data) {
    state.products = data.products;
    state.totalLength = data.totalLength;
  },

  setInvoiceProducts(state, products) {
    state.products = products;
  },

  addProduct(state, data) {
    if (data.multiple) {
      state.products = [...state.products, ...data.data];
      state.totalLength += data.data.length;
    } else {
      state.products.unshift({ ...data.data });
      state.totalLength++;
    }
  },

  setProduct(state, product) {
    state.product = { ...product };
  },

  removeProduct(state, id) {
    state.products = state.products.filter((el) => el._id != id);
    state.totalLength--;
  },

  addAndReplaceProduct(state, product) {
    state.products.splice(
      state.products.findIndex((x) => x._id == product._id),
      1,
      product
    );
  },
};

const actions = {
  async createProduct({ commit }, data) {
    try {
      const multiple = Array.isArray(data);
      const obj = { data, multiple };
      const res = await productService.createProduct(obj);

      commit("addProduct", { data: res.data.data, multiple });

      if (multiple) {
        showPopup("Tuotteet luotu", "success");
      } else {
        showPopup("Tuote luotu", "success");
      }
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getAllProducts({ commit }, url) {
    try {
      const res = await productService.getAllProducts(url);
      commit("setAllProducts", { products: res.data.products, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getInvoiceProducts({ commit }) {
    try {
      const res = await productService.getInvoiceProducts();
      commit("setInvoiceProducts", res.data.products);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async updateProduct({ commit }, data) {
    try {
      const res = await productService.updateProduct(data);
      commit("addAndReplaceProduct", res.data.product);
      commit("setProduct", res.data.product);

      showPopup("Tuote muokattu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async inActivateProduct({ commit }, id) {
    try {
      await productService.inActivateProduct(id);
      commit("removeProduct", id);
      showPopup("Tuote poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
