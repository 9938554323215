import formService from "../../services/form.service";
import { showPopup } from "../../utils/helpers";
import _ from "lodash";

const state = {
  forms: [],
  attachment: {
    rentalContract: null,
    apartment: null,
    form: null,
    recipients: "",
    alwaysActive: false,
    dates: { startDate: null, endDate: null },
  },
  sessionKey: null,
  currentApartment: null,
  currentRentalContract: null,
  apartmentForms: [],
  activeForms: [],
  currentForm: null,
  selectedForm: null,
  selectedApartment: null,
  selectedRentalContract: null,
  selectedHeaders: [],
  submissionsExists: false,
  currentOptions: {
    fieldStyle: "outlined",
    required: true,
    multipleImages: false,
    colsString: "12 6 4 3 2",
  },
  form: { fields: {} },
  fieldCounts: {},
  totalLength: 0,
  loading: false,
  contractOptions: {
    sendActivationForm: false,
    formIds: [],
    recipients: "",
    sendFormTo: "",
    customMessage: "",
    reminders: {
      active: false,
      items: [],
    },
  },
};

const mutations = {
  setForms(state, data) {
    state.forms = data.forms;
    state.totalLength = data.totalLength;
  },

  resetForms(state) {
    state.currentForm = null;
    state.selectedForm = null;
    state.selectedApartment = null;
    state.selectedRentalContract = null;
    state.selectedHeaders = [];
  },

  setAttachment(state, data) {
    state.attachment = _.set(state.attachment, data.field, data.val);
  },

  setActiveForms(state, data) {
    state.activeForms = data.forms;
  },

  setSelectedForm(state, form) {
    state.selectedForm = form;
  },

  setCurrentRentalContract(state, contract) {
    state.currentRentalContract = { ...contract };
  },

  setCurrentApartment(state, apartment) {
    state.currentApartment = { ...apartment };
  },

  setSelectedApartment(state, apartment) {
    state.selectedApartment = apartment;
  },

  setSelectedRentalContract(state, contract) {
    state.selectedRentalContract = contract;
  },

  setApartmentForms(state, apartment) {
    state.apartmentForms = apartment.forms;
  },

  setSelectedHeaders(state, headers) {
    state.selectedHeaders = headers;
  },

  setFormFieldValue(state, obj) {
    state.form.fields[obj.field] = obj.value;
  },

  initProductionForm(state) {
    state.form = { fields: {} };
  },

  setEmailStr(state, string) {
    state.currentForm.emailStr = string;
  },

  setEmailVariable(state, string) {
    state.currentForm.emailStr += string;
  },

  setDefaultHeaders(state) {
    // Set to state so its not needed to fetch from server
    const index = state.activeForms.findIndex((el) => el._id == state.selectedForm._id);
    if (index != -1) {
      state.activeForms[index].defaultHeaders = state.selectedHeaders;
    }
  },

  setLoading(state, val) {
    state.loading = val;
  },

  setCurrentForm(state, form) {
    // set label values to oldLabel
    form.rows = form.rows.map((row) => {
      row.cols = row.cols.map((col) => {
        // Update old label and oldType
        col.oldLabel = col.label;
        col.oldFieldType = col.fieldType;
        return col;
      });
      return row;
    });

    state.currentForm = { ...form };
    state.loading = false;
  },

  setDefaultTemplate(state, form) {
    // set label values to oldLabel
    const rows = form.rows.map((row) => {
      row.cols = row.cols.map((col) => {
        // Update old label and oldType
        col.oldLabel = col.label;
        col.oldFieldType = col.fieldType;
        return col;
      });
      return row;
    });

    state.currentForm.rows = rows;
    state.currentForm.styles = form.styles;
    state.currentForm.rules = form.rules;
    state.currentForm.metadata = {};
    state.currentForm.oldMetadata = {};
  },

  setSubmissionsExists(state, exists) {
    state.submissionsExists = exists;
  },

  resetFormFields(state) {
    state.form = { fields: {} };
  },

  setField(state, obj) {
    state.currentForm = _.set(state.currentForm, obj.field, obj.val);
  },

  deleteDateValue(state, obj) {
    state.form.fields[obj.label] = null;
  },

  addForm(state, form) {
    state.forms.unshift({ ...form });
    state.totalLength++;
  },

  addRow(state, row) {
    state.currentForm.rows.push(row);
  },

  deleteCol(state, payload) {
    state.currentForm.rows[payload.rowIndex].cols.splice(payload.colIndex, 1);
  },

  deleteRow(state, rowIndex) {
    state.currentForm.rows.splice(rowIndex, 1);
  },

  setRows(state, rows) {
    state.currentForm.rows = rows;
  },

  hideOrShowRow(state, rowIndex) {
    state.currentForm.rows[rowIndex].showRow = !state.currentForm.rows[rowIndex].showRow;
  },

  setCols(state, payload) {
    const { rowIndex, oldIndex, newIndex } = payload;
    const row = state.currentForm.rows[rowIndex];
    const cols = [...row.cols];
    const [col] = cols.splice(oldIndex, 1);
    cols.splice(newIndex, 0, col);
    state.currentForm.rows[rowIndex].cols = cols;
  },

  addCol(state, rowIndex) {
    state.currentForm.rows[rowIndex].cols.push({
      name: "",
      fieldType: "text",
      type: "string",
      fieldStyle: state.currentOptions.fieldStyle,
      label: "",
      meta: "",
      required: state.currentOptions.required,
      multipleImages: state.currentOptions.multipleImages,
      options: "",
      padding: "",
      maxWidth: 90,
      colsString: state.currentOptions.colsString,
      text: "",
      link: "",
      align: "flex-start",
      textCenter: false,
    });
  },

  updateCol(state, obj) {
    // Set the value
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex][obj.field] = obj.val;
    // Field type
    const fieldType = state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].fieldType;
    // Update col type
    updateColOptions(state, fieldType, obj);
  },

  removeForm(state, id) {
    state.forms = state.forms.filter((el) => id != el._id);
    state.totalLength--;
  },

  removeApartmentForm(state, id) {
    state.attachment.apartment.forms = state.attachment.apartment.forms.filter(
      (el) => id != el._id
    );
  },

  removeRentalContractForm(state, id) {
    state.attachment.rentalContract.forms = state.attachment.rentalContract.forms.filter(
      (el) => id != el._id
    );
  },

  // RULES
  addRule(state) {
    const rule = {
      ruleField: "",
      targetFields: [],
      rule: "show",
      ruleFieldBoolean: true,
      ruleFieldValues: [],
    };

    if (state.currentForm.rules) state.currentForm.rules.push(rule);
    else {
      state.currentForm.rules = [];
      state.currentForm.rules.push(rule);
    }
  },

  removeRule(state, index) {
    state.currentForm.rules.splice(index, 1);
  },

  filterTargetFields(state, ruleRow) {
    const currentRule = state.currentForm.rules[ruleRow];
    state.currentForm.rules[ruleRow].targetFields = state.currentForm.rules[
      ruleRow
    ].targetFields.filter((el) => el !== currentRule.ruleField);
  },

  emptyRuleFieldValues(state, ruleRow) {
    state.currentForm.rules[ruleRow]["ruleFieldValues"] = [];
  },

  removeRuleOptions(state, obj) {
    const col = state.currentForm.rows[obj.rowIndex].cols[obj.colIndex];
    const label = col.label;
    const options = col.options.split(", ");
    let optionsChanged = false;

    const filteredRules = state.currentForm.rules.filter((rule) => rule.ruleField === label);

    filteredRules.forEach((rule) => {
      let availableOptions = [];

      rule.ruleFieldValues.forEach((el) => {
        if (options.includes(el)) {
          availableOptions.push(el);
        } else {
          optionsChanged = true;
        }
      });
      rule.ruleFieldValues = availableOptions;
    });

    if (optionsChanged) showPopup("Ehtojen valintoja muutettu", "info");
  },

  removeUnnecessaryRules(state, obj) {
    const label = state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].label;

    if (label) {
      const rulesLength = state.currentForm.rules.length;
      // If label matches ruleField, delete rule
      const filteredRules = state.currentForm.rules.filter((rule) => rule.ruleField !== label);

      // If targetFields includes label, delete targetfield
      filteredRules.forEach((rule) => {
        rule.targetFields = rule.targetFields.filter((field) => field !== label);
      });

      // Set new rules
      state.currentForm.rules = filteredRules;

      // Show popup if rules are deleted
      if (filteredRules.length !== rulesLength) showPopup("Ehtoja poistettu", "info");
    }
  },

  renameRules(state, obj) {
    state.currentForm.rules = state.currentForm.rules.map((rule) => {
      if (rule.ruleField === obj.previousLabel) {
        rule.ruleField = obj.newLabel;
      }

      rule.targetFields = rule.targetFields.map((field) => {
        if (field === obj.previousLabel) {
          return obj.newLabel;
        } else {
          return field;
        }
      });

      return rule;
    });
  },
};

const actions = {
  async getForms({ commit }, data) {
    try {
      const res = await formService.getForms(data);
      commit("setForms", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getActiveForms({ commit }) {
    try {
      const res = await formService.getActiveForms();
      commit("setActiveForms", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getOne({ commit }, id) {
    try {
      const res = await formService.getOne(id);
      commit("setCurrentForm", res.data.form);
      commit("setSubmissionsExists", res.data.submissionsExists);
    } catch (err) {
      showPopup("Jokin meni pieleen lomakkeen lataamisessa.", "error");
    }
  },

  async createForm({ state }) {
    try {
      await formService.createForm(state.currentForm);
      showPopup("Lomake luotu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async editForm({ state }, id) {
    try {
      await formService.editForm({ form: state.currentForm, id });
      showPopup("Lomake tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async attachFormToApartment({ state, commit }) {
    try {
      const res = await formService.attachFormToApartment({
        form: state.attachment.form._id,
        apartmentId: state.attachment.apartment._id,
        recipients: state.attachment.recipients,
        alwaysActive: state.attachment.alwaysActive,
        dates: state.attachment.dates,
      });
      commit("apartment/replaceSelectedApartments", [res.data.apartment], { root: true });
      commit("setAttachment", { field: "apartment", val: res.data.apartment });
      showPopup("Lomake tallennettu vuokrakohteelle", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async attachFormToRentalContract({ state, commit }) {
    try {
      const res = await formService.attachFormToRentalContract({
        form: state.attachment.form._id,
        contractId: state.attachment.rentalContract._id,
        recipients: state.attachment.recipients,
        alwaysActive: state.attachment.alwaysActive,
        dates: state.attachment.dates,
      });
      commit("contract/replaceContract", res.data.contract, { root: true });
      commit("setAttachment", { field: "rentalContract", val: res.data.contract });
      showPopup("Lomake tallennettu sopimukselle", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async editApartmentForm({ commit }, data) {
    try {
      const res = await formService.editApartmentForm(data);
      commit("apartment/replaceSelectedApartments", [res.data.apartment], { root: true });
      commit("setAttachment", { field: "apartment", val: res.data.apartment });
      showPopup("Lomake tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async editRentalContractForm({ commit }, data) {
    try {
      const res = await formService.editRentalContractForm(data);
      commit("contract/replaceContract", res.data.contract, { root: true });
      commit("setAttachment", { field: "rentalContract", val: res.data.contract });
      showPopup("Lomake tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async saveDefaultHeaders({ state, commit }) {
    try {
      await formService.saveDefaultHeaders({
        formId: state.selectedForm._id,
        headers: state.selectedHeaders,
      });

      commit("setDefaultHeaders");
      showPopup("Oletuskentät tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateFormActiveState(ctx, data) {
    try {
      await formService.updateFormActiveState(data);
      showPopup("Lomakkeen tila päivitetty", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async deleteForm({ commit }, id) {
    try {
      await formService.deleteForm(id);
      commit("removeForm", id);
      showPopup("Lomake poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async removeApartmentForm({ commit }, data) {
    try {
      const res = await formService.removeApartmentForm(data);
      commit("removeApartmentForm", data.formId);
      commit("apartment/replaceSelectedApartments", [res.data.apartment], { root: true });
      showPopup("Lomake poistettu vuokrakohteelta", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async removeRentalContractForm({ commit }, data) {
    try {
      const res = await formService.removeRentalContractForm(data);
      commit("removeRentalContractForm", data.formId);
      commit("contract/replaceContract", res.data.contract, { root: true });
      showPopup("Lomake poistettu sopimukselta", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  // PUBLIC //

  async getOnePublic({ commit }, id) {
    try {
      const res = await formService.getOnePublic(id);
      commit("setCurrentForm", res.data.form);
    } catch (err) {
      showPopup("Jokin meni pieleen lomakkeen lataamisessa.", "error");
    }
  },

  async getApartmentForm({ commit }, data) {
    try {
      const res = await formService.getApartmentForm(data);
      commit("setCurrentApartment", res.data.apartment);
      commit("setCurrentForm", res.data.form);
    } catch (err) {
      showPopup("Jokin meni pieleen lomakkeen lataamisessa.", "error");
    }
  },

  async getRentalContractForm({ commit }, data) {
    try {
      const res = await formService.getRentalContractForm(data);
      commit("setCurrentRentalContract", res.data.contract);
      commit("setCurrentForm", res.data.form);
    } catch (err) {
      showPopup("Jokin meni pieleen lomakkeen lataamisessa.", "error");
    }
  },

  async submitForm({ state }, obj) {
    try {
      const data = {
        ...state.form,
        formId: state.currentForm._id,
        attachment: obj,
        accId: state.currentForm.accId,
        sessionKey: state.sessionKey,
      };

      await formService.submitForm(data);
    } catch (err) {
      throw new Error(err);
    }
  },
};

// HELPERS

function updateColOptions(state, fieldType, obj) {
  if (fieldType == "checkbox") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "boolean";
  } else if (fieldType == "multiselect") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "array";
  } else if (fieldType == "date") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "date";
  } else if (fieldType == "submit" || fieldType == "button") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "button";
  } else if (fieldType == "image" || fieldType == "logo") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "image";
  } else if (fieldType == "number") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "number";
  } else {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type = "string";
  }

  if (
    fieldType == "h1" ||
    fieldType == "h2" ||
    fieldType == "h3" ||
    fieldType == "p" ||
    fieldType == "submit" ||
    fieldType == "button" ||
    fieldType == "logo"
  ) {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].label = "";
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].oldLabel = "";
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].required = false;
  }

  // update unnessessary options
  if (fieldType != "select" && fieldType != "multiselect" && fieldType != "radio") {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].options = "";
  }

  // update unnessessary texts
  if (
    fieldType != "h1" &&
    fieldType != "h2" &&
    fieldType != "h3" &&
    fieldType != "p" &&
    fieldType != "button" &&
    fieldType != "submit"
  ) {
    state.currentForm.rows[obj.rowIndex].cols[obj.colIndex].text = "";
  }

  // update current options
  if (obj.field == "fieldStyle") state.currentOptions.fieldStyle = obj.val;
  if (obj.field == "required") state.currentOptions.required = obj.val;
  if (obj.field == "colsString") state.currentOptions.colsString = obj.val;
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
