import { showPopup } from "../../utils/helpers";
import otherContractService from "../../services/otherContract.service";

const state = {
  contracts: [],
  totalLength: 0,
  contract: {
    name: "",
    tenant: null,
    otherTenants: [],
    landlords: [],
    apartment: null,
    keys: [],
    modelId: "",
    content: "",
  },
  contractModel: { content: "" },
  contractContent: "",
  loading: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  setContract(state, contract) {
    state.contract = { ...contract };
  },

  addContracts(state, data) {
    state.contracts = [...data.contracts];
    state.totalLength = data.totalLength;
  },

  removeContract(state, id) {
    state.contracts.splice(
      state.contracts.findIndex((x) => String(x._id) == String(id)),
      1
    );
  },

  replaceOtherContract(state, contract) {
    state.contracts.splice(
      state.contracts.findIndex((x) => String(x._id) == String(contract._id)),
      1,
      contract
    );
  },

  removeReferenceContract(state, id) {
    state.contracts = state.contracts.filter((el) => el._id != id);
  },
};

const actions = {
  async createOtherContract(ctx, data) {
    try {
      await otherContractService.createOtherContract(data);
      showPopup("Sopimuksen luominen onnistui", "success");
    } catch (err) {
      showPopup("Sopimuksen luominen ei onnistunut", "error");
    }
  },

  async updateOtherContract(ctx, { contract, id }) {
    try {
      await otherContractService.updateOtherContract(contract, id);
      showPopup("Sopimuksen päivittäminen onnistui", "success");
    } catch (err) {
      showPopup("Sopimuksen päivittäminen ei onnistunut", "error");
    }
  },

  async deleteContract({ commit }, id) {
    try {
      await otherContractService.deleteContract(id);
      commit("removeContract", id);
      showPopup("Sopimus poistettu", "success");
    } catch (err) {
      showPopup("Sopimuksen poistaminen ei onnistunut", "error");
    }
  },

  async getContracts({ commit }, url) {
    try {
      const res = await otherContractService.getContracts(url);
      commit("addContracts", { contracts: res.data.contracts, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getContractsById({ commit }, ids) {
    return otherContractService
      .getContractsById(ids)
      .then((res) => {
        commit("addContracts", {
          contracts: res.data.contracts,
          totalLength: res.data.contracts.length,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async getContract({ commit }, id) {
    try {
      const res = await otherContractService.getContract(id);
      commit("setContract", res.data.contract);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

const getters = {
  getContract(state) {
    return state.contract;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
