import axiosMethods from "../mixins/axios";

export default {
  async getNotes(data) {
    return await axiosMethods.get(
      `/api/v1/note/notes?model=${data.model}&documentId=${data.documentId}`
    );
  },

  async createNote(data) {
    return await axiosMethods.post(
      `/api/v1/note?model=${data.model}&documentId=${data.documentId}`,
      data.note
    );
  },

  async editNote(data) {
    return await axiosMethods.update(
      `/api/v1/note?model=${data.model}&documentId=${data.documentId}`,
      data.note
    );
  },

  async deleteNote(data) {
    return await axiosMethods.delete(
      `/api/v1/note?noteId=${data.noteId}&model=${data.model}&documentId=${data.documentId}`
    );
  },
};
