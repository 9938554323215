import reservationService from "../../services/reservation.service";
import { showPopup } from "../../utils/helpers";

const state = {
  reservations: [],
  totalLength: 0,
};

const mutations = {
  addAllReservations(state, data) {
    state.reservations = data.reservations;
    state.totalLength = data.totalLength;
  },

  removeReservation(state, id) {
    state.reservations = state.reservations.filter((el) => el._id != id);
  },

  setItemRead(state, id) {
    const index = state.reservations.findIndex((el) => String(el._id) == String(id));

    if (index != -1) {
      state.reservations[index].read = true;
    }
  },
};

const actions = {
  async getAllReservations({ commit }, url) {
    try {
      const res = await reservationService.getAllReservations(url);
      commit("addAllReservations", {
        reservations: res.data.reservations,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async markItemRead({ commit }, id) {
    try {
      await reservationService.markItemRead(id);
      commit("setItemRead", id);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  // async deleteReservation({ commit }, id) {
  //   try {
  //     await reservationService.deleteReservation(id);
  //     commit("removeReservation", id);
  //     showPopup("Varaus poistettu", "success");
  //   } catch (err) {
  //     showPopup(err, "error");
  //   }
  // },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
