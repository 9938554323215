import axiosMethods from "../mixins/axios";

export default {
  async getDeposits(data) {
    return await axiosMethods.post(data.url, data.query);
  },

  async getDeposit(id) {
    return await axiosMethods.get("/api/v1/deposits/getOne/" + id);
  },

  async getTenantDeposits(tenantId) {
    return await axiosMethods.get("/api/v1/deposits/tenant/" + tenantId);
  },

  async getAttachedDeposits(serviceId) {
    return await axiosMethods.get("/api/v1/deposits/get-by-service-id/" + serviceId);
  },

  async createDeposit(deposit) {
    return await axiosMethods.post("/api/v1/deposits/create/", deposit);
  },

  async editDeposit(deposit) {
    return await axiosMethods.update(`/api/v1/deposits/update/${deposit._id}`, deposit);
  },

  async releaseDeposit(deposit) {
    return await axiosMethods.update(`/api/v1/deposits/release/${deposit._id}`, deposit);
  },

  async deleteDeposit(id) {
    return await axiosMethods.delete(`/api/v1/deposits/delete/${id}`);
  },

  async resendDepositMessage(id) {
    return await axiosMethods.post(`/api/v1/deposits/resend-message/${id}`);
  },
};
