import axiosMethods from "../mixins/axios";

export default {
  async getAllTenants(url) {
    return await axiosMethods.get(url);
  },

  async createTenant(data) {
    return await axiosMethods.post("/api/v1/tenant/create", data);
  },

  async updateTenant(obj) {
    return await axiosMethods.update(
      `/api/v1/tenant/update/${obj.tenantId}?updateDataToService=${obj.updateDataToService}`,
      obj.data
    );
  },

  async getOneTenant(url) {
    return await axiosMethods.get(url);
  },

  async searchTenant(url) {
    return await axiosMethods.get(url);
  },

  async getTenantsById(ids) {
    return await axiosMethods.post("/api/v1/tenant/get-tenants-by-id", ids);
  },

  async inActivateTenant(id) {
    return await axiosMethods.update("/api/v1/tenant/inactivate/" + id);
  },

  async addTenantKeys(data) {
    return await axiosMethods.update(data.url, data.keys);
  },

  async removeKeyFromTenant(data) {
    return await axiosMethods.update("/api/v1/key/removeTenantKey/" + data.key._id, {
      status: data.status,
      tenantId: data.tenantId,
      deletePermissions: data.deletePermissions,
    });
  },
};
