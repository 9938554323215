import receiptService from "../../services/receipt.service";
import { showPopup } from "../../utils/helpers";
import _ from "lodash";

const state = {
  receipts: [],
  currentReceipt: {
    source: "",
    receiptType: "receipt",
    taxDeductible: true,
    name: "",
    description: "",
    products: [],
    amount: null,
    date: "",
    reference: "",
    apartment: null,
    recurrency: {
      isRecurrent: false,
      repeatsLeft: null,
    },
    outgoingPayments: {
      inUse: false,
      subtractDate: "",
      subtracted: false,
      paymentId: null,
      receiptPaymentId: null,
    },
  },
  currentProduct: {
    name: "",
    count: 1,
    taxpr: 25.5,
    amount: null,
    handling: null,
    productId: null,
  },
  productIndex: null,
  totalLength: 0,
  loading: false,
  sums: { total: 0, vat255: 0, vat24: 0, vat14: 0, vat10: 0 },
  showSums: true,
  receiptType: "",
  search: "",
  startDate: "",
  endDate: "",
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  setShowSums(state, val) {
    state.showSums = val;
  },

  setFormValue(state, obj) {
    state[obj.field] = obj.val;
  },

  addReceipts(state, data) {
    state.receipts = data.receipts;
    state.totalLength = data.totalLength;
    state.sums = data.sums;
  },

  addReceiptToState(state, receipt) {
    state.receipts.unshift(receipt);
    state.totalLength++;
  },

  addCurrentReceiptValue(state, obj) {
    state.currentReceipt = _.set(state.currentReceipt, obj.field, obj.val);
  },

  addCurrentProductValue(state, obj) {
    state.currentProduct = _.set(state.currentProduct, obj.field, obj.val);
  },

  setCurrentReceipt(state, receipt) {
    state.currentReceipt = _.cloneDeep(receipt);
  },

  setCurrentProduct(state, prod) {
    state.currentProduct = { ...prod };
  },

  setCurrentProductIndex(state, idx) {
    state.productIndex = idx;
  },

  setProduct(state) {
    state.currentReceipt.products.splice(state.productIndex, 1, { ...state.currentProduct });
  },

  deleteReceiptFromState(state, id) {
    state.receipts = state.receipts.filter((el) => el._id != id);
    state.totalLength--;
  },

  replaceReceipt(state, receipt) {
    state.receipts.splice(
      state.receipts.findIndex((el) => el._id == receipt._id),
      1,
      receipt
    );
  },

  replaceFiles(state, receipt) {
    const index = state.receipts.findIndex((el) => el._id === receipt._id);
    if (index !== -1) {
      state.receipts[index].files = receipt.files;
    }
  },
};

const actions = {
  async getReceipts({ commit }, data) {
    try {
      const res = await receiptService.getReceipts(data);

      commit("addReceipts", {
        receipts: res.data.receipts,
        totalLength: res.data.totalLength,
        sums: res.data.sums,
      });
      commit("setLoading", false);
      commit("setShowSums", true);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  createReceipt({ commit, state }) {
    return receiptService
      .createReceipt(state.currentReceipt)
      .then((res) => {
        commit("addReceiptToState", res.data.receipt);
        commit("setShowSums", false);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  updateReceipt({ commit, state }) {
    return receiptService
      .updateReceipt(state.currentReceipt)
      .then((res) => {
        commit("replaceReceipt", res.data.receipt);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  deleteReceipt({ commit }, id) {
    return receiptService
      .deleteReceipt(id)
      .then(() => {
        commit("deleteReceiptFromState", id);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

const getters = {
  isUpdated(state) {
    let isDisabled = false;

    // Check if payments are created or not
    if (state.currentReceipt.outgoingPayments.paymentId) {
      if (state.currentReceipt.outgoingPayments.paymentId.status != "created") isDisabled = true;
    }

    if (state.currentReceipt.outgoingPayments.receiptPaymentId) {
      if (state.currentReceipt.outgoingPayments.receiptPaymentId.status != "created")
        isDisabled = true;
    }

    return isDisabled;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
