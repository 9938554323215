<template>
  <div>
    <v-snackbar v-model="snackbar" :top="position" text right timeout="-1" color="black">
      <v-row dense justify="space-between">
        <div>
          <v-icon small v-if="position" @click="position = !position">mdi-chevron-down</v-icon>
          <v-icon small v-if="!position" @click="position = !position">mdi-chevron-up</v-icon>
        </div>

        <p style="cursor: pointer; margin-bottom: 0" @click="hidden = !hidden">
          {{ hidden ? "Näytä" : "Piilota" }}
        </p>
      </v-row>
      <editor-menu-bar v-if="!hidden" :editor="editor" v-slot="{ commands, isActive }">
        <div>
          <v-row align="center" dense no-gutters>
            <v-col cols="8">
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
              >
                Bold
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                Italic
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.paragraph() }"
                @click="commands.paragraph()"
              >
                Paragraph
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1 })"
              >
                H1
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
              >
                H2
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
              >
                H3
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.alignment({ align: 'left' }) }"
                @click="commands.alignment({ align: 'left' })"
              >
                Left
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.alignment({ align: 'center' }) }"
                @click="commands.alignment({ align: 'center' })"
              >
                Center
              </v-btn>
              <v-btn
                class="mr-1 mt-1"
                small
                :class="{ 'is-active': isActive.alignment({ align: 'right' }) }"
                @click="commands.alignment({ align: 'right' })"
              >
                Right
              </v-btn>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                v-model="variableItem"
                class="mb-1"
                :class="{ 'is-active': isActive.variable() }"
                @change="(value) => commands.variable({ value: value })"
                @input="clearVariableSelect"
                :items="tiptapVariables"
                label="Muuttujat"
                hide-details
                dense
                solo
              >
              </v-autocomplete>
              <v-autocomplete
                v-model="functionItem"
                :class="{ 'is-active': isActive.variable() }"
                @change="(value) => commands.variable({ value: value })"
                :items="tiptapFunctions"
                @input="clearFunctionSelect"
                label="Toiminnot"
                hide-details
                dense
                solo
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row
            class="mt-1"
            v-if="
              $route.name != 'Muokkaa vuokrasopimuspohjaa' &&
              $route.name != 'Luo vuokrasopimuspohja'
            "
            dense
            no-gutters
          >
            <v-col>
              <div class="display-1 d-flex">
                <v-btn small color="error" @click="cancelContentHandler"> Peruuta </v-btn>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="saveContentHandler"> Ok </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </editor-menu-bar>
    </v-snackbar>

    <!-- CONTENT -->
    <editor-content :editor="editor" class="white pa-1" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import { Heading, Bold, Italic } from "tiptap-extensions";
import Variable from "@/plugins/tiptap/Variable";
import Alignment from "@/plugins/tiptap/Alignment";
import config from "@/configs";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    modelType: {
      type: String,
      default: "rentalContract",
    },
  },

  data() {
    return {
      hidden: false,
      position: false,
      editor: null,
      variables: config.contract.variables,
      variableItem: null,
      functionItem: null,
      snackbar: true,
    };
  },

  watch: {
    value() {
      this.editor.setContent(this.contentToEditor, true);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        new Heading({ levels: [1, 2, 3] }),
        new Bold(),
        new Italic(),
        new Alignment(),
        new Variable({
          variables: this.variables,
        }),
      ],
      content: this.contentToEditor,
      onUpdate: () => {
        this.$emit("input", this.contentFromEditor);
      },
    });
  },

  computed: {
    contentToEditor() {
      // variables
      let val = this.value.replace(
        /\{\{(.+?)\}\}/g,
        '<span class="variable" data-variable="$1">$1</span>'
      );
      // return
      return val;
    },

    contentFromEditor() {
      // variables
      let val = this.editor
        .getHTML()
        .replace(
          /<span data-variable="(.+?)" data-type="(.+?)" class="variable">[^>]*>/g,
          "{{$1}}"
        );
      // return
      return val;
    },

    tiptapVariables() {
      let vars = [];
      config.contract.variables.forEach((ttVar) => {
        if (
          ttVar.type == "variable" &&
          this.modelType == "rentalContract" &&
          ttVar.show.includes(1)
        ) {
          vars.push(ttVar);
        }

        if (
          ttVar.type == "variable" &&
          this.modelType == "otherContract" &&
          ttVar.show.includes(2)
        ) {
          vars.push(ttVar);
        }

        if (
          ttVar.type == "variable" &&
          this.modelType == "rentIncrease" &&
          ttVar.show.includes(3)
        ) {
          vars.push(ttVar);
        }
      });
      return vars;
    },

    tiptapFunctions() {
      let funcs = [];
      config.contract.variables.forEach((ttFunc) => {
        if (
          ttFunc.type == "function" &&
          this.modelType == "rentalContract" &&
          ttFunc.show.includes(1)
        ) {
          funcs.push(ttFunc);
        }
        if (
          ttFunc.type == "function" &&
          this.modelType == "otherContract" &&
          ttFunc.show.includes(2)
        ) {
          funcs.push(ttFunc);
        }
        if (
          ttFunc.type == "function" &&
          this.modelType == "rentIncrease" &&
          ttFunc.show.includes(3)
        ) {
          funcs.push(ttFunc);
        }
      });
      return funcs;
    },
  },

  methods: {
    clearVariableSelect() {
      this.$nextTick(() => {
        this.variableItem = null;
      });
    },

    clearFunctionSelect() {
      this.$nextTick(() => {
        this.functionItem = null;
      });
    },

    cancelContentHandler() {
      this.$emit("cancelcontenthandler");
    },

    saveContentHandler() {
      this.$emit("savecontent");
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style>
.ProseMirror:focus {
  outline: none;
}

h1 {
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: 600;
  margin-bottom: 15px;
}

h2 {
  line-height: 1.5em;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.4em;
}

p {
  line-height: 1.35em;
}

.text-center {
  text-align: center;
}

p:empty {
  height: 0.5em;
}

.variable {
  background-color: #cccccc;
  border-radius: 0.2rem;
  padding: 2px 1px 2px 1px;
  font-weight: bold;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 14px;
}

.variable[data-type="function"] {
  background-color: #00cccc;
}

.variable[data-variable^="#if"] {
  background-color: #68b5f5;
}

.variable[data-variable^="/if"] {
  background-color: #68b5f5;
}

.variable[data-variable="else"] {
  background-color: #ecb639;
}

.variable[data-variable="pageBreak"] {
  background-color: #ef476f;
}

.v-btn.is-active {
  background-color: #e6e6e6 !important;
}

.v-autocomplete__content {
  z-index: 9999 !important;
}
</style>
