import rentIncreaseService from "../../services/rentIncrease.service";
import moment from "moment";
import { showPopup } from "../../utils/helpers";
import helpers from "../../utils/rentIncreaseHelpers";
import _ from "lodash";

const state = {
  rentalContracts: [],
  rentIncreases: [],
  totalLength: 0,
  loading: false,
  currentContract: {
    lease: { startDate: new Date(), endDate: new Date(), rentIncreaseType: "" },
    baseInvoice: { products: [], updatedProducts: [] },
  },
  currentRentIncrease: { rentalContract: {}, baseInvoice: {} },
  indexDate: "",
  indexValue: null,
  loadingIndex: false,
  productsBtnAnimation: true,
  replyToSelector: "account",
  newRentIncrease: {
    dueDate: "",
    sendType: "",
    paymentTerm: null,
    newIndexDate: "",
    newIndexValue: null,
    percentage: null,
    fixedAmount: null,
    startRentPrice: null,
    products: [],
    modelId: "",
    content: "",
    dontSendMessage: false,
    replyToEmail: null,
  },
  productsAreValidated: false,
};

const mutations = {
  setLoadingIndex(state, val) {
    state.loadingIndex = val;
  },

  setNewRentIncreaseValue(state, obj) {
    state.newRentIncrease[obj.field] = obj.val;
  },

  setProductsAreValidated(state, val) {
    state.productsAreValidated = val;
  },

  setReplyToSelector(state, val) {
    state.replyToSelector = val;
  },

  removeRentIncrease(state, id) {
    state.rentIncreases = state.rentIncreases.filter((el) => id != el._id);
  },

  removeRentalContract(state, id) {
    state.rentalContracts = state.rentalContracts.filter((el) => id != el._id);
  },

  setIndexValue(state, indexVal) {
    state.indexValue = indexVal;
  },

  setIndexDate(state, date) {
    state.indexDate = date;
  },

  setCurrentContract(state, item) {
    state.currentContract = { ...item };
  },

  setCurrentRentIncrease(state, item) {
    state.currentRentIncrease = { ...item };
  },

  setButtonAnimation(state, val) {
    state.productsBtnAnimation = val;
  },

  addRentalContracts(state, data) {
    state.rentalContracts = data.rentalContracts;
    state.totalLength = data.totalLength;
  },

  addRentIncreases(state, data) {
    state.rentIncreases = data.rentIncreases;
    state.totalLength = data.totalLength;
  },

  addRentIncreaseProducts(state, products) {
    state.newRentIncrease.products = [...products];
  },

  addProduct(state) {
    state.newRentIncrease.products.push({
      desc: "",
      count: null,
      itemtype: "kpl",
      amount: null,
      taxpr: 0,
      accountingId: null,
      productId: null,
    });
  },

  setProduct(state, obj) {
    obj.product.productNumber = obj.event.productNumber;
    obj.product.desc = obj.event.desc;
    obj.product.amount = obj.event.amount;
    obj.product.count = obj.event.count;
    obj.product.taxpr = obj.event.taxpr;
    obj.product.itemtype = obj.event.itemtype;
    obj.product.productType = obj.event.productType;
    obj.product.accountingId = obj.event.accountingId;
    obj.product.productId = obj.event._id;
    delete obj.product._id;

    state.newRentIncrease.products.splice(obj.index, 1, obj.product);
  },

  deleteProduct(state, index) {
    state.newRentIncrease.products.splice(index, 1);
  },
};

const actions = {
  async getRentalContracts({ commit }, data) {
    try {
      const res = await rentIncreaseService.getRentalContracts(data);
      commit("addRentalContracts", {
        rentalContracts: res.data.rentalContracts,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getRentIncreases({ commit }, url) {
    try {
      const res = await rentIncreaseService.getRentIncreases(url);
      commit("addRentIncreases", {
        rentIncreases: res.data.rentIncreases,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  createNewRentIncrease({ state, commit }) {
    let rentIncrease = state.newRentIncrease;
    rentIncrease.products = helpers.getNewProductsWithoutId(state.newRentIncrease.products);

    return rentIncreaseService
      .createNewRentIncrease(rentIncrease, state.currentContract)
      .then(() => {
        commit("removeRentalContract", state.currentContract._id);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async deleteInActiveRentIncrease({ commit }, id) {
    try {
      await rentIncreaseService.deleteInActiveRentIncrease(id);
      commit("removeRentIncrease", id);
      showPopup("Vuokrankorotus poistettu", "success");
    } catch (err) {
      showPopup(err, " error");
    }
  },

  deleteActiveRentIncrease({ commit }, data) {
    return rentIncreaseService
      .deleteActiveRentIncrease(data)
      .then(() => {
        commit("removeRentIncrease", data.id);
        showPopup("Vuokrankorotus poistettu", "success");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async getIndexValue({ commit }, data) {
    try {
      const res = await rentIncreaseService.getIndexValue(data);
      commit("setIndexValue", res.data.value[0]);
      commit("setIndexDate", data.date.substring(0, 7));
      commit("setLoadingIndex", false);
    } catch (err) {
      if (err.response.status == 400) {
        showPopup(
          "Kohdekuukauden pistelukua ei löydy. Kokeile aikaisempaa ajankohtaa tai täytä kenttä manuaalisesti.",
          "error"
        );
        commit("setLoadingIndex", false);
        commit("setIndexValue", null);
      } else {
        showPopup(err, "error");
        commit("setLoadingIndex", false);
        commit("setIndexValue", null);
      }
    }
  },

  async getLatestIndexValue({ commit }, type) {
    let error = false;
    let reTry = 0;
    let monthsToSubtract;
    if (type === "propertyMaintenanceIndex" || type === "propertyMaintenancePlusPercentageIndex")
      monthsToSubtract = 3;
    else monthsToSubtract = 1;

    let date = moment().subtract(monthsToSubtract, "months").format("YYYY-MM-DD");
    commit("setIndexDate", "");
    commit("setIndexValue", null);

    do {
      try {
        const res = await rentIncreaseService.getIndexValue({ date, type });
        commit("setIndexDate", date.substring(0, 7));
        commit("setIndexValue", res.data.value[0]);
        commit("setLoadingIndex", false);
        reTry++;
        error = false;
      } catch (err) {
        reTry++;
        error = true;
        date = moment(date).subtract(monthsToSubtract, "months").format("YYYY-MM-DD");
      }
    } while (error && reTry < 3);
  },

  setRentIncreaseProducts({ state, commit, getters }) {
    const products = _.cloneDeep(getters.currentProducts);
    let newProducts = [];

    products.forEach((el) => {
      if (el.productId && el.productId.productType === "Vuokra") {
        el.amount = helpers.countNewSum(
          el,
          state.currentContract,
          state.newRentIncrease,
          getters.factors
        );
        if (state.currentContract.lease.rentIncreaseType == "fixed") {
          el.increaseMade = true;
        }
        newProducts.push(el);
      } else {
        newProducts.push(el);
      }
    });

    commit("addRentIncreaseProducts", newProducts);
  },

  increaseCurrentProducts({ state, commit, getters }) {
    const products = _.cloneDeep(state.newRentIncrease.products);
    let newProducts = [];

    products.forEach((el) => {
      if (
        el.productId &&
        typeof el.productId === "object" &&
        el.productId.productType === "Vuokra"
      ) {
        el.amount = helpers.countNewSum(
          el,
          state.currentContract,
          state.newRentIncrease,
          getters.factors
        );
        if (state.currentContract.lease.rentIncreaseType == "fixed") {
          el.increaseMade = true;
        }
        newProducts.push(el);
      } else if (typeof el.productId === "string" && el.productType === "Vuokra") {
        el.amount = helpers.countNewSum(
          el,
          state.currentContract,
          state.newRentIncrease,
          getters.factors
        );
        if (state.currentContract.lease.rentIncreaseType == "fixed") {
          el.increaseMade = true;
        }
        newProducts.push(el);
      } else {
        newProducts.push(el);
      }
    });

    commit("addRentIncreaseProducts", newProducts);
  },
};

const getters = {
  currentProducts(state) {
    return state.currentContract.baseInvoice.isUpdated
      ? state.currentContract.baseInvoice.updatedProducts
      : state.currentContract.baseInvoice.products;
  },

  factors() {
    // Index
    const newIndexVal = !state.newRentIncrease.newIndexValue
      ? 1
      : state.newRentIncrease.newIndexValue;
    const startIndexVal = !state.currentContract.lease.indexStartValue
      ? 1
      : state.currentContract.lease.indexStartValue;

    const indexFactor = newIndexVal / startIndexVal;

    // Percentage
    let percentage = state.newRentIncrease.percentage;
    percentage = percentage === 0 || !percentage ? 0 : percentage;
    const percentageFactor = 1 + percentage / 100;

    //Index plus percentage
    const indexPlusPercentageFactor = (1 + percentage / 100) * indexFactor;

    return { indexFactor, percentageFactor, indexPlusPercentageFactor };
  },

  showLoader(state) {
    let value = false;

    if (
      state.currentContract.baseInvoice.measurementsNeeded ||
      !state.currentContract.baseInvoice.active ||
      state.currentContract.baseInvoice.overwritten
    ) {
      return false;
    }

    if (state.currentContract.lease.rentIncreaseType == "percentage") {
      value = state.newRentIncrease.percentage >= 0 ? true : false;
    }

    if (
      state.currentContract.lease.rentIncreaseType == "index" ||
      state.currentContract.lease.rentIncreaseType == "consumerIndex" ||
      state.currentContract.lease.rentIncreaseType == "propertyMaintenanceIndex"
    ) {
      value =
        state.newRentIncrease.newIndexDate &&
        state.newRentIncrease.newIndexValue &&
        state.newRentIncrease.startRentPrice
          ? true
          : false;
    }

    if (
      state.currentContract.lease.rentIncreaseType == "indexPlusPercentage" ||
      state.currentContract.lease.rentIncreaseType == "consumerIndexPlusPercentage" ||
      state.currentContract.lease.rentIncreaseType == "propertyMaintenancePlusPercentageIndex"
    ) {
      value =
        state.newRentIncrease.newIndexDate &&
        state.newRentIncrease.newIndexValue &&
        state.newRentIncrease.percentage >= 0 &&
        state.newRentIncrease.startRentPrice
          ? true
          : false;
    }

    if (state.currentContract.lease.rentIncreaseType == "fixed") {
      value = state.newRentIncrease.fixedAmount ? true : false;
    }

    if (state.currentContract.lease.rentIncreaseType == "own") {
      value = true;
    }

    return value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
