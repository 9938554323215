import axiosMethods from "../mixins/axios";

export default {
  async getMonthlyInvoicing() {
    return await axiosMethods.get("/api/v1/statistics/getMonthlyInvoicing");
  },
  async getMonthlyExpenses() {
    return await axiosMethods.get("/api/v1/statistics/getMonthlyExpenses");
  },
  async getStats() {
    return await axiosMethods.get("/api/v1/statistics/get-stats");
  },
};
