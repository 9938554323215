<template>
  <v-footer rounded style="min-height: 25px; padding: 0 !important" class="footer-container">
    <v-card color="footer" class="flex-grow-1 d-flex pa-2 ma-0" flat>
      <v-layout wrap class="text-center column-container">
        <v-flex xs12 sm4>
          <div v-if="showLogo(getFooterData.texts.column1)" class="text-center">
            <v-img
              :style="{ width: '100%', 'max-height': '60px' }"
              contain
              :src="customLogo"
            ></v-img>
          </div>

          <div v-else class="footer-text--text" v-html="getFooterData.texts.column1"></div>
        </v-flex>

        <v-flex xs12 sm4 class="pr-sm-1 pl-sm-1">
          <div v-if="showLogo(getFooterData.texts.column2)" class="text-center">
            <v-img
              :style="{ width: '100%', 'max-height': '60px' }"
              contain
              :src="customLogo"
            ></v-img>
          </div>

          <div v-else class="footer-text--text" v-html="getFooterData.texts.column2"></div>
        </v-flex>

        <v-flex xs12 sm4>
          <div v-if="showLogo(getFooterData.texts.column3)" class="text-center">
            <v-img
              :style="{ width: '100%', 'max-height': '60px' }"
              contain
              :src="customLogo"
            ></v-img>
          </div>

          <div v-else class="footer-text--text" v-html="getFooterData.texts.column3"></div>
        </v-flex>
      </v-layout>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("account", ["getFooterData", "getCustomLogo"]),

    customLogo() {
      if (this.getCustomLogo) {
        const base64String = this.getCustomLogo.buffer;
        const mimeType = this.getCustomLogo.mimeType;
        return `data:${mimeType};base64,${base64String}`;
      } else {
        return null;
      }
    },
  },

  methods: {
    showLogo(text) {
      return text == "{{logo}}" && this.customLogo;
    },
  },
};
</script>

<style scoped>
.footer-container {
  font-size: 13px;
}

.column-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .footer-container {
    padding: 0px !important;
    font-size: 70%;
  }
}
</style>
