import axiosMethods from "../mixins/axios";

export default {
  async getAllProducts(url) {
    return await axiosMethods.get(url);
  },

  async getInvoiceProducts() {
    return await axiosMethods.get("/api/v1/product/get-invoice-products");
  },

  async getProductsById(ids) {
    const response = await axiosMethods.post("/api/v1/product/get-products-by-id/", ids);
    return response.data.products;
  },

  async createProduct(data) {
    return await axiosMethods.post("/api/v1/product/create", data);
  },

  async updateProduct(data) {
    return await axiosMethods.update("/api/v1/product/update/" + data.productId, data.product);
  },

  async inActivateProduct(id) {
    return await axiosMethods.update("/api/v1/product/in-activate/" + id);
  },
};
