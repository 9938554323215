import axiosMethods from "../mixins/axios";

export default {
  async getOneCondominium(condominiumId) {
    return await axiosMethods.get("/api/v1/condominium/getOne/" + condominiumId);
  },

  async getAllCondominiums(data) {
    return await axiosMethods.post(data.url, data.data);
  },

  async editCondominium(data) {
    return await axiosMethods.update(data.url, { documentUpdate: data.condominium });
  },

  // Marking inActive, not actually deleting
  async deleteCondominium(id) {
    return await axiosMethods.update("/api/v1/condominium/delete/" + id);
  },

  async searchCondominium(url) {
    return await axiosMethods.get(url);
  },
};
