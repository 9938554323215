<template>
  <v-container>
    <v-row class="justify-center" dense>
      <v-col cols="12" md="6" lg="4" class="pr-2">
        <v-form ref="form">
          <h2 class="mb-5">Muut ehdot</h2>
          <v-col cols="12">
            <v-checkbox v-model="subLeaseAllowed" label="Jälleenvuokraus sallittu" hide-details />
            <v-checkbox v-model="furnished" label="Vuokrataan kalustettuna" hide-details />
            <v-checkbox v-model="mandatoryInsurance" label="Pakollinen kotivakuutus" hide-details />
            <v-checkbox v-model="noSmoking" label="Tupakointi kielletty" hide-details />
            <v-checkbox v-model="noPets" label="Lemmikit kiellettyjä" />
          </v-col>

          <v-col v-if="!noPets" cols="12">
            <label>Tarkennus lemmikkieläimistä</label>
            <v-text-field v-model="petsDetails" outlined dense hide-details />
          </v-col>

          <v-col cols="12">
            <label>Siirrä avaimenhaltijat sopimuksilta</label>
            <v-autocomplete
              class="mt-1"
              v-model="keyHoldersFromContracts"
              :items="activeRentalContracts"
              :item-text="nameContractnumber"
              item-value="_id"
              label="Siirrä sopimuksilta"
              no-data-text="Vuokrasopimuksen numero tai vuokralainen"
              outlined
              dense
              multiple
              small-chips
              return-object
              deletable-chips
              :search-input.sync="searchInput"
              @change="searchInput = ''"
              @input.native="getRentalContract"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Avainten määrä</label>
            <v-text-field
              v-model.number="keys"
              v-only-numbers
              v-prevent-paste
              outlined
              dense
              suffix="kpl"
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Hävitettyjen avainten korvaussumma €</label>
            <v-text-field
              v-model.number="keysLostSum"
              outlined
              dense
              type="number"
              step="0.01"
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Allekirjoituspäivä</label>
            <v-menu
              ref="startMenu"
              v-model="signatureMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedSignature"
                  outlined
                  dense
                  v-bind="attrs"
                  :rules="required"
                  v-on="on"
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="signatureDate"
                first-day-of-week="1"
                @input="signatureMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <label>Allekirjoituspaikka</label>
            <v-text-field
              v-model="signatureCity"
              outlined
              dense
              :rules="required"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="signForOthers"
              label="Allekirjoita valtakirjalla vuokranantajan puolesta"
              dense
              hide-details
            />
          </v-col>
        </v-form>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <h3 class="mt-1">Laskutus</h3>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-checkbox v-model="eInvoice" label="Laskutus integraation kautta käytössä" dense />
          </v-col>
        </v-row>

        <v-row dense v-if="currentUser.currentAccount.settings.mandatoryPhotoOnContract">
          <v-col cols="12" md="10" xl="9">
            <div class="image-upload-notice mb-1">
              <p>
                Asetuksissa on määritetty pakollinen kuvanlataus vuokrakohteesta vuokrasopimuksen
                teon yhteydessä. Lataa kuvat vuokrasopimuksen luomisen jälkeen. Et voi
                allekirjoittaa sopimusta ennenkuin kuva on otettu.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [mixins],

  data() {
    return {
      searchInput: "",
      contractInput: "",
      signatureMenu: false,
      dateFormattedSignature: "",
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("contract", ["activeRentalContracts"]),
    ...mapState("account", ["currentUser"]),

    subLeaseAllowed: {
      get() {
        return this.$store.state.rentalContract.others.subLeaseAllowed;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            subLeaseAllowed: value,
          },
        });
      },
    },
    furnished: {
      get() {
        return this.$store.state.rentalContract.others.furnished;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            furnished: value,
          },
        });
      },
    },
    mandatoryInsurance: {
      get() {
        return this.$store.state.rentalContract.others.mandatoryInsurance;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            mandatoryInsurance: value,
          },
        });
      },
    },
    noPets: {
      get() {
        return this.$store.state.rentalContract.others.noPets;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            noPets: value,
          },
        });
      },
    },
    petsDetails: {
      get() {
        return this.$store.state.rentalContract.others.petsDetails;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            petsDetails: value,
          },
        });
      },
    },
    keyHoldersFromContracts: {
      get() {
        return this.$store.state.rentalContract.others.keyHoldersFromContracts;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            keyHoldersFromContracts: value,
          },
        });
      },
    },
    noSmoking: {
      get() {
        return this.$store.state.rentalContract.others.noSmoking;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            noSmoking: value,
          },
        });
      },
    },
    keys: {
      get() {
        return this.$store.state.rentalContract.others.keys;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            keys: value,
          },
        });
      },
    },
    keysLostSum: {
      get() {
        return this.$store.state.rentalContract.others.keysLostSum;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            keysLostSum: value,
          },
        });
      },
    },
    signatureDate: {
      get() {
        return this.$store.state.rentalContract.others.signatureDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            signatureDate: value,
          },
        });
      },
    },
    signatureCity: {
      get() {
        return this.$store.state.rentalContract.others.signatureCity;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            signatureCity: value,
          },
        });
      },
    },
    eInvoice: {
      get() {
        return this.$store.state.rentalContract.others.eInvoice;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            eInvoice: value,
          },
        });
      },
    },

    signForOthers: {
      get() {
        return this.$store.state.rentalContract.others.signForOthers;
      },
      set(value) {
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            signForOthers: value,
          },
        });
      },
    },
  },

  watch: {
    signatureDate() {
      this.dateFormattedSignature = this.formatDate(this.signatureDate);
    },

    contractInput: debounce(function (newVal) {
      this.searchContract(`/api/v1/rental-contract/search-rental-contract?search=${newVal}`);
    }, 1000),

    currentUser(val) {
      if (this.$route.path.includes("create") && val.signForOthers) {
        this.signForOthers = true;
      }
    },
  },

  created() {
    this.$root.$refs.others = this;

    if (this.$store.state.rentalContract.others.signatureDate)
      this.dateFormattedSignature = this.formatDate(
        this.$store.state.rentalContract.others.signatureDate
      );

    if (this.$route.path.includes("create") && this.currentUser.signForOthers) {
      this.signForOthers = true;
    }
  },

  methods: {
    ...mapActions("contract", ["searchContract"]),

    getRentalContract(event) {
      this.contractInput = event.target.value;
    },

    checkIfValid() {
      eventBus.$emit("checkothers", this.$refs.form.validate());
    },

    nameContractnumber(item) {
      if (item.tenant) {
        return item.contractNumber + ", " + item.tenant.name;
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.image-upload-notice {
  width: 100%;
  border: 1px solid var(--v-success-base);
  padding: 25px;
  color: var(--v-success-base);
}
</style>
