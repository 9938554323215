import tableHeaderService from "../../services/tableHeader.service";
import { defaultStaticHeaders } from "@/configs/defaultState";
import _ from "lodash";

const state = {
  activeHeaders: [],
  editMode: false,
  openTableHeaderDialog: false,
  selectedHeader: null,
  currentHeader: { name: "", columns: [] },
  currentColumn: {
    text: "",
    formula: "",
    rounding: 2,
    order: 1,
  },
  editingIndex: null,
};

const mutations = {
  addCurrentColumn(state) {
    state.currentHeader.columns.push(state.currentColumn);
  },

  setCurrentHeader(state, obj) {
    state.currentHeader = _.cloneDeep(obj);
  },

  setSelectedHeader(state, header) {
    if (header) state.selectedHeader = header;
  },

  removeSelectedHeader(state) {
    state.selectedHeader = null;
  },

  setUserTableHeader(state, tableName) {
    const userHeaders = this.state.account.currentUser?.defaultHeaders || [];
    const foundHeader = userHeaders.find((el) => el.tableName === tableName);
    if (foundHeader) {
      const header = state.activeHeaders.find(
        (el) => String(el._id) === String(foundHeader.headerId)
      );
      if (header) this.commit("tableHeader/setSelectedHeader", header);
    }
  },

  setCurrentColumn(state, column) {
    state.currentColumn = column;
  },

  setField(state, obj) {
    state[obj.field] = obj.val;
  },

  removeColumn(state, index) {
    state.currentHeader.columns.splice(index, 1);
  },

  replaceColumn(state) {
    state.currentHeader.columns.splice(state.editingIndex, 1, { ...state.currentColumn });
  },

  addActiveHeaders(state, headers) {
    state.activeHeaders = headers;
  },
};

const actions = {
  async getTableHeaders({ commit }, tableName) {
    try {
      const res = await tableHeaderService.getTableHeaders(tableName);
      commit("addActiveHeaders", res.data.tableHeaders);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createTableHeader({ commit, state, dispatch }, { tableName, edit }) {
    try {
      const res = await tableHeaderService.createTableHeader({
        header: state.currentHeader,
        tableName,
      });
      if (edit) commit("setSelectedHeader", res.data.tableHeader);
      await dispatch("getTableHeaders", tableName);
    } catch (err) {
      throw new Error(err);
    }
  },

  async editTableHeader({ commit, state, dispatch }, { tableName, edit }) {
    try {
      const res = await tableHeaderService.editTableHeader({
        header: state.currentHeader,
        tableName,
      });
      if (edit) commit("setSelectedHeader", res.data.tableHeader);
      await dispatch("getTableHeaders", tableName);
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteTableHeader({ commit, dispatch }, data) {
    try {
      const res = await tableHeaderService.deleteTableHeader(data);
      commit("removeSelectedHeader");
      commit("account/setUserDefaultHeaders", res.data.defaultHeaders, { root: true });
      await dispatch("getTableHeaders", data.tableName);
    } catch (err) {
      throw new Error(err);
    }
  },

  async saveDefaultHeader({ commit }, data) {
    try {
      const res = await tableHeaderService.saveDefaultHeader(data);
      commit("account/setUserDefaultHeaders", res.data.defaultHeaders, { root: true });
    } catch (err) {
      throw new Error(err);
    }
  },
};

const getters = {
  tableHeaders: (state) => (tableName) => {
    const staticHeaders = defaultStaticHeaders[tableName] || [];
    const dynamicHeaders = (state.selectedHeader?.columns || [])
      .map((header, index) => ({
        ...header,
        value: `A${index + 1}`,
        sortable: false,
      }))
      .sort((a, b) => a.order - b.order);

    return [...staticHeaders, ...dynamicHeaders];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
