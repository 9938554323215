import axiosMethods from "../mixins/axios";

export default {
  async createGroup(formData) {
    return await axiosMethods.post("/api/v1/group/create", formData);
  },

  async searchGroups(url) {
    return await axiosMethods.get(url);
  },

  async editGroup(formData, id) {
    return await axiosMethods.update(`/api/v1/group/edit/${id}`, formData);
  },

  async attachUsersToGroup(data) {
    return await axiosMethods.update(`/api/v1/group/${data.groupId}/attach-users`, {
      users: data.users,
    });
  },

  async deleteGroup(id) {
    return await axiosMethods.update(`/api/v1/group/delete/${id}`);
  },

  async getGroups(url) {
    return await axiosMethods.get(url);
  },
};
