import groupService from "../../services/group.service";
import { showPopup } from "../../utils/helpers";
import { defaultGroup } from "../../configs/defaultState";
import _ from "lodash";

const state = {
  groups: [],
  activeGroups: [],
  selectedAccounts: [],
  totalLength: 0,
  currentGroup: _.cloneDeep(defaultGroup),
};

const mutations = {
  setGroups(state, data) {
    state.groups = [...data.groups];
    state.totalLength = data.totalLength;
  },

  setLogo(state, file) {
    state.currentGroup.logo = file;
  },

  addActiveGroups(state, groups) {
    state.activeGroups = [...groups, ...state.activeGroups].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  setToActiveGroups(state, group) {
    if (group) {
      state.activeGroups = [{ ...group }];
    } else {
      state.activeGroups = [];
    }
  },

  setSelectedAccounts(state, arr) {
    state.selectedAccounts = [...arr];
  },

  addGroup(state, group) {
    state.groups.unshift({ ...group });
    state.totalLength++;
  },

  removeGroup(state, id) {
    state.groups.splice(
      state.groups.findIndex((x) => String(x._id) == String(id)),
      1
    );
    state.totalLength--;
  },

  replaceGroup(state, group) {
    state.groups.splice(
      state.groups.findIndex((x) => String(x._id) == String(group._id)),
      1,
      group
    );
  },

  setCurrentGroup(state, group) {
    state.currentGroup = { ...group };
  },

  setDefaultGroup(state) {
    state.currentGroup = _.cloneDeep(defaultGroup);
  },
};

const actions = {
  async getGroups({ commit }, id) {
    try {
      const response = await groupService.getGroups(id);
      commit("setGroups", response.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchGroups({ commit }, url) {
    try {
      const res = await groupService.searchGroups(url);
      commit("addActiveGroups", res.data.groups);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async createGroup({ commit, state }) {
    try {
      const formData = createFormData(state.currentGroup);
      const response = await groupService.createGroup(formData);
      commit("addGroup", response.data.group);
    } catch (err) {
      throw new Error(err);
    }
  },

  async editGroup({ commit, state }) {
    try {
      const formData = createFormData(state.currentGroup);
      const response = await groupService.editGroup(formData, state.currentGroup._id);
      commit("replaceGroup", response.data.group);
    } catch (err) {
      throw new Error(err);
    }
  },

  async attachUsersToGroup({ commit }, data) {
    try {
      const response = await groupService.attachUsersToGroup(data);
      commit("replaceGroup", response.data.group);
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteGroup({ commit }, id) {
    try {
      await groupService.deleteGroup(id);
      commit("removeGroup", id);
    } catch (err) {
      throw new Error(err);
    }
  },
};

// Helpers
function createFormData(data) {
  const formData = new FormData();
  const serializedData = { ...data };
  delete serializedData.logo;
  formData.append("data", JSON.stringify(serializedData));
  if (data.logo) {
    formData.append("logo", data.logo);
  }
  return formData;
}
export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
