import condominiumService from "../../services/condominium.service";
import { defaultCondominium, defaultCondominiumLoanForm } from "../../configs/defaultState";
import _ from "lodash";
import { showPopup } from "../../utils/helpers";

const state = {
  currentCondominium: _.cloneDeep(defaultCondominium),
  condominiums: [],
  activeCondominiums: [],
  currentLoan: {},
  loanIndex: null,
  totalLength: 0,
  loading: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  addAllCondominiums(state, data) {
    state.condominiums = data.condominiums;
    state.totalLength = data.totalLength;
  },

  setField(state, obj) {
    state.currentCondominium = _.set(state.currentCondominium, obj.field, obj.val);
  },

  setCondominium(state, condominium) {
    if (condominium.loans) {
      condominium.loans.forEach((el) => {
        el.curRefRateIntStart = new Date(el.curRefRateIntStart).toISOString().substring(0, 10);
        el.morgageExemptionEnds = el.morgageExemptionEnds
          ? new Date(el.morgageExemptionEnds).toISOString().substring(0, 10)
          : "";
        el.endDate = el.endDate ? new Date(el.endDate).toISOString().substring(0, 10) : "";
      });
    } else {
      condominium.loans = [];
    }
    state.currentCondominium = { ...condominium };
  },

  setDefaultCondominium(state) {
    state.currentCondominium = _.cloneDeep(defaultCondominium);
  },

  deleteCondominium(state, id) {
    state.condominiums = state.condominiums.filter((el) => el._id != id);
  },

  addActiveCondominiums(state, condominiums) {
    state.activeCondominiums = [...condominiums, ...state.activeCondominiums].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  setCurrentLoan(state, data) {
    state.currentLoan = { ...data.loan };
    state.loanIndex = data.index;
  },

  setDefaultLoanForm(state) {
    state.currentLoan = _.cloneDeep(defaultCondominiumLoanForm);
  },

  addLoan(state) {
    state.currentCondominium.loans.push({ ...state.currentLoan });
  },

  editLoan(state) {
    state.currentCondominium.loans.splice(state.loanIndex, 1, { ...state.currentLoan });
  },

  deleteLoan(state, index) {
    state.currentCondominium.loans.splice(index, 1);
  },
};

const actions = {
  async getOneCondominium({ commit }, id) {
    try {
      const res = await condominiumService.getOneCondominium(id);
      commit("setCondominium", res.data.condominium);
      // Set images
      commit("image/setImages", res?.data?.condominium?.images || [], { root: true });
      // set loading false
      commit("setLoading", false);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getAllCondominiums({ commit }, data) {
    try {
      const res = await condominiumService.getAllCondominiums(data);
      commit("addAllCondominiums", {
        condominiums: res.data.condominiums,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      throw new Error(err);
    }
  },

  async editCondominium(ctx, data) {
    try {
      await condominiumService.editCondominium(data);
      showPopup("Kiinteistö tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteCondominium({ commit }, id) {
    try {
      await condominiumService.deleteCondominium(id);
      commit("deleteCondominium", id);
      showPopup("Kiinteistö poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchCondominium({ commit }, url) {
    try {
      const res = await condominiumService.searchCondominium(url);

      commit("addActiveCondominiums", res.data.condominiums);
    } catch (err) {
      showPopup(err, " error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
