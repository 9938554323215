<template>
  <v-container :class="ownClass">
    <v-row class="justify-center align-center">
      <v-col cols="12" :sm="sm" align="center">
        <h3 :style="`font-size: ${fontSize}px`" class="mb-3">{{ text }}</h3>
        <v-progress-circular
          v-if="type == 'circle'"
          class="justify-center"
          :size="size"
          color="primary"
          indeterminate
        ></v-progress-circular>

        <v-progress-linear
          v-if="type == 'line'"
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    ownClass: {
      type: String,
      default: "fill-height",
    },
    text: {
      type: String,
      default: "Ladataan",
    },
    fontSize: {
      type: Number,
      default: 15,
    },
    size: {
      type: Number,
      default: 60,
    },
    sm: {
      type: Number,
      default: 4,
    },
    type: {
      type: String,
      default: "circle",
    },
  },
};
</script>

<style scoped></style>
