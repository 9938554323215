import axiosMethods from "../mixins/axios";

export default {
  async getAllInvoices(data) {
    return await axiosMethods.post(data.url, data.data);
  },

  async getOneInvoice(url) {
    return await axiosMethods.get(url);
  },

  async getOverdueInvoices(url) {
    return await axiosMethods.get(url);
  },

  async getAllPayments(data) {
    return await axiosMethods.post(data.url, data.data);
  },

  async searchInvoice(url) {
    return await axiosMethods.get(url);
  },

  async getInvoiceByServiceId(serviceId) {
    return await axiosMethods.get(`/api/v1/invoices/service-id/${serviceId}`);
  },

  async updateSingleInvoiceStatuses(data) {
    return await axiosMethods.update(`/api/v1/invoices/check-status-single`, data);
  },

  async sendInvoice(data) {
    return await axiosMethods.post(`/api/v1/invoices/send/${data.invoiceId}`);
  },

  async sendRefundInvoice(id) {
    return await axiosMethods.post(`/api/v1/invoices/refund-related/send/${id}`);
  },

  async createRefundInvoiceWithoutSending(data) {
    return await axiosMethods.post(`/api/v1/invoices/refund/register/${data.id}`, {
      payDate: data.payDate,
    });
  },

  async createRefundInvoice(data) {
    return await axiosMethods.post("/api/v1/invoices/refund/create", data);
  },

  async editRefundInvoice(data) {
    return await axiosMethods.update(data.url, data);
  },

  async updateRecurrentInvoice(data) {
    return await axiosMethods.update("/api/v1/invoices/recurrent-invoice/edit", data);
  },

  async deleteInvoice(id) {
    return await axiosMethods.delete("/api/v1/invoices/delete/" + id);
  },

  async makePayment(data) {
    return await axiosMethods.post("/api/v1/invoices/make-payment", data);
  },

  async changeDueDate(data) {
    return await axiosMethods.update(`/api/v1/invoices/change-duedate/${data.serviceId}`, {
      dueDate: data.dueDate,
    });
  },

  async changeCostCentreToAllInvoices(data) {
    return await axiosMethods.update(`/api/v1/invoices/change-invoice-cost-centre`, data);
  },

  async changeProductAccountingIdToInvoices(data) {
    return await axiosMethods.update(`/api/v1/invoices/change-product-accounting-id`, data);
  },
};
