<template>
  <v-container>
    <v-form ref="form">
      <h2 class="mb-3 text-center">Vuokrauskohde</h2>
      <h4 v-if="!apartment.activeForRent" class="error--text mt-1 text-center">
        Vuokrakohde ei ole vuokrattavana
      </h4>
      <v-row class="justify-center" dense>
        <v-col cols="12" sm="10" md="6" lg="5" xl="4">
          <v-col cols="12">
            <label>Hae vuokrakohde</label>
            <v-autocomplete
              :value="apartment.id"
              :items="activeApartments"
              :item-text="fullAddress"
              item-value="_id"
              no-data-text="Vuokrakohteen osoite"
              outlined
              dense
              small-chips
              return-object
              @change="setApartment($event)"
              :search-input.sync="searchInput"
              @input.native="getApartments"
              :rules="required"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Huoneet</label>
            <v-autocomplete
              v-model="roomIds"
              :items="activeRooms"
              item-text="name"
              item-value="_id"
              no-data-text="Ei huoneita"
              outlined
              dense
              small-chips
              hide-details
              multiple
              deletable-chips
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Osoite</label>
            <v-text-field
              v-model="apartment.address"
              disabled
              outlined
              dense
              :rules="required"
              hide-details
            />
          </v-col>

          <v-col v-if="apartment.apartmentNumber" cols="12">
            <label>Vuokrakohteen numero</label>
            <v-text-field
              v-model="apartment.apartmentNumber"
              :rules="required"
              disabled
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Postinumero</label>
            <v-text-field
              v-model="apartment.zipCode"
              :rules="required"
              disabled
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Kaupunki</label>
            <v-text-field
              v-model="apartment.city"
              :rules="required"
              disabled
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Maa</label>
            <v-text-field
              v-model="apartment.country"
              :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
              disabled
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Pinta-ala</label>
            <v-text-field
              v-model.number="apartment.area"
              :rules="required"
              type="number"
              step="0.1"
              disabled
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col v-if="apartment.floorPlan" cols="12">
            <label>Huoneisto kokoonpano</label>
            <v-text-field
              v-model="apartment.floorPlan"
              :rules="required"
              disabled
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Muut käytössä olevat tilat</label>
            <v-text-field v-model="otherRooms" outlined dense hide-details />
            <v-checkbox
              v-model="apartment.condition"
              label="Vuokrataan nykyisessä kunnossa"
              hide-details
            ></v-checkbox>
            <v-checkbox v-model="photos" label="Vuokrakohde valokuvataan" hide-details />
          </v-col>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import { mapState, mapActions } from "vuex";
import { debounce } from "../../utils/helpers";

export default {
  mixins: [mixins, invoiceMixins],

  data() {
    return {
      searchInput: "",
      apartmentInput: "",
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("apartment", ["activeApartments"]),

    apartment() {
      return this.$store.state.rentalContract.apartment;
    },

    activeRooms() {
      if (this.apartment?.rooms && this.apartment.rooms.length > 0) {
        return this.apartment.rooms.filter((el) => el.active);
      } else {
        return [];
      }
    },

    roomIds: {
      get() {
        return this.$store.state.rentalContract.apartment.roomIds;
      },
      set(value) {
        this.$store.commit("setContractField", {
          apartment: {
            ...this.$store.state.rentalContract.apartment,
            roomIds: value,
          },
        });
      },
    },

    photos: {
      get() {
        return this.$store.state.rentalContract.apartment.photos;
      },
      set(value) {
        this.$store.commit("setContractField", {
          apartment: {
            ...this.$store.state.rentalContract.apartment,
            photos: value,
          },
        });
      },
    },

    otherRooms: {
      get() {
        return this.$store.state.rentalContract.apartment.otherRooms;
      },
      set(value) {
        this.$store.commit("setContractField", {
          apartment: {
            ...this.$store.state.rentalContract.apartment,
            otherRooms: value,
          },
        });
      },
    },
  },

  watch: {
    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),
  },

  async created() {
    this.$root.$refs.editApartment = this;
  },

  methods: {
    ...mapActions("apartment", ["searchApartment"]),

    checkIfValid() {
      eventBus.$emit("checkapartment", this.$refs.form.validate());
    },

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    async getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    setApartment(apartment) {
      if (apartment) {
        apartment = { ...apartment, id: apartment._id };
        delete apartment._id;
        // set to state
        this.$store.state.rentalContract.apartment = apartment;

        // OtherRooms field
        this.$store.commit("setContractField", {
          apartment: {
            ...this.$store.state.rentalContract.apartment,
            otherRooms: apartment.condominium.otherRooms,
          },
        });

        // Fields to lease page
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            waterChargePerMonth: apartment.waterCharge,
          },
        });
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            saunaCost: apartment.condominium.saunaCost,
          },
        });
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            parkingCost: apartment.condominium.parkingCost,
          },
        });
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            laundretteCost: apartment.condominium.laundretteCost,
          },
        });

        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rent: apartment.estimatedRent,
          },
        });

        // set signaturecity
        this.$store.commit("setContractField", {
          others: {
            ...this.$store.state.rentalContract.others,
            signatureCity: apartment.city,
          },
        });

        // // Set landlord at the same time
        // if (apartment.owners.length > 0) {
        //   apartment.owners.forEach(landlord => {
        //          this.$store.commit("setContractField", {
        //   landlord: {
        //     ...this.$store.state.rentalContract.landlord,
        //     name: apartment.landlord.name,
        //     email: apartment.landlord.email,
        //     phone: apartment.landlord.phone,
        //   },
        // });
        //   });
        // }
      }
    },
  },
};
</script>

<style scoped></style>
