import { showPopup } from "../../utils/helpers";
import todoService from "../../services/todo.service";
// import apartmentService from "../../services/apartment.service";

const state = {
  tasks: [],
  apartments: [],
  labels: [
    {
      id: "apartments",
      title: "Vuokrakohteet",
      color: "green",
    },
    {
      id: "others",
      title: "Yleiset",
      color: "blue",
    },
    {
      id: "rentIncrease",
      title: "Vuokrankorotukset",
      color: "orange",
    },
    {
      id: "serviceRequest",
      title: "Huolto",
      color: "red",
    },
    {
      id: "keys",
      title: "Avaimet",
      color: "black",
    },
  ],
};

const mutations = {
  setAllTasks(state, tasks) {
    state.tasks = tasks;
  },

  addOneTask: (state, task) => {
    state.tasks.unshift(task);
  },

  updateTask: (state, task) => {
    const taskIdx = state.tasks.find((t) => t._id === task._id);

    Object.assign(taskIdx, task);
  },

  taskCompleted: (state, task) => {
    const taskIdx = state.tasks.findIndex((t) => t._id === task._id);

    state.tasks[taskIdx].completed = true;
  },

  taskIncomplete: (state, task) => {
    const taskIdx = state.tasks.findIndex((t) => t._id === task._id);

    state.tasks[taskIdx].completed = false;
  },

  removeTask: (state, task) => {
    const taskIdx = state.tasks.findIndex((t) => t._id === task._id);

    if (taskIdx !== -1) state.tasks.splice(taskIdx, 1);
  },
};

const actions = {
  async addTask({ commit }, task) {
    try {
      const res = await todoService.addTask(task);
      commit("addOneTask", res.data.toDo);
      showPopup("Tehtävä luotu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async deleteTask({ commit }, tasks) {
    try {
      await todoService.deleteTask(tasks);
      commit("removeTask", tasks);
      showPopup("Tehtävä poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async taskCompleted({ commit }, task) {
    try {
      await todoService.markCompleted(task);
      commit("taskCompleted", task);
      showPopup("Tehtävä merkattu valmiiksi", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async taskIncomplete({ commit }, task) {
    try {
      await todoService.markInCompleted(task);
      commit("taskIncomplete", task);
      showPopup("Valmis siirretty tehtäviin", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async updateTask({ commit }, task) {
    try {
      const res = await todoService.updateTask(task);

      commit("updateTask", res.data.toDo);
      showPopup("Tehtävä päivitetty", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  getTasks({ commit }) {
    return todoService
      .getTasks()
      .then((res) => {
        commit("setAllTasks", res.data.toDos);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

const getters = {
  incompleteTasks({ tasks }) {
    return tasks.filter((t) => !t.completed);
  },
  completeTasks({ tasks }) {
    return tasks.filter((t) => t.completed);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
