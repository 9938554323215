import axiosMethods from "../mixins/axios";

export default {
  async getContracts(url) {
    return await axiosMethods.get(url);
  },

  async getContractsById(ids) {
    return await axiosMethods.post("/api/v1/other-contract/contracts-by-id", { ids });
  },

  async getContract(id) {
    return await axiosMethods.get(`/api/v1/other-contract/get-contract/${id}`);
  },

  async createOtherContract(data) {
    return await axiosMethods.post("/api/v1/other-contract/create-document", data);
  },

  async updateOtherContract(contract, id) {
    return await axiosMethods.update(`/api/v1/other-contract/update-document/${id}`, contract);
  },

  async deleteContract(id) {
    return await axiosMethods.delete(`/api/v1/other-contract/delete-document/${id}`);
  },
};
