import axiosMethods from "../mixins/axios";

export default {
  async updateUserData(user) {
    return await axiosMethods.update("/api/v1/user/updateUser", user);
  },

  async updatePassword(password, passwordConfirm, currentPassword) {
    return await axiosMethods.update("/api/v1/user/updateMyPassword", {
      password: password,
      passwordConfirm: passwordConfirm,
      currentPassword: currentPassword,
    });
  },
};
