import userService from "../../services/user.service";
import { showPopup } from "../../utils/helpers";

const state = {
  users: [],
  activeUsers: [],
};

const mutations = {
  setToActiveUsers(state, users) {
    if (users) {
      state.activeUsers = [...users];
    } else {
      state.activeUsers = [];
    }
  },

  addActiveUsers(state, users) {
    state.activeUsers = [...users, ...state.activeUsers].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },
};

const actions = {
  async searchUsers({ commit }, url) {
    try {
      const res = await userService.searchUsers(url);
      commit("addActiveUsers", res.data.users);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
