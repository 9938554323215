// Key is routes name
const paths = {
  // Invoices
  Yhteenveto: { module: "invoice", array: "overDueInvoices" },
  Laskut: { module: "invoice", array: "invoices" },
  Hyvityslaskut: { module: "invoice", array: "invoices" },
  Maksusuoritukset: { module: "invoice", array: "payments" },

  // Contracts
  Vuokrasopimukset: { module: "contract", array: "rentalContracts" },
  "Muut sopimukset": { module: "othercontract", array: "contracts" },

  // Rent increases
  Vuokrankorotukset: { module: "rentIncrease", array: ["rentIncreases", "rentalContracts"] },

  // Outgoing payments
  "Lähtevien seuranta": {
    module: "outgoingpayment",
    array: ["payments", "otherPayments", "checkList"],
  },

  // Deposits
  Vakuudet: { module: "deposit", array: "deposits" },

  // Products
  Tuotteet: { module: "product", array: "products" },

  // Keys
  Avainhallinta: { module: "keys", array: "keys" },
  Avaimenhaltijat: { module: "keyholder", array: "keyHolders" },

  // Contacts
  Yhteydenotot: { module: "contact", array: ["contacts", "queueContacts"] },

  // Apartments and condominia
  Vuokrakohteet: { module: "apartment", array: "apartments" },
  Taloyhtiöt: { module: "condominium", array: "condominiums" },

  // Tenants and partners and realtors
  Vuokralaiset: { module: "tenant", array: "tenants" },
  "Muut omistajat": { module: "partner", array: "partners" },
  Välittäjät: { module: "realtor", array: "realtors" },

  // Reports
  Analysaattori: { module: "report", array: "analyseItems" },

  // Forms
  Lomakkeet: { module: "form", array: "forms" },
  "Lomakkeen yhteydenotot": { module: "formSubmission", array: "formSubmissions" },

  // Receipts
  Kulut: { module: "receipt", array: "receipts" },
  "Vuokrakohteen yhteenveto": { module: "receipt", array: "receipts" },

  // Groups
  Ryhmät: { module: "group", array: "groups" },
  "Ryhmät/tilit": { module: "account", array: "accounts" },
  Ryhmälaskut: { module: "invoice", array: "invoices" },
  Ryhmähyvityslaskut: { module: "invoice", array: "invoices" },
  Ryhmävuokrankorotukset: { module: "rentIncrease", array: "rentalContracts" },
  Ryhmätaloyhtiöt: { module: "condominium", array: "condominiums" },
  Ryhmävuokrakohteet: { module: "apartment", array: "apartments" },
};

export default paths;
