import noteService from "../../services/note.service";
import { showPopup } from "../../utils/helpers";

const state = {
  loading: false,
  notes: [],
  currentNote: {},
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  setCurrentNote(state, val) {
    state.currentNote = { ...val };
  },

  setNotes(state, document) {
    state.notes = [...document.notes];
  },

  replateNote(state, data) {
    const index = data.document.notes.findIndex((note) => String(note._id) === String(data.noteId));

    if (index != -1) {
      state.notes.splice(index, 1, { ...data.document.notes[index] });
    }
  },

  removeTenantNote(state, noteId) {
    const index = state.notes.findIndex((note) => String(note._id) === String(noteId));

    if (index != -1) {
      state.notes.splice(index, 1);
    }
  },

  removeNote(state, noteId) {
    const index = state.notes.findIndex((note) => String(note._id) === String(noteId));

    if (index != -1) {
      state.notes.splice(index, 1);
    }
  },
};

const actions = {
  async getNotes({ commit }, data) {
    try {
      const response = await noteService.getNotes(data);
      commit("setNotes", response.data.document);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createNote({ commit }, data) {
    try {
      const response = await noteService.createNote(data);
      commit("setNotes", response.data.document);
      showPopup("Muistiinpano luotu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async editNote({ commit }, data) {
    try {
      const response = await noteService.editNote(data);
      commit("replateNote", { document: response.data.document, noteId: data.note._id });
      showPopup("Muistiinpano tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteNote({ commit }, data) {
    try {
      await noteService.deleteNote(data);
      commit("removeNote", data.noteId);
      showPopup("Muistiinpano poistettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
