import axiosMethods from "../mixins/axios";

export default {
  async getAllKeys(url) {
    return await axiosMethods.get(url);
  },

  async editSingleKey(key) {
    return await axiosMethods.update("/api/v1/key/updateOne/" + key._id, key);
  },

  async editSingleTenantKey(key) {
    return await axiosMethods.update("/api/v1/key/update-tenant-key/" + key._id, key);
  },

  async searchKey(url) {
    return await axiosMethods.get(url);
  },
};
